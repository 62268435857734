<div role="main" class="main">
    <section class="page-header page-header-classic page-header-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
                    <span class="page-header-title-border visible" style="width: 85.3906px;"></span>
                    <h1 data-title-border="">Services Outside Temple</h1>
                </div>
                <div class="col-md-4 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb d-block text-md-right">
                        <li><a [routerLink]="'/'">Home</a></li>
                        <li class="active">Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <div role="main" class="main">
        <div class="container py-4">
            <div class="row">
                <div class="col-lg-3 order-lg-2">
                    <app-availability-sidebar></app-availability-sidebar>
                </div>
                <div class="col-lg-8 order-lg-1" style="border-right:1px solid #e4e4e4; margin-right:25px;">
                    <div class="blog-posts">
                        <div class="row justify-content-md-center">
                            <div class="flex-row row">
                                <div class="col-lg-12">
                                    <p><strong>These Services CANNOT be ordered online, however you can check
                                            information, charges, check availability with Temple via Check Availability
                                            feature.</strong></p>
                                </div>
                                <div *ngFor="let service of services; let i=index" class="col-xs-6 col-sm-4 col-lg-4">
                                    <div class="thumbnail">
                                        <img src="{{ service.image }}"
                                            class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt="" />
                                        <div class="caption">
                                            <h2 class="clr1 font-weight-semibold mb-2">
                                                <a href="javascript:void(0);">{{ service.title }}</a>
                                            </h2>
                                            <div class="input-group">
                                                <input type="text" [owlDateTime]="dt1" [disabled]="true"
                                                    [owlDateTimeTrigger]="dt1" [min]="min" readonly
                                                    (dateTimeInput)="selectDate(i,$event)" class="form-control"
                                                    name="date" />
                                                <div class="input-group-append">
                                                    <button class="btn btn-secondary" [owlDateTimeTrigger]="dt1"
                                                        type="button">
                                                        <i class="fa fa-calendar"></i>
                                                    </button>
                                                </div>
                                                <owl-date-time [pickerMode]="'popup'" [pickerType]="'calendar'"
                                                    #dt1></owl-date-time>
                                            </div>
                                            <p class="flex-text">{{ service.description }}</p>
                                            <div class="input-group">
                                                <div class="clr1">
                                                    <span class="qty">Location</span>
                                                </div>
                                                <select class="miles form-control" (change)="selectLocation(i,$event)"
                                                    size="0" aria-invalid="false">
                                                    <option value="0">Temple</option>
                                                    <option value="1">0 < 50 Miles</option>
                                                    <option value="2">50 < 100 Miles</option>
                                                </select>
                                            </div>
                                            <a (click)="addProductToCart(service)" class="btn btn-primary btn-modern">$
                                                {{ service.amount }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>