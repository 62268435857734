<aside class="sidebar">
    <p>
        <strong>Details:</strong>
        <a class="float-right btn btn-primary text-white" (click)="emptyCart()">Empty Puja Basket</a>
    </p>
    <div class="toggle toggle-primary toggle-simple" data-plugin-toggle="">
        <section *ngFor="let item of cartItems; let i=index" (click)="hideShow(i,item.expand)" class="toggle"
            [ngClass]="(item.expand) ? 'active' : ''">
            <label>{{ item.type }}: <span class="p_txt">$ {{ item.totalCost }}</span>
                <br />{{ item.name }}<div class="cr_btn">
                    <i (click)="removeItem(item)" class="btn-minus fas fa-minus-square"></i>
                </div></label>
            <div *ngIf="item.expand">
                <p *ngIf="item.type_id != 5">Puja Date: <span class="p_txt">{{ item.date }}</span></p>
                <p *ngIf="item.type_id == 5">Donation Date: <span class="p_txt">{{ item.date }}</span></p>
                <p>Quantity: <span class="p_txt">{{ item.quantity }}</span></p>
                <p>{{ item.booking_config }}</p>
            </div>
        </section>
        <div class="form-group">
            <form [formGroup]="Form" (ngSubmit)="submitForm()">
                <div class="custom-checkbox" style="margin-top:12px;">
                    <input type="checkbox" (click)="checkedDonation($event)" class="custom-control-input"
                        id="shipbillingaddress" data-toggle-element='.checkbox-is-checked'>
                    <label [hidden]="page == 'donation'" class="custom-control-label" for="shipbillingaddress"> I would
                        like to
                        make a <a [routerLink]="'/services/donation'">donation</a></label>
                </div>
                <div [hidden]="!showDonation">
                    <input class="form-control" formControlName="price" type="number" value="0" />
                    <div class="input-group">
                        <select class="form-control" formControlName="donation_id" style="padding: 0px 5px !important;">
                            <option *ngFor="let donation of donations" value="{{donation._id}}">{{ donation.title }}</option>
                        </select>
                        <button type="submit" class="btn btn-primary btn-modern float-right">Add</button>
                    </div>
                </div>
            </form>
        </div>
        <hr>
        <p>Total Amount: <span class="p_txt">$ {{ grossTotal }}</span></p>
        <hr>
    </div>
    <div class="box-content">
        <div class="form-row">
            <div class="form-group">
                <a (click)="proceedToPay()" class="btn btn-primary btn-modern float-right">Proceed to Pay</a>
            </div>
        </div>
    </div>
</aside>