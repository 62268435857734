import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Event, Router, NavigationStart } from '@angular/router';
import { RequestService } from '../../services/request.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  Form: any = FormArray;
  loading = false;
  submitted = false;
  reset_by:string = 'Username';
  success = '';

  constructor(
    private authenticationService: AuthenticationService,
    public requestService: RequestService,
    private toastr: ToastrService,
    public router: Router,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.Form = this.formBuilder.group({
      reset_by: ['username', Validators.required],
      username: ['', Validators.required],
    });
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

  get f() { return this.Form.controls; }

  changeResetBy() {
    this.reset_by = this.Form.value.reset_by;
    this.reset_by = this.reset_by.replace(/_/gi, " ");
  }

  back(){
    this.success = '';
  }

  submitForm() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;
    this.requestService.forgotPassword(this.Form.value).subscribe((res) => {
      if (res.status === 1) {
        this.success = res.message;
        this.loading = false;
      } else {
        this.toastr.error(res.message);
        this.loading = false;
      }
    });
    
  }

}
