import { Component, OnInit, TemplateRef, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'weekly-date-picker',
  templateUrl: './weekly-date-picker.component.html',
})
export class WeeklyDatePickerComponent implements OnInit {  
  min=new Date();
  @Input() service!: any;
  @Input() index!: number;
  @Output() selectDate: EventEmitter<number> = new EventEmitter();
  @Output() selectQty: EventEmitter<number> = new EventEmitter();

  constructor(
  ) {}

  public myFilter = (d: any): boolean => {
    const day = d.getDay();
    if(this.service.days === day+''){
      return true;
    }
    return false ;
  }

  ngOnInit(): void {
    let today = new Date();
    today = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    this.min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
  }

  selectingDate(event:any){ 
    let selected = new Date(event.value);
    let month = selected.getMonth()+1;
    let date = month+'/'+selected.getDate()+'/'+selected.getFullYear();
    let data:any ={
      index:this.index,
      date:date //this.datepipe.transform(event.value, 'MM/dd/yyyy')
    };
    this.selectDate.emit(data);
  }

  selectingQty(event:any){
    let data:any ={
      index:this.index,
      qty:event.target.value
    };
    this.selectQty.emit(data);
  }

}