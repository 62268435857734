import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './middleware/auth.guard';

import { HomeComponent } from './component/home/home.component';
import { LoginComponent } from './component/login/login.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { ForgotUsernameComponent } from './component/forgot-username/forgot-username.component';
import { ForgotDevoteeIdComponent } from './component/forgot-devotee-id/forgot-devotee-id.component';
import { DevoteeRegistrationComponent } from './component/devotee-registration/devotee-registration.component';
import { CheckoutComponent } from './component/checkout/checkout.component';
import { CheckoutDetailComponent } from './component/checkout-detail/checkout-detail.component';
import { PaymentComponent } from './component/payment/payment.component';
import { ReceiptComponent } from './component/receipt/receipt.component';
import { LiveStreamingComponent } from './component/live-streaming/live-streaming.component';
import { DailyPujaScheduleComponent } from './component/daily-puja-schedule/daily-puja-schedule.component';
import { NewsComponent } from './component/news/news.component';
import { ReportComponent } from './component/report/report.component';
import { NewsdetailsComponent } from './component/news-details/news-details.component';
import { SearchComponent } from './component/search/search.component';
import { AlmanacComponent } from './component/almanac/almanac.component';
import { ProfileComponent } from './component/profile/profile.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { DonationReportComponent } from './component/donation-report/donation-report.component';
import { DevoteeCommitteeComponent } from './component/devotee-committee/devotee-committee.component';
import { SpecialStandingCommitteeComponent } from './component/special-standing-committee/special-standing-committee.component';

/* Services */
import { ServicesComponent } from './component/services/services.component';
import { OutsideTempleComponent } from './component/services/outside-temple/outside-temple.component';
import { RentalsComponent } from './component/services/rentals/rentals.component';
import { TempleComponent } from './component/services/temple/temple.component';
import { DailyComponent } from './component/services/daily/daily.component';
import { WeeklyComponent } from './component/services/weekly/weekly.component';
import { MonthlyComponent } from './component/services/monthly/monthly.component';
import { AnnualComponent } from './component/services/annual/annual.component';
import { VahanaSevasComponent } from './component/services/vahana-sevas/vahana-sevas.component';
import { PersonalComponent } from './component/services/personal/personal.component';
import { ExpressComponent } from './component/services/express/express.component';
import { DonationComponent } from './component/services/donation/donation.component';
import { ConfirmAvailableComponent } from './component/services/confirm-available/confirm-available.component';
import { SpecialComponent } from './component/services/special/special.component';
import { RecurringComponent } from './component/services/recurring/recurring.component';
import { DonationDetailComponent } from './component/services/donation-detail/donation-detail.component';
import { ServiceDetailComponent } from './component/services/service-detail/service-detail.component';

/* Visit */
import { VisitInfoComponent } from './component/visit-info/visit-info.component';
import { LocationDirectionsComponent } from './component/visit-info/location-directions/location-directions.component';
import { HotelsComponent } from './component/visit-info/hotels/hotels.component';
import { TemplePrasadamPolicyComponent } from './component/visit-info/temple-prasadam-policy/temple-prasadam-policy.component';
import { FaqComponent } from './component/visit-info/faq/faq.component';

const routes: Routes = [
  { path: 'index', redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  {
    path: 'about-us',
    loadChildren: () => import('./component/about-us/about-us.module').then((m) => m.AboutUsModule)
  },
  {
    path: 'services',
    component: ServicesComponent,
    children: [
      { path: '', redirectTo: 'express', pathMatch: 'full' },
      {
        path: 'express',
        component: ExpressComponent
      },
      {
        path: 'outside-temple',
        component: OutsideTempleComponent
      },
      {
        path: 'rentals',
        component: RentalsComponent
      },
      {
        path: 'temple',
        component: TempleComponent
      },
      {
        path: 'daily',
        component: DailyComponent
      },
      {
        path: 'weekly',
        component: WeeklyComponent
      },
      {
        path: 'monthly',
        component: MonthlyComponent
      },
      {
        path: 'annual',
        component: AnnualComponent
      },
      {
        path: 'special',
        component: SpecialComponent
      },
      {
        path: 'vahana_sevas',
        component: VahanaSevasComponent
      },
      {
        path: 'personal',
        component: PersonalComponent
      },
      {
        path: 'donation',
        component: DonationComponent
      },
      {
        path: 'confirm-available',
        component: ConfirmAvailableComponent
      },
      {
        path: 'recurring',
        component: RecurringComponent
      },
      {
        path: 'donation/detail/:id',
        component: DonationDetailComponent
      },
      {
        path: ':type/detail/:id',
        component: ServiceDetailComponent
      },
    ],
    // loadChildren: () => import('./component/services/services.module').then((m) => m.ServicesModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./component/events/events.module').then((m) => m.EventsModule)
  },
  {
    path: 'activities',
    loadChildren: () => import('./component/activities/activities.module').then((m) => m.ActivitiesModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./component/gallery/gallery.module').then((m) => m.GalleryModule)
  },
  {
    path: 'visit-info',
    component: VisitInfoComponent,
    children: [
      { path: '', redirectTo: 'location-directions', pathMatch: 'full' },
      {
        path: 'location-directions',
        component: LocationDirectionsComponent
      },
      {
        path: 'hotels',
        component: HotelsComponent
      },
      {
        path: 'temple-prasadam-policy',
        component: TemplePrasadamPolicyComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password/:id',
    component: ResetPasswordComponent
  },
  {
    path: 'forgot-username',
    component: ForgotUsernameComponent
  },
  {
    path: 'forgot-devotee-id',
    component: ForgotDevoteeIdComponent
  },
  {
    path: 'devotee-registration',
    component: DevoteeRegistrationComponent
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  },
  {
    path: 'checkout/detail',
    component: CheckoutDetailComponent
  },
  {
    path: 'checkout/payment',
    component: PaymentComponent
  },
  {
    path: 'receipt/:id',
    component: ReceiptComponent
  },
  {
    path: 'live-streaming',
    component: LiveStreamingComponent
  },
  {
    path: 'daily-puja-schedule',
    component: DailyPujaScheduleComponent
  },
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'news/:id',
    component: NewsdetailsComponent
  },
  {
    path: 'report',
    component: ReportComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'almanac',
    component: AlmanacComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'change_password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'donation_report',
    component: DonationReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'devotee_committee',
    component: DevoteeCommitteeComponent
  },
  {
    path: 'special_standing_committee',
    component: SpecialStandingCommitteeComponent
  },
  // {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    preloadingStrategy: PreloadAllModules,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
