import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { RequestService } from '../../services/request.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator } from '../../services/validator-service';
import { first } from 'rxjs/operators';
import { Event, Router,ActivatedRoute , NavigationStart } from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-devotee-registration',
  templateUrl: './devotee-registration.component.html',
  styleUrls: ['./devotee-registration.component.css']
})
export class DevoteeRegistrationComponent implements OnInit {
  nakshatrams: any;
  countries: any;
  states: any;
  languages: any;

  Form: any = FormArray;
  loading = false;
  submitted = false;

  married_box: boolean = false;
  children_box: boolean = false;
  @ViewChild('loginMdl') loginMdl:any;

  constructor(
    public requestService: RequestService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    public router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getData();
    this.Form = this.formBuilder.group({
      first_name: ['', Validators.required],
      middle_name: [''],
      last_name: ['', Validators.required],
      dob: [''],
      nakshatram: [''],
      gotram: [''],
      email: ['', Validators.required],
      confirm_email: ['', Validators.required],
      language: [''],
      is_married: [false],
      spouse: this.formBuilder.array([]),
      is_children: [false],
      childrens: this.formBuilder.array([]),
      address: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zip_code: ['', Validators.required],
      secondary_email: [''],
      home_phone: [''],
      office_phone: [''],
      mobile: ['', Validators.required],
      user_name: ['', Validators.required],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      is_subscribed: [false],
    }, { 
      validator: [
        ConfirmedValidator('email', 'confirm_email'),
        ConfirmedValidator('password', 'confirm_password')
      ]
    });
  }

  getData() {
    this.requestService.getNakshatrams().subscribe((res) => {
      if (res.status === 1) {
        this.nakshatrams = res.data;
      } else {
        this.nakshatrams = [];
      }
    });
    this.requestService.getLanguages().subscribe((res) => {
      if (res.status === 1) {
        this.languages = res.data;
      } else {
        this.languages = [];
      }
    });
    this.requestService.getCountries().subscribe((res) => {
      if (res.status === 1) {
        this.countries = res.data;
      } else {
        this.countries = [];
      }
    });
  }

  onSelectCountry(event: any) {
    this.getStates(event.target.value);
  }

  getStates(country_id: string) {
    this.Form.controls['state'].setValue('');
    if (country_id === '') {
      this.states = [];
    } else {
      this.requestService.getStates(country_id).subscribe((res) => {
        if (res.status === 1) {
          this.states = res.data;
        }
      });
    }
  }

  changeIsMarried() {
    this.married_box = this.Form.value.is_married;
    if (this.married_box) {
      this.spouse().push(
        this.formBuilder.group({
          first_name: ['', Validators.required],
          middle_name: [''],
          last_name: ['', Validators.required],
          dob: [''],
          nakshatram: [''],
          email: [''],
        })
      );
    } else {
      this.spouse().removeAt(0);
    }
  }

  spouse(): FormArray {
    return this.Form.get("spouse") as FormArray
  }

  changeIsChildren() {
    this.children_box = this.Form.value.is_children;
    if (this.children_box && (this.childrens()).length === 0) {
      this.addChildrens();
    }
  }

  childrens(): FormArray {
    return this.Form.get("childrens") as FormArray
  }

  newChildrens(): FormGroup {
    return this.formBuilder.group({
      first_name: ['', Validators.required],
      middle_name: [''],
      last_name: ['', Validators.required],
      dob: [''],
      nakshatram: [''],
      email: [''],
    })
  }

  addChildrens() {
    this.childrens().push(this.newChildrens());
  }

  removeChildrens(i: number) {
    this.childrens().removeAt(i);
  }

  get f() { return this.Form.controls; }

  submitForm() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    }
    this.modalService.open(this.loginMdl);
  }

  confirmSubmit(){
    this.modalService.dismissAll();
    this.loading = true;
    this.authenticationService.registerDevotee(this.Form.value)
      .pipe(first())
      .subscribe({
        next: (data:any) => {
          if(data.status === 1){
            window.location.href = '/';
          } else {
            this.toastr.error(data.message);
            this.loading = false;
          }
        },
        error: error => {
          this.toastr.error(error);
          this.loading = false;
        }
      });
  }

}
