import { Component, OnInit, TemplateRef } from '@angular/core';
import { RequestService } from '../../../services/request.service';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-temple-prasadam-policy',
  templateUrl: './temple-prasadam-policy.component.html',
  styleUrls: ['./temple-prasadam-policy.component.css'],
})
export class TemplePrasadamPolicyComponent implements OnInit {  
  loading:boolean = true;
  description: String = '';

  constructor(
    public requestService: RequestService,
    private domSanitizer : DomSanitizer
    ) {}

    transformHtml(value: any) {
      return this.domSanitizer.bypassSecurityTrustHtml(value);
    }
    
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.requestService.getVisitPrasadamPolicy().subscribe((res) => {
      if (res.status === 1) {
        this.description = res.data.description;
      } else {
        this.description = '';
      }
    });
  }

}