import { Component, OnInit, TemplateRef } from '@angular/core';
import { RequestService } from '../../../services/request.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit {
  loading: boolean = true;
  title: String = '';
  sub_title: String = '';
  description: String = '';

  constructor(
    private sanitizer: DomSanitizer,
    public requestService: RequestService,
  ) { }

  transformHtml(value:any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.requestService.getVisitFaq().subscribe((res) => {
      if (res.status === 1) {
        this.title = res.data.title;
        this.sub_title = res.data.sub_title;
        this.description = res.data.description;
      } else {
        this.title = '';
        this.sub_title = '';
        this.description = '';
      }
    });
  }

}