import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage-service';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { AvailabilityItem } from '../models/availability-item.model';
import { AvailabilityCart } from '../models/availability-cart.model';

const CART_KEY = 'availability';

@Injectable()
export class AvailabilityCartService {
  private subscriptionObservable: Observable<AvailabilityCart>;
  private subscribers: Array<Observer<AvailabilityCart>> = new Array<Observer<AvailabilityCart>>();

  public constructor(
    private storageService: LocalStorageService,
  ) {
    this.subscriptionObservable = new Observable<AvailabilityCart>((observer: Observer<AvailabilityCart>) => {
      this.subscribers.push(observer);
      observer.next(this.retrieve());
      return () => {
        this.subscribers = this.subscribers.filter((obs) => obs !== observer);
      };
    });
  }

  public get(): Observable<AvailabilityCart> {
    return this.subscriptionObservable;
  }

  public isAlreadyExists(id:string) {
    const cart = this.retrieve();
    let item = cart.items.find((p) => p._id === id);
    if (item === undefined) {
      return false;
    }else{
      return true;
    }
  }

  public empty(): void {
    const newCart = new AvailabilityCart();
    this.save(newCart);
    this.dispatch(newCart);
  }

  public isEmpty() {
    const cart = this.retrieve();
    if (cart.grossTotal > 0) {
      return true;
    } else {
      return false;
    }
  }

  private retrieve() {
    const cart = new AvailabilityCart();
    const storedCart = this.storageService.getItem(CART_KEY);
    if (storedCart) {
      cart.updateFrom(JSON.parse(storedCart));
    }
    return cart;
  }

  private save(cart: AvailabilityCart): void {
    this.storageService.setItem(CART_KEY, JSON.stringify(cart));
  }

  private dispatch(cart: AvailabilityCart): void {
    this.subscribers
      .forEach((sub) => {
        try {
          sub.next(cart);
        } catch (e) {
          // we want all subscribers to get the update even if one errors.
        }
      });
  }

  // 2=outside | 3=rental
  public addItem(product: any) {
    const cart = this.retrieve();
    // let item = cart.items.find((p) => p._id === product._id);
    // if (item === undefined) {
      let item = new AvailabilityItem();
      item._id = product._id;
      item.type = product.type;
      item.type_id = product.type_id;
      item.name = product.name;
      item.quantity = product.quantity;
      item.price = product.price;
      item.location = product.location;
      item.date = product.date;
      item.start_time = product.start_time;
      item.end_time = product.end_time;
      item.duration = product.duration;
      item.add_amount = product.add_amount;
      item.cleaning = product.cleaning;
      item.raw_data = product.raw_data;
      cart.items.push(item);
    // }
    this.calculateCart(cart);
    this.save(cart);
    this.dispatch(cart);
  }

  public removeItem(id:string) {
    const cart = this.retrieve();
    let item = cart.items.find((p) => p._id === id);
    if (item !== undefined) {
      item.quantity = 0;
    }
    cart.items = cart.items.filter((cartItem: any) => cartItem.quantity > 0);
    this.calculateCart(cart);
    this.save(cart);
    this.dispatch(cart);
  }
  
  private calculateCart(cart: AvailabilityCart): void {
    cart.itemsTotal = cart.items
      .map((item) => item.quantity * (item.price + item.add_amount + item.cleaning))
      .reduce((previous, current) => previous + current, 0);
    cart.grossTotal = cart.itemsTotal;
  }

}
