import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Inject,
  ElementRef,
  TemplateRef,
  HostListener
} from '@angular/core';
import { Event, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { RequestService } from '../../../services/request.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { filter } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ShoppingCartService } from "../../../services/shopping-cart.service";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  dashboard_url = '';
  cart: any;
  base = '';
  page = '';
  splitVal: any;
  donations: any;
  itemCount = 0;
  isLoggedIn: boolean = false;
  user_data: any;
  redirectUrl: string = '/';
  searchBox: boolean = false;
  Form: any = FormArray;
  e_menu: boolean = false;
  c_menu: boolean = false;

  main_menu:boolean = false;

  @HostListener('document:click', ['$event'])


  clickout(event:any) {
    if(this.eRef.nativeElement.contains(event.target)) {
      // console.log('inside');
    } else {
      this.e_menu = false;
      this.c_menu = false;
    }
  }

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private shoppingCartService: ShoppingCartService,
    public requestService: RequestService,
    private eRef: ElementRef,
    public router: Router
  ) { 
    if(this.authenticationService.isLoggedIn()){
      this.user_data = this.authenticationService.currentUserValue;
      this.dashboard_url = (this.user_data.connection_token) ? `${environment.adminUrl}?connection_token=${this.user_data.connection_token}` : '';
    }
    router.events.subscribe((val) => {
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.main_menu = false;
        }
    });
  });
  }

  toggleMainMenu(){
this.main_menu = !this.main_menu;
  }

  ngOnInit(): void {
    this.getData();
    this.cart = this.shoppingCartService.get();
    this.cart.subscribe((cart: any) => {
      this.itemCount = cart.items.map((x: any) => x.quantity).reduce((p: any, n: any) => p + n, 0);
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.splitVal = event.url.split('/');
        this.base = this.splitVal[1];
        this.page = this.splitVal[2];
        if (this.base !== undefined) {
          this.redirectUrl = '/' + this.base.split('?')[0];
        }
        if (this.page !== undefined) {
          this.redirectUrl = this.redirectUrl + '/' + this.page.split('?')[0];
        }
      }
    });
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.user_data = this.authenticationService.currentUserValue;
    this.Form = this.formBuilder.group({
      keyword: [''],
    });
  }

  get f() { return this.Form.controls; }

  submitForm() {
    this.searchBox = false;
    this.router.navigate(['/search'], { queryParams: { keyword: this.Form.value.keyword } });
  }

  openMenu(e:any,t: string) {
    e.stopPropagation();
    e.preventDefault();
    if (t === 'e') {
      this.c_menu = false;
      this.e_menu = !this.e_menu;
    } else {
      this.e_menu = false;
      this.c_menu = !this.c_menu;
    }
  }

  getData() {
    this.requestService.getDonation().subscribe((res) => {
      if (res.status === 1) {
        this.donations = res.data;
      } else {
        this.donations = [];
      }
    });
  }

  logout() {
    this.authenticationService.logout();
    location.reload();
  }

  toggleSearch() {
    this.searchBox = (this.searchBox) ? false : true;
  }

}

