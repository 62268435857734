import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(
        private http: HttpClient
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')  || '{}'));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue():User {
        return this.currentUserSubject.value;
    }

    registerDevotee(data: any) {
        return this.http.post<any>(`${environment.apiUrl}register`, data)
            .pipe(map(data => {
                if (data.status === 1) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('currentUser', JSON.stringify(data.data));
                    this.currentUserSubject.next(data.data);
                    return { status: 1 };
                } else {
                    return data;
                }
            }));
    }

    updateDevotee(data: any) {
        return this.http.post<any>(`${environment.apiUrl}update_profile`, data)
            .pipe(map(data => {
                if (data.status === 1) {
                    localStorage.setItem('currentUser', JSON.stringify(data.data));
                    this.currentUserSubject.next(data.data);
                    return { status: 1 };
                } else {
                    return data;
                }
            }));
    }

    loginDevotee(data: any) {
        return this.http.post<any>(`${environment.apiUrl}login`, data)
            .pipe(map(data => {
                if (data.status === 1) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('currentUser', JSON.stringify(data.data));
                    this.currentUserSubject.next(data.data);
                    return { status: 1 };
                } else {
                    return data;
                }
            }));
    }

    changePassword(data: any) {
        return this.http.post<any>(`${environment.apiUrl}change_password`, data)
            .pipe(map(data => {
                    return data;
            }));
    }

    updateUser(data: any) {
        localStorage.setItem('currentUser', JSON.stringify(data));
        this.currentUserSubject.next(data);
        return true;
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(new User());
    }

    isLoggedIn() {
        const currentUser = this.currentUserSubject.value;
        if (Object.keys(currentUser).length > 0) {
            return true;
        } else {
            return false;
        }
    }

}