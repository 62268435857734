import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { RequestService } from '../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { ShoppingCartService } from "../../services/shopping-cart.service";
import { RequiredValidator } from '../../services/validator-service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.css']
})
export class NewsdetailsComponent implements OnInit {
  nakshatrams:any;
  news: any;
  user_data: any;
  myForm: any;
  loading = false;
  submitted = false;
  error = '';

  constructor(
    private sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private shoppingCartService: ShoppingCartService
  ) {
    this.myForm = FormGroup;
  }

  transformHtml(value:any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  
  ngOnInit(): void {
    window.scrollTo(0, 0);
    const id = this.route.snapshot.params['id'];
    this.myForm = this.formBuilder.group({
      first_name_1: ['', [Validators.required,Validators.maxLength(35)]],
      last_name_1: ['', [Validators.required,Validators.maxLength(35)]],
      gotram_1: [''],
      nakshatram_1: [''],
      first_name_2: [''],
      last_name_2: [''],
      gotram_2: [''],
      nakshatram_2: [''],
      first_name_3: [''],
      last_name_3: [''],
      gotram_3: [''],
      nakshatram_3: [''],
      persons: this.formBuilder.array([]),
      price: ['', Validators.required],
      date: [''],
    }, { 
      validator: [
        RequiredValidator('first_name_1'),
        RequiredValidator('last_name_1')
      ]
    });
    this.getNews(id);
    if (this.authenticationService.isLoggedIn()) {
      this.syncUserDetail();
    }
  }

  persons(): FormArray {
    return this.myForm.get("persons") as FormArray
  }

  newpersons(): FormGroup {
    return this.formBuilder.group({
      first_name: [''],
      last_name: [''],
      gotram: [''],
      nakshatram: ['']
    });
  }

  addPersons() {
    this.persons().push(this.newpersons());
  }

  removePersons(i: number) {
    this.persons().removeAt(i);
  }

  syncUserDetail() {
    this.user_data = this.authenticationService.currentUserValue;
    this.myForm.controls['first_name_1'].setValue(this.user_data.first_name);
    this.myForm.controls['last_name_1'].setValue(this.user_data.last_name);
    this.myForm.controls['gotram_1'].setValue(this.user_data.gotram);
    this.myForm.controls['nakshatram_1'].setValue(this.user_data.nakshatram);
    if (this.user_data.spouse.length > 0) {
      var spouse = this.user_data.spouse[0];
      this.myForm.controls['first_name_2'].setValue(spouse.first_name);
      this.myForm.controls['last_name_2'].setValue(spouse.last_name);
      this.myForm.controls['gotram_2'].setValue(spouse.gotram);
      this.myForm.controls['nakshatram_2'].setValue(spouse.nakshatram);
    }
    if (this.user_data.childrens.length > 0) {
      var childrens = this.user_data.childrens;
      var self = this;
      childrens.forEach(function (value: any, i: number) {
        if (i === 0) {
          self.myForm.controls['first_name_3'].setValue(childrens[i].first_name);
          self.myForm.controls['last_name_3'].setValue(childrens[i].last_name);
          self.myForm.controls['gotram_3'].setValue(childrens[i].gotram);
          self.myForm.controls['nakshatram_3'].setValue(childrens[i].nakshatram);
        } else {
          self.persons().push(
            self.formBuilder.group({
              first_name: [childrens[i].first_name],
              last_name: [childrens[i].last_name],
              gotram: [childrens[i].gotram],
              nakshatram: [childrens[i].nakshatram]
            })
          );
        }
      });

    }
  }

  get f() { return this.myForm.controls; }

  submitForm() {
    this.submitted = true;

    if (this.myForm.invalid) {
      return;
    }
    let today : any = new Date();
    today = today.toLocaleString('en-US', { timeZone: 'America/New_York' })
    let date = this.datepipe.transform(new Date(today), 'MM/dd/yyyy');
    this.myForm.controls['date'].setValue(date);

    this.shoppingCartService.empty();

    this.shoppingCartService.addItem({
      _id: '',
      type: 'Donation',
      type_id: 5,
      name: 'Donation',
      quantity: 1,
      price: this.myForm.value.price,
      date: date,
      location: '',
      page:'services/donation',
      booking_config: '',
      raw_data: this.myForm.value
    }, 1);

    this.error = ''; 
    this.loading = false;

    this.router.navigate(['/checkout']);

  }

  getNews(id: string) {
    this.requestService.getNakshatrams().subscribe((res) => {
      if(res.status === 1){
        this.nakshatrams = res.data;
      } else {
        this.nakshatrams = [];
      }
    });
    this.requestService.getNewsDetail({ id }).subscribe((res) => {
      if (res.status === 1) {
        this.news = res.data;
        if(res.data.is_donation == 2){
          this.myForm.controls['price'].setValue(res.data.donation_amount);
        }
      } else {
        this.news = [];
      }
    });
  }

}