import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/request.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  latest_news: any;
  all_news: any = [];
  per_page_items: number = 10;
  total_items: number = 0;
  total_pages: number = 1;
  current_page: number = 1;
  loadPagination: boolean = false;
  loading:boolean = true;

  constructor(
    private sanitizer: DomSanitizer,
    public requestService: RequestService,
    ) { }
    transformHtml(value:any) {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getLatestNews();
    this.getNews();
  }

  getLatestNews(){
    this.requestService.getLatestNews(10).subscribe((res) => {
      if (res.status === 1) {
        this.latest_news = res.data;
      } else {
        this.latest_news = [];
      }
    });
  }

  changePage(page:number){
    this.current_page = page;
    this.getNews();
    window.scrollTo(0, 0);
  }

  getNews() {
    this.requestService.getAllNews({page:this.current_page}).subscribe((res) => {
      if(res.status === 1){
        this.all_news = res.data;
        this.total_pages = res.total_pages;
        this.total_items = res.total_items;
        if(res.total_pages > 1){
          this.loadPagination = true;
        }
      } else {
        this.all_news = [];
        this.total_pages = 1;
        this.total_items = 0;
        this.loadPagination = false;
      }
      this.loading = false;
    });
  }

}
