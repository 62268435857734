import {
  Component,
  OnInit,
} from '@angular/core';
import { Event, Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { AvailabilityCartService } from "../../../services/availability-cart.service";
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-availability-sidebar',
  templateUrl: './availability-sidebar.component.html',
  styleUrls: ['./availability-sidebar.component.css'],
})
export class AvailabilitySidebarComponent implements OnInit {
  base = '';
  page = '';
  splitVal: any;
  cart: any;
  cartItems: any;
  grossTotal = 0;
  isProceed = false;
  isLoggedIn: boolean = false;

  constructor(
    public router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private availabilityCartService: AvailabilityCartService
  ) {
    this.page = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.splitVal = event.url.split('/');
        this.base = this.splitVal[1];
        this.page = this.splitVal[2];
      }
    });
    this.getCartData();
    this.isProceed = this.availabilityCartService.isEmpty();
    this.isLoggedIn = this.authenticationService.isLoggedIn();
  }

  getCartData() {
    this.cart = this.availabilityCartService.get();
    this.cart.subscribe((cart: any) => {
      this.grossTotal = cart.grossTotal;
      this.cartItems = cart.items.map((item: any) => { 
        return { 
          ...item, 
          expand: false, 
          totalCost: item.quantity * (item.price + item.add_amount + item.cleaning) 
        }; 
      });
    });
  }

  hideShow(i: number, bl: boolean): void {
    this.cartItems[i].expand = (bl) ? false : true;
  }

  public emptyCart(): void {
    this.availabilityCartService.empty();
    this.getCartData();
  }

  public removeItem(product: any): void {
    this.availabilityCartService.removeItem(product._id);
    this.getCartData();
  }

  checkAvailability() {
    this.isProceed = this.availabilityCartService.isEmpty();
    if (!this.isProceed) {
        this.toastr.error("Please select any one for check availability"); return;
    }
    if(!this.isLoggedIn){
      this.router.navigate(['/login'],{ queryParams: { redirect: '/services/'+this.page  }}); return;
    }
    this.router.navigate(['/services/confirm-available'],{ queryParams: { redirect: '/services/'+this.page  }});
  }

}

