export class CartItem {
  public _id: string = '';
  public type: string = '';
  public type_id: number = 0;
  public name: string = '';
  public quantity: number = 0;
  public price: number = 0;
  public date: string = '';
  public cut_off_hours: number = 0;
  public cut_off_service_time: string = '0';
  public cut_off_type: number = 1;
  public location: string = '0';
  public booking_config: string = '';
  public raw_data: object = {};
  public page: string = '';
}
