import { Component, OnInit, TemplateRef } from '@angular/core';
import { RequestService } from '../../../services/request.service';

@Component({
  selector: 'app-temple',
  templateUrl: './temple.component.html',
  styleUrls: ['./temple.component.css'],
})
export class TempleComponent implements OnInit {  
  loading:boolean = true;
  services:any;

  constructor(
    public requestService: RequestService,
  ) {}

  ngOnInit(): void {
    this.getData();
    window.scrollTo(0, 0);
  }

  getData() {
    this.requestService.getServiceCategories().subscribe((res) => {
      if(res.status === 1){
        this.services = res.data;
      } else {
        this.services = [];
      }
    });
  }

}