<div role="main" class="main">
    <section class="page-header page-header-classic page-header-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
                    <span class="page-header-title-border visible" style="width: 85.3906px;"></span>
                    <h1 data-title-border="">LIVE STREAMING</h1>
                </div>
                <div class="col-md-4 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb d-block text-md-right">
                        <li><a [routerLink]="'/'">Home</a></li>
                        <li class="active">LIVE STREAMING</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <div class="container" *ngIf="urls.length > 0">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <h2>LIVE streaming available during</h2>
                <hr>
                <ul class="live_2">
                    <li><span>Event Name :</span>{{ urls[0].name }}</li>
                    <li><span>Event Timing :</span>{{ urls[0].timing }}</li>
                    <li><span>About the Events :</span>{{ urls[0].about }}</li>
                </ul>
                <div class="clearfix"></div>
                <hr/>
                <div class="row">
                    <div class="col-sm-4 col-md-4" *ngFor="let url of urls">
                        <a target="_blank" href="{{ url.url }}">
                            <img src="{{ url.image }}" class="live_b" /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>