import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { RequestService } from '../../services/request.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator } from '../../services/validator-service';
import { first } from 'rxjs/operators';
import { Event, Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  IPayPalConfig,
  ICreateOrderRequest
} from 'ngx-paypal';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  nakshatrams: any;
  countries: any;
  states: any;
  languages: any;
  userData: any;

  Form: any = FormArray;
  loading = false;
  submitted = false;

  married_box: boolean = false;
  children_box: boolean = false;
  @ViewChild('loginMdl') loginMdl: any;
  @ViewChild('confMdl') confMdl:any;
  ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
  };

  isDueCorporateBody: boolean = true;
  cbdues: any = [];
  public payPalConfig?: IPayPalConfig;
  clientId: string = environment.paypalClientId;
  currency: string = environment.paypalCurrency;
  payment_status_1: string = 'Please Wait....';
  payment_status_2:string = 'Your payment is currently being processed.';
  payment_status_3:string = 'Do not refresh or close you browser.';

  constructor(
    public requestService: RequestService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    public router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getData();
    this.Form = this.formBuilder.group({
      devotee_id: [''],
      first_name: ['', Validators.required],
      middle_name: [''],
      last_name: ['', Validators.required],
      dob: [''],
      nakshatram: [''],
      gotram: [''],
      email: ['', Validators.required],
      language: [''],
      is_married: [false],
      spouse: this.formBuilder.array([]),
      is_children: [false],
      childrens: this.formBuilder.array([]),
      address: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zip_code: ['', Validators.required],
      secondary_email: [''],
      home_phone: [''],
      office_phone: [''],
      contact_number: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      is_subscribed: [false],
      user_id: [''],
    });
  }

  private initConfig(due_data: any): void {
    this.payment_status_1 = 'Please Wait....';
    this.payment_status_2 = 'Your payment is currently being processed.';
    this.payment_status_3 = 'Do not refresh or close you browser.';
    this.payPalConfig = {
      currency: this.currency,
      clientId: this.clientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: this.currency,
              value: due_data.amount,
              breakdown: {
                item_total: {
                  currency_code: this.currency,
                  value: due_data.amount
                }
              }
            },
            items: [{
              name: 'CB membership',
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: this.currency,
                value: due_data.amount,
              }
            }],
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
        color: 'silver',
      },
      onApprove: (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        // actions.order.get().then(function(details:any) {
        //   console.log('onApprove - you can get full order details inside onApprove: ', details);
        // });
      },
      onClientAuthorization: (data) => {
        this.modalService.open(this.confMdl,this.ngbModalOptions);
        this.requestService.makeDuePayment(due_data, data, 1, this.userData.user_id).subscribe((res) => {
          if (res.status === 1) {
            this.payment_status_1 = '';
            this.payment_status_3 = '';
            this.payment_status_2 = 'Corporate Body Dues Paid successfully';
            // this.toastr.error('Corporate Body Dues Paid successfully');
            // this.shoppingCartService.empty();
            // this.localStorageService.removeItem('payout');
            // this.router.navigate(['/receipt/' + res.order_id]);
          } else {
            this.payment_status_1 = '';
            this.payment_status_3 = '';
            this.payment_status_2 = res.data;
            // this.toastr.error(res.data);
          }
        });
      },
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
        this.modalService.open(this.confMdl,this.ngbModalOptions);
        this.payment_status_1 = '';
        this.payment_status_3 = '';
        this.payment_status_2 = 'Payment canceled';
            // this.toastr.error('Payment canceled');
      },
      onError: err => {
        this.modalService.open(this.confMdl,this.ngbModalOptions);
        this.payment_status_1 = '';
        this.payment_status_3 = '';
        this.payment_status_2 = 'Payment not completed due to server error';
          // this.toastr.error('Payment not completed due to server error');
        // console.log('OnError', err);
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
      },
    };
  }

  reloadPage() {
    location.reload();
  }

  getData() {
    this.userData = this.authenticationService.currentUserValue;
    this.requestService.getProfile(this.userData.user_id).subscribe((res1) => {
      if (res1.status === 1) {
        this.userData = res1.data;
        this.syncUserDetail();
        this.requestService.getNakshatrams().subscribe((res) => {
          if (res.status === 1) {
            this.nakshatrams = res.data;
          } else {
            this.nakshatrams = [];
          }
        });
        this.requestService.getLanguages().subscribe((res) => {
          if (res.status === 1) {
            this.languages = res.data;
          } else {
            this.languages = [];
          }
        });
        this.requestService.getCountries().subscribe((res) => {
          if (res.status === 1) {
            this.countries = res.data;
            this.Form.controls['country'].setValue(this.userData.address.country);
            this.getStates(this.userData.address.country);
          } else {
            this.countries = [];
          }
        });
      }
    });
    this.requestService.checkCorporateBody(this.userData.user_id).subscribe((res2) => {
      if (res2.status == 1) {
        this.cbdues = res2.data;
        this.initConfig(res2.data[0]);
      } else if(res2.status == 2){
        this.cbdues = res2.data;
      } else {
        this.cbdues = [];
        this.isDueCorporateBody = false;
      }
    });
  }

  syncUserDetail() {
    this.Form.controls['first_name'].setValue(this.userData.first_name);
    this.Form.controls['middle_name'].setValue(this.userData.middle_name);
    this.Form.controls['last_name'].setValue(this.userData.last_name);
    this.Form.controls['dob'].setValue(this.userData.dob);
    let naksh = (this.userData.nakshatram === null) ? '' : this.userData.nakshatram;
    this.Form.controls['nakshatram'].setValue(naksh);
    this.Form.controls['gotram'].setValue(this.userData.gotram);
    this.Form.controls['email'].setValue(this.userData.email);
    let lang = (this.userData.language === null) ? '' : this.userData.language;
    this.Form.controls['language'].setValue(lang);
    this.Form.controls['is_married'].setValue(this.userData.is_married);
    this.Form.controls['is_children'].setValue(this.userData.has_children);
    this.Form.controls['address'].setValue(this.userData.address.address);
    this.Form.controls['city'].setValue(this.userData.address.city);
    this.Form.controls['zip_code'].setValue(this.userData.address.zipcode);
    this.Form.controls['secondary_email'].setValue(this.userData.secondary_email);
    this.Form.controls['home_phone'].setValue(this.userData.home_contact_number);
    this.Form.controls['office_phone'].setValue(this.userData.work_contact_number);
    this.Form.controls['contact_number'].setValue(this.userData.contact_number);
    let isSubscribed = (this.userData.is_subscribed === null) ? '' : this.userData.is_subscribed;
    this.Form.controls['is_subscribed'].setValue(isSubscribed);
    this.Form.controls['user_id'].setValue(this.userData.user_id);
    this.Form.controls['devotee_id'].setValue(this.userData.devotee_id);
    this.updateIsMarried();
    this.updateIsChildren();
  }

  onSelectCountry(event: any) {
    this.getStates(event.target.value);
  }

  getStates(country_id: string) {
    this.Form.controls['state'].setValue('');
    if (country_id === '') {
      this.states = [];
    } else {
      this.requestService.getStates(country_id).subscribe((res) => {
        if (res.status === 1) {
          this.states = res.data;
          this.Form.controls['state'].setValue(this.userData.address.state);
        }
      });
    }
  }

  changeIsMarried() {
    this.married_box = this.Form.value.is_married;
    if (this.married_box) {
      this.spouse().push(
        this.formBuilder.group({
          first_name: ['', Validators.required],
          middle_name: [''],
          last_name: ['', Validators.required],
          dob: [''],
          nakshatram: [''],
          email: [''],
        })
      );
    } else {
      this.spouse().removeAt(0);
    }
  }

  updateIsMarried() {
    this.married_box = this.Form.value.is_married;
    if (this.married_box) {
      this.spouse().push(
        this.formBuilder.group({
          first_name: [this.userData.spouse[0].first_name, Validators.required],
          middle_name: [this.userData.spouse[0].middle_name],
          last_name: [this.userData.spouse[0].last_name, Validators.required],
          dob: [this.userData.spouse[0].dob],
          nakshatram: [this.userData.spouse[0].nakshatram],
          email: [this.userData.spouse[0].email],
        })
      );
    } else {
      this.spouse().removeAt(0);
    }
  }

  spouse(): FormArray {
    return this.Form.get("spouse") as FormArray
  }

  changeIsChildren() {
    this.children_box = this.Form.value.is_children;
    if (this.children_box && (this.childrens()).length === 0) {
      this.addChildrens();
    }
  }

  updateIsChildren() {
    this.children_box = this.Form.value.is_children;
    if (this.children_box && this.userData.childrens.length > 0) {
      var childrens = this.userData.childrens;
      var self = this;
      childrens.forEach(function (value: any, i: number) {
        self.childrens().push(
          self.formBuilder.group({
            first_name: [childrens[i].first_name],
            middle_name: [childrens[i].middle_name],
            last_name: [childrens[i].last_name],
            dob: [childrens[i].dob],
            nakshatram: [childrens[i].nakshatram],
            email: [childrens[i].email]
          })
        );
      });

      // this.addChildrens();
    }
  }

  childrens(): FormArray {
    return this.Form.get("childrens") as FormArray
  }

  newChildrens(): FormGroup {
    return this.formBuilder.group({
      first_name: ['', Validators.required],
      middle_name: [''],
      last_name: ['', Validators.required],
      dob: [''],
      nakshatram: [''],
      email: [''],
    })
  }

  addChildrens() {
    this.childrens().push(this.newChildrens());
  }

  removeChildrens(i: number) {
    this.childrens().removeAt(i);
  }

  get f() { return this.Form.controls; }

  submitForm() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    }
    // this.modalService.open(this.loginMdl);
    // }

    // confirmSubmit() {
    // this.modalService.dismissAll();
    this.loading = true;
    this.authenticationService.updateDevotee(this.Form.value)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if (data.status === 1) {
            this.toastr.error('Profile updated successfully');
            setTimeout(() => { location.reload() }, 2000);
          } else {
            this.toastr.error(data.message);
            this.loading = false;
          }
        },
        error: error => {
          this.toastr.error(error);
          this.loading = false;
        }
      });
  }

}
