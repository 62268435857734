<div role="main" class="main form_bg">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Proceed to Pay</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Payment</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div role="main" class="main shop">

		<div class="container">

			<div class="container">


				<div class="row ">
					<div class="col-md-4 form_bg_bdr" style="margin: 0 auto;">
						<div class="panel panel-default credit-card-box">
							<div class="panel-heading display-table">
								<div class="row display-tr">
									<h3 class="panel-title display-td">Payment Details</h3>
									<div class="display-td">
										<img class="img-responsive pull-right" src="/assets/img/card_stripe.jpg">
									</div>
								</div>
							</div>
							<div class="panel-body">
								<ngx-paypal [config]="payPalConfig"></ngx-paypal>
							</div>
						</div>
					</div>

				</div>
			</div>

		</div>

	</div>
</div>