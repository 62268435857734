<footer id="footer">
	<div class="container">
		<div class="footer-ribbon">
			<span>Connect with Temple</span>
		</div>
		<div class="row py-5 my-4">
			<div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
				<h5 class="text-3 mb-3">NEWSLETTER</h5>
				<p class="pr-1">
					Please register your email address to be connected with temple and receive regular newsletters and
					events.
				</p>
				<div *ngIf="success" class="text-success" id="newsletterSuccess">
					{{success | titlecase}}
				</div>
				<div *ngIf="error" class="text-danger" id="newsletterError">
					{{error | titlecase}}
				</div>
				<form id="newsletterForm" [formGroup]="Form" (ngSubmit)="submitForm()" method="POST"
					class="mr-4 mb-3 mb-md-0">
					<div class="form-group f-name">
						<input type="text" formControlName="first_name" class="form-control form-control-lg"
							placeholder="First Name *">
					</div>
					<div class="form-group l-name">
						<input type="text" formControlName="last_name" class="form-control form-control-lg"
							placeholder="Last Name *">
					</div>
					<div class="form-group">
						<input type="text" formControlName="email" class="form-control form-control-lg"
							placeholder="Email *">
					</div>
					<div class="form-group">
						<input type="text" formControlName="contact_number" class="form-control form-control-lg"
							placeholder="Phone" required="">
					</div>
					<div class="sv_btn">
						<button type="submit" class="btn">Submit</button>
					</div>
				</form>
			</div>
			<div class="col-md-6 col-lg-3 mb-4 mb-lg-0">
				<h5 class="text-3 mb-3">Opening Hours</h5>
				<p class="text-4 mb-0"><span class="text-color-light">{{opening_hours}}</span></p>
				<!-- <p class="text-4 mb-0">Saturday: <span class="text-color-light">9:30 am to 1:00 pm</span></p> -->
				<!-- <p class="text-4 mb-0">Sunday: <span class="text-color-light">Closed</span></p> -->
				<!-- <p class="text-4 mb-0 footer_txt_bg">
					<a href="https://old.svtemple.org/" target="_blank">Corporate body website</a>
				</p> -->
			</div>
			<div class="col-md-6 col-lg-3 mb-4 mb-md-0">
				<div class="contact-details">
					<h5 class="text-3 mb-3">CONTACT US</h5>
					<ul class="list list-icons list-icons-lg">
						<li class="mb-1"><i class="far fa-dot-circle text-color-primary"></i>
							<p class="m-0">
								<a target="_blank"
									href="https://www.google.com/maps/dir//Sri+Venkateswara+Temple,+1230+S+McCully+Dr,+Penn+Hills,+PA+15235/@40.441794,-79.8052358,17z/">
									1230 S McCully Drive<br />Pittsburgh PA 15235
								</a>
							</p>
						</li>
						<li class="mb-1"><i class="fab fa-whatsapp text-color-primary"></i>
							<p class="m-0"><a href="tel:(412)373-3380">(412) 373-3380</a></p>
						</li>
						<li class="mb-1"><i class="far fa-envelope text-color-primary"></i>
							<p class="m-0"><a href="mailto:srivaru@svtemple.org">srivaru@svtemple.org</a></p>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-md-6 col-lg-2">
				<h5 class="text-3 mb-3">FOLLOW US</h5>
				<ul class="social-icons">
					<li class="social-icons-facebook"><a href="https://www.facebook.com/SVTemplePgh/" target="_blank"
							title="Facebook"><i class="fab fa-facebook-f f_icon"></i></a></li>
					<li class="social-icons-youtube"><a
							href="https://www.youtube.com/c/SriVenkateswaraTemplePittsburghOfficial/" target="_blank"
							title="Youtube"><i class="fab fa-youtube f_icon"></i></a></li>
				</ul>
			</div>
		</div>
		<div>
			<p class="text-center">Site best viewed in Chrome, Mozilla Firefox, Opera and Microsoft Edge</p>
		</div>
	</div>
	<div class="footer-copyright">
		<div class="container">
			<div class="row py-2">
				<div
					class="col-lg-4 d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0">
					<p>© 2020. Sri Venkateswara Temple All Rights Reserved.</p>
				</div>
				<div
					class="col-lg-4 d-flex align-items-center justify-content-center justify-content-lg-start mb-4 mb-lg-0">
					<p>Designed &amp; Developed by <a target="_blank" href="http://digiteratech.com/">Digitera
							Technologies</a> </p>

				</div>
				<div class="col-lg-4 d-flex align-items-center justify-content-center justify-content-lg-end">
					<nav id="sub-menu">
						<ul>
							<li><i class="fas fa-angle-right"></i><a [routerLink]="'/visit-info/faq'"
									class="ml-1 text-decoration-none"> FAQ's</a></li>
							<li><i class="fas fa-angle-right"></i><a target="_BLANK" href="/sitemap.xml"
									class="ml-1 text-decoration-none"> Sitemap</a></li>
							<li><i class="fas fa-angle-right"></i><a [routerLink]="'/about-us/contact-us'"
									class="ml-1 text-decoration-none"> Contact Us</a></li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>
</footer>

<a class="scroll-to-top hidden-mobile visible" (click)="scrolltotop()"><i class="fas fa-chevron-up"></i></a>