<div role="main" class="main">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Devotee Reset Password</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Login</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-12">

					<div class="row justify-content-md-center">
						<div class=" col-md-6">
							<div class="featured-box featured-box-primary text-left mt-0">
								<div class="box-content">
									<h4 class="color-primary font-weight-semibold text-4 text-uppercase mb-0">Devotee
										Reset
										Password</h4>
										<form *ngIf="status === 0" [formGroup]="Form" (ngSubmit)="submitForm()"
											class="needs-validation">
											<div class="form-row">
												<div class="form-group col">
													<label class="font-weight-bold text-dark text-2">New Password</label>
													<input type="password" formControlName="password" autocomplete="off"
														[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
														placeholder="New Password *"
														class="form-control form-control-lg" />
													<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
														<div *ngIf="f.password.errors.required">Password is
															required</div>
													</div>
												</div>
											</div>
											<div class="form-row">
												<div class="form-group col">
													<label class="font-weight-bold text-dark text-2">Confirm Password</label>
													<input type="password" formControlName="confirm_password" autocomplete="off"
														[ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }"
														placeholder="Confirm Password *"
														class="form-control form-control-lg" />
													<div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
														<div *ngIf="f.confirm_password.errors.required">Confirm password is
															required</div>
														<div *ngIf="f.confirm_password.errors.confirmedValidator">Password and Confirm password must be match.</div>
													</div>
												</div>
											</div>
											<div class="form-row">
												<div class="form-group col">
													<input type="submit" value="Submit"
														class="btn btn-primary btn-modern float-right"
														data-loading-text="Loading...">
												</div>
											</div>
										</form>
										<div *ngIf="status === 2" class="panel panel-default credit-card-box">
											<div class="panel-heading display-table">
												<div class="row display-tr">
													<i class="far fa-times-circle chk text-danger"></i>
													<h3 class="panel-title display-td suc_tt text-danger">Opps</h3>
													<p style="text-align:center;">{{ status_msg }}</p>
												</div>
											</div>
										</div>
										<div *ngIf="status === 1" class="panel panel-default credit-card-box">
											<div class="panel-heading display-table">
												<div class="row display-tr">
													<i class="far fa-check-circle chk"></i>
													<h3 class="panel-title display-td suc_tt">Greetings</h3>
													<p style="text-align:center;">{{ status_msg }}</p>
												</div>
											</div>
										</div>
								</div>
							</div>
						</div>
					</div>

			</div>
		</div>
	</div>
</div>