<div role="main" class="main">
    <section class="page-header page-header-classic page-header-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
                    <span class="page-header-title-border visible" style="width: 85.3906px;"></span>
                    <h1 data-title-border="">DONATIONS</h1>
                </div>
                <div class="col-md-4 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb d-block text-md-right">
                        <li><a [routerLink]="'/'">Home</a></li>
                        <li><a [routerLink]="'/services/donation'">Donations</a></li>
                        <li class="active">{{donation.title}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <div role="main" class="main">
        <div class="container py-4">
            <div class="row">
                <div class="col-lg-8 order-lg-1 donation-box">
                    <h2 class="word-rotator-title mb-0">
                        <strong>{{donation.title}}</strong>
                    </h2>
                    <div class="row">
                        <div class="col-md-12">
                            <hr class="tall" />
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="donation.image != ''" class="col-md-6 col-lg-6">
                            <img class="donation-image" src="{{donation.image}}" />
                            <p class="donation-description-with-image"
                                [innerHTML]="transformHtml(donation.description)"></p>
                        </div>
                        <div *ngIf="donation.image != ''" class="col-md-6 col-lg-6">
                            <div class="input-group">
                                <input type="text" placeholder="$ Donation Amount" value="{{ donation.amount }}"
                                    class="form-control" (keyup)="changeAmount($event)">
                                <a (click)="addProductToCart()" class="btn btn-primary btn-modern">ADD to Basket</a>
                            </div>
                            <p class="donation-description-without-image"
                                [innerHTML]="transformHtml(donation.description)"></p>
                        </div>
                        <div *ngIf="donation.image === ''" class="col-md-12 col-lg-12">
                            <p [innerHTML]="transformHtml(donation.description)"></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 order-lg-2">
                    <hr class="cart-top-border" />
                    <app-cart-sidebar></app-cart-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>