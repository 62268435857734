import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/request.service';

@Component({
  selector: 'app-daily-puja-schedule',
  templateUrl: './daily-puja-schedule.component.html',
  styleUrls: ['./daily-puja-schedule.component.css']
})
export class DailyPujaScheduleComponent implements OnInit {
  monday_schedules: any;
  tuesday_schedules: any;
  wednesday_schedules: any;
  thursday_schedules: any;
  friday_schedules: any;
  saturday_schedules: any;
  sunday_schedules: any;

  constructor(
    public requestService: RequestService,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getData();
  }

  getData() {
    this.requestService.getAllPujaSchedules().subscribe((res) => {
      if (res.status === 1) {
        this.monday_schedules = res.data.monday;
        this.tuesday_schedules = res.data.tuesday;
        this.wednesday_schedules = res.data.wednesday;
        this.thursday_schedules = res.data.thursday;
        this.friday_schedules = res.data.friday;
        this.saturday_schedules = res.data.saturday;
        this.sunday_schedules = res.data.sunday;
      }
    });
  }

}