<div role="main" class="main">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Monthly Almanac</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">ALMANAC</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div class="container-calender">
		<div class="row">
			<div class="col-sm-12 col-md-12" style="overflow-x: scroll;">
				<full-calendar style="min-width: 1100px;" [options]="calendarOptions" ></full-calendar>
			</div>
		</div>
	</div>
</div>