import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { RequestService } from '../../../services/request.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  Form: any = FormArray;
  opening_hours: string = '';
  error = '';
  success = '';

  constructor(
    public requestService: RequestService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      first_name: [''],
      last_name: [''],
      email: [''],
      contact_number: [''],
    });
    this.requestService.getDarshanTimings().subscribe((res) => {
      if (res.status === 1) {
        this.opening_hours = res.data.opening_hours;
      } else {
        this.opening_hours = '';
      }
    });
  }

  scrolltotop() {
    window.scrollTo(0, 0);
  }

  get f() { return this.Form.controls; }

  submitForm() {
    this.error = '';
    this.success = '';
    this.requestService.subscribe(this.Form.value).subscribe((res) => {
      if (res.status === 1) {
        this.success = res.message;
        this.Form.controls['email'].setValue('');
      } else {
        this.error = res.message;
      }
    });
  }

}
