<div role="main" class="main">
    <section class="page-header page-header-classic page-header-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
                    <span class="page-header-title-border visible" style="width: 85.3906px;"></span>
                    <h1 data-title-border="">PERSONAL SERVICES</h1>
                </div>
                <div class="col-md-4 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb d-block text-md-right">
                        <li><a [routerLink]="'/'">Home</a></li>
                        <li class="active">Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <div role="main" class="main">
        <div class="container py-4">
            <div class="row">
                <div class="col-lg-3 order-lg-2">
                    <app-cart-sidebar (toggleDonation)="toggleDonation($event)"></app-cart-sidebar>
                </div>
                <div class="col-lg-8 order-lg-1" style="border-right:1px solid #e4e4e4; margin-right:25px;">
                    <div class="blog-posts">
                        <div class="row justify-content-md-center">
                            <div class="flex-row row">
                                <div *ngFor="let service of services; let i=index" class="col-xs-6 col-sm-4 col-lg-4">
                                    <div class="thumbnail">
                                        <a
                                            [routerLink]="'/services/personal/detail/'+(service.slug != '' ?service.slug : service._id)"><img
                                                src="{{ service.image }}"
                                                class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0"
                                                alt="" /></a>
                                        <div class="caption">
                                            <h2 class="clr1 font-weight-semibold mb-2"><a
                                                    [routerLink]="'/services/personal/detail/'+(service.slug != '' ?service.slug : service._id)">{{
                                                    service.title }}</a></h2>
                                            <div class="input-group">
                                                <input type="text" class="form-control" [owlDateTime]="dt1"
                                                    [disabled]="true" [owlDateTimeTrigger]="dt1" [min]="min" readonly
                                                    (dateTimeInput)="selectDate(i,$event)" autocomplete="off"
                                                    data-select="datepicker" placeholder="Pick a date">
                                                <div class="input-group-append">
                                                    <button class="btn btn-secondary" [owlDateTimeTrigger]="dt1"
                                                        type="button">
                                                        <i class="fa fa-calendar"></i>
                                                    </button>
                                                </div>
                                                <select id="qty" class="" (change)="selectQty(i,$event)" size="0"
                                                    aria-invalid="false">
                                                    <option value="1" selected>1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                                <div class="clr1 ">
                                                    <span class="qty">Qty</span>
                                                </div>
                                                <owl-date-time [pickerMode]="'popup'" [pickerType]="'calendar'"
                                                    #dt1></owl-date-time>
                                            </div>
                                            <div>
                                                <a (click)="addProductToCart(service)" class="order_rbtn">Order Puja</a>
                                                <a class="order_lbtn">$ {{ service.amount }}</a>
                                            </div>
                                            <div class="flex-text" [innerHTML]="transformHtml(service.description)">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div [hidden]="!showDonation" class="checkbox-is-checked">
                        <hr />
                        <h2>Donations</h2>
                        <app-donation-block></app-donation-block>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>