<div class="pin-wrapper" *ngIf="scrollers.length > 0">
    <aside class="nav-secondary" id="navSecondary" data-plugin-sticky="">
        <div class="container">
            <div class="row">
                <!-- style="color:#6d0000; padding:4px 0; font-size:13px; font-weight: 500; letter-spacing:0.5px;" -->
                <marquee class="marq" onMouseOver="this.stop()" onMouseOut="this.start()" scrolldelay="150" loop="1"
                    scrollamount="6">
                    <span *ngFor="let scroller of scrollers">
                        <span style="color:#000;font-weight:600;">{{scroller.title}}:</span>
                        <span [innerHTML]="transformHtml(scroller.description)"></span>
                    </span>
                </marquee>
            </div>
        </div>
    </aside>
</div>
<div role="main" class="main">
    <app-home-slider></app-home-slider>

    <!-- <div class="pin-wrapper" style="">
        <aside class="nav-secondary" id="navSecondary" data-plugin-sticky="" style="">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-6">
                        <div class="nav-btn">
                            <div class="feature-box-icon">
                                <i class="icon-clock icons"></i>
                            </div>
                            Darshan Timings
                        </div>
                        <div class="nav_txt">{{darshan_timings}}</div>
                    </div>
                    <div class="col-sm-6 col-md-6">
                        <ul class="slider_menu">
                            <li><a [routerLink]="'/daily-puja-schedule'" class="nav_txt2">Daily Puja Schedule</a></li>
                            <li *ngIf="monthly_newsletter_pdf_url !== ''"><a target="_blank"
                                    href="{{monthly_newsletter_pdf_url}}" class="nav_txt2">Monthly Newsletter</a></li>
                            <li *ngIf="monthly_newsletter_pdf_url == ''"><a disabled href="javascript:void(0);"
                                    class="nav_txt2">Monthly Newsletter</a></li>
                            <li *ngIf="annual_calendar_pdf_url !== ''"><a target="_blank"
                                    href="{{annual_calendar_pdf_url}}" class="nav_txt2">Annual Calendar</a></li>
                            <li *ngIf="annual_calendar_pdf_url == ''"><a disabled href="javascript:void(0);"
                                    class="nav_txt2">Annual Calendar</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </aside>
    </div> -->

    <div class="pin-wrapper">
        <aside class="nav-secondary" id="navSecondary" data-plugin-sticky1="">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-6">
                        <div class="dharshan">
                            <div class="nav-btn">
                                <div class="feature-box-icon">
                                    <i class="icon-clock icons"></i>
                                </div>
                                Darshan Timings
                            </div>
                        </div>
                        <div class="nav_txt">{{darshan_timings}}</div>
                    </div>
                    <div class="col-sm-6 col-md-6">
                        <ul class="slider_menu">
                            <li><a [routerLink]="'/daily-puja-schedule'" class="nav_txt2">Daily Puja Schedule</a></li>
                            <li *ngIf="monthly_newsletter_pdf_url !== ''"><a target="_blank"
                                    href="{{monthly_newsletter_pdf_url}}" class="nav_txt2">Monthly Newsletter</a></li>
                            <li *ngIf="monthly_newsletter_pdf_url == ''"><a disabled href="javascript:void(0);"
                                    class="nav_txt2">Monthly Newsletter</a></li>
                            <li *ngIf="annual_calendar_pdf_url !== ''"><a target="_blank"
                                    href="{{annual_calendar_pdf_url}}" class="nav_txt2">Annual Calendar</a></li>
                            <li *ngIf="annual_calendar_pdf_url == ''"><a disabled href="javascript:void(0);"
                                    class="nav_txt2">Annual Calendar</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </aside>
    </div>


    <div class="home-intro" id="home-intro">
        <div class="container">

            <div class="row">
                <!-- <div class="side_m d-none d-sm-none d-lg-block d-md-block d-xl-block">
                    <div id="click1-tab"> <a class="tab tab1" [routerLink]="'/live-streaming'">Live Stream</a></div>
                    <div id="click2-tab"> <a class="tab tab2" target="_blank"
                            href="https://www.google.com/maps/dir//Sri+Venkateswara+Temple,+1230+S+McCully+Dr,+Penn+Hills,+PA+15235/@40.441794,-79.8052358,17z/">Map</a>
                    </div>
                    <div id="click3-tab"> <a class="tab tab3" [routerLink]="'/services/express'">Express Booking</a>
                    </div>
                    <div id="click4-tab"> <a class="tab tab4" [routerLink]="'/events/upcoming'">Upcoming Events</a>
                    </div>
                    <div *ngIf="survey_url != ''" id="click5-tab"> <a class="tab tab5" [href]="survey_url">Survey</a>
                    </div>
                </div> -->
                <!-- <div class="side_m_b d-block d-sm-block d-lg-none d-md-none d-xl-none"> -->
                <div class="side_m_b">
                    <div id="click1-tab"> <a class="tab tab1_mb" [routerLink]="'/live-streaming'"><i
                                class="fas fa-video" title="Live Stream"></i></a></div>
                    <div id="click2-tab"> <a class="tab tab2_mb" target="_blank"
                            href="https://www.google.com/maps/dir//Sri+Venkateswara+Temple,+1230+S+McCully+Dr,+Penn+Hills,+PA+15235/@40.441794,-79.8052358,17z/"><i
                                class="fas fa-map-marker-alt" title="Map"></i></a></div>
                    <div id="click3-tab"> <a class="tab tab3_mb" [routerLink]="'/services/express'"><i
                                class="far fa-file-alt" title="Express Booking"></i></a></div>
                    <div id="click4-tab"> <a class="tab tab4_mb" [routerLink]="'/events/upcoming'"><i
                                class="far fa-calendar-alt" title="Upcoming"></i></a></div>
                    <div *ngIf="survey_url != ''" id="click5-tab"> <a class="tab tab5_mb" [href]="survey_url"><i
                                class="fas fa-poll" title="Survey"></i></a></div>
                </div>

                <div class="col-sm-4 col-md-4 box1">
                    <h4 class="news"><span class="icon-clock"></span> Daily Pooja Schedule</h4>
                    <p>{{current_date | date:'EEEE, MMMM dd, YYYY'}}</p>
                    <marquee onMouseOver="this.stop()" onMouseOut="this.start()" direction="up" scrolldelay="150">
                        <div class="news_1">
                            <div class="single-row row" *ngFor="let puja of daily_puja_schedules">
                                <span class="dt col-lg-3">{{puja.time}}</span>
                                <span class="col-lg-9">{{puja.title}}</span>
                            </div>
                        </div>
                    </marquee>

                </div>
                <div class="col-sm-5 col-md-5 box2">
                    <div class="row">
                        <div class="col-sm-6 col-md-7">
                            <h4 class="news"><i class="icon-calendar icons"></i> Panchangam<br /><span
                                    style="font-size: 14px;">(Pittsburgh, USA Time)</span></h4>
                        </div>
                        <div class="col-sm-6 col-md-5">
                            <p style="font-size:14px;">{{current_date | date:'EEEE, MMMM dd,YYYY'}}</p>
                        </div>
                    </div>
                    <div class="row" *ngIf="panchang">
                        <div class="col-sm-4 col-md-7">
                            <ul class="list-2">
                                <li>
                                    Tithi :
                                    <span
                                        *ngIf="panchang.tithi1_name == '' && panchang.tithi1_time == null && panchang.tithi2_name == '' && panchang.tithi2_time == null">-</span>
                                    <span
                                        *ngIf="panchang.tithi1_name != '' && panchang.tithi1_time != null">{{panchang.tithi1_name}}
                                        till {{panchang.tithi1_time}}</span>
                                    <span
                                        *ngIf="panchang.tithi1_name != '' && panchang.tithi1_time == null">{{panchang.tithi1_name}}</span>
                                    <span
                                        *ngIf="panchang.tithi2_name != '' && panchang.tithi2_time != null">{{panchang.tithi2_name}}
                                        till {{panchang.tithi2_time}}</span>
                                    <span
                                        *ngIf="panchang.tithi2_name != '' && panchang.tithi2_time == null">{{panchang.tithi2_name}}</span>
                                </li>
                                <li>Yoga :
                                    <span *ngIf="panchang.yoga_name == '' && panchang.yoga_time == null">-</span>
                                    <span
                                        *ngIf="panchang.yoga_name != '' && panchang.yoga_time != null">{{panchang.yoga_name}}
                                        till {{panchang.yoga_time}}</span>
                                    <span
                                        *ngIf="panchang.yoga_name != '' && panchang.yoga_time == null">{{panchang.yoga_name}}</span>
                                </li>
                                <li>Yamagandam :
                                    <span
                                        *ngIf="panchang.yamaganda_begin == null && panchang.yamaganda_end == null">-</span>
                                    <span
                                        *ngIf="panchang.yamaganda_begin != null && panchang.yamaganda_end != null">{{panchang.yamaganda_begin}}
                                        - {{panchang.yamaganda_end}}</span>
                                    <span
                                        *ngIf="panchang.yamaganda_begin != null && panchang.yamaganda_end == null">{{panchang.yamaganda_begin}}</span>
                                    <span
                                        *ngIf="panchang.yamaganda_begin == null && panchang.yamaganda_end != null">{{panchang.yamaganda_end}}</span>
                                </li>
                                <li>Varjyam :
                                    <span
                                        *ngIf="panchang.varjya1_begin == null && panchang.varjya1_end == null && panchang.varjya2_begin == null && panchang.varjya2_end == null">-</span>
                                    <span
                                        *ngIf="panchang.varjya1_begin != null && panchang.varjya1_end != null">{{panchang.varjya1_begin}}
                                        - {{panchang.varjya1_end}}</span>
                                    <span
                                        *ngIf="panchang.varjya1_begin != null && panchang.varjya1_end == null">{{panchang.varjya1_begin}}</span>
                                    <span
                                        *ngIf="panchang.varjya1_begin == null && panchang.varjya1_end != null">{{panchang.varjya1_end}}</span>
                                    <span
                                        *ngIf="panchang.varjya2_begin != null && panchang.varjya2_end != null">{{panchang.varjya2_begin}}
                                        - {{panchang.varjya2_end}}</span>
                                    <span
                                        *ngIf="panchang.varjya2_begin != null && panchang.varjya2_end == null">{{panchang.varjya2_begin}}</span>
                                    <span
                                        *ngIf="panchang.varjya2_begin == null && panchang.varjya2_end != null">{{panchang.varjya2_end}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-4 col-md-5">
                            <ul class="list-2">
                                <li>Nakshatra :
                                    <span
                                        *ngIf="panchang.nakshatram1_name == '' && panchang.nakshatram1_time == null && panchang.nakshatram2_name == '' && panchang.nakshatram2_time == null">-</span>
                                    <span
                                        *ngIf="panchang.nakshatram1_name != '' && panchang.nakshatram1_time != null">{{panchang.nakshatram1_name}}
                                        till {{panchang.nakshatram1_time}}</span>
                                    <span
                                        *ngIf="panchang.nakshatram1_name != '' && panchang.nakshatram1_time == null">{{panchang.nakshatram1_name}}</span>
                                    <span
                                        *ngIf="panchang.nakshatram2_name != '' && panchang.nakshatram2_time != null">{{panchang.nakshatram2_name}}
                                        till {{panchang.nakshatram2_time}}</span>
                                    <span
                                        *ngIf="panchang.nakshatram2_name != '' && panchang.nakshatram2_time == null">{{panchang.nakshatram2_name}}</span>
                                </li>
                                <li>Rahukalam :
                                    <span
                                        *ngIf="panchang.rahukala_begin == null && panchang.rahukala_end == null">-</span>
                                    <span
                                        *ngIf="panchang.rahukala_begin != null && panchang.rahukala_end != null">{{panchang.rahukala_begin}}
                                        - {{panchang.rahukala_end}}</span>
                                    <span
                                        *ngIf="panchang.rahukala_begin != null && panchang.rahukala_end == null">{{panchang.rahukala_begin}}</span>
                                    <span
                                        *ngIf="panchang.rahukala_begin == null && panchang.rahukala_end != null">{{panchang.rahukala_end}}</span>
                                </li>
                                <li>
                                    <span><img class="sun-img"
                                            src="/assets/img/sunrise.png" />{{panchang.sunrise}}</span>
                                    <span><img class="sun-img" src="/assets/img/sunset.png" />{{panchang.sunset}}</span>
                                </li>
                            </ul>
                            <div><a class="nav-btn2 nav-btn2a" [routerLink]="['/almanac']"
                                    routerLinkActive="router-link-active">ALMANAC</a></div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-md-3 box1">
                    <h4 class="news"><span class="glyphicon glyphicon-list-alt"></span> Latest News</h4>
                    <div class="panel panel-default" style="margin-bottom: 0px;">
                        <div class="panel-body">
                            <ul class="_demo1 list-3">
                                <li class="news-item" *ngFor="let n of news">{{n.date}}<span><a
                                            [routerLink]="['/news',n.slug != '' ?n.slug : n._id]">{{n.title}}</a></span>
                                </li>
                            </ul>
                        </div>
                        <div class="panel-footer"> </div>
                    </div>
                    <div><a class="nav-btn2 nav-btn2a" [routerLink]="'/news'">View All News</a></div>
                </div>
            </div>
        </div>
    </div>

    <section class="section-height-3 m-top" *ngIf="spotlight && spotlight.is_enable_spolight == 1">
        <div class="container">
            <div class="row">
                <div
                    [ngClass]="[spotlight.is_enabled_image == 1 || spotlight.is_enabled_video == 1 ? 'col-lg-8 pb-sm-8 pb-lg-0 pr-lg-5 mb-sm-5 mb-lg-0' : 'col-lg-12 pb-sm-8 pb-lg-0 pr-lg-5 mb-sm-5 mb-lg-0']">
                    <div class="custom-post-style-2">
                        <h2 class="text-color-dark font-weight-normal text-6 mb-2 spot-ttl">{{spotlight.title}}</h2>
                        <h5 class="event_tt">{{spotlight.sub_title}}</h5>
                        <div class="post-meta">
                            {{spotlight.date}}
                        </div>
                    </div>
                    <div [innerHTML]="transformHtml(spotlight.description)"></div>
                </div>
                <div class="col-sm-4 col-md-4"
                    *ngIf="spotlight.is_enabled_image == 1 || spotlight.is_enabled_video == 1">
                    <div class="portfolio-item">
                        <a href="#" *ngIf="spotlight.is_enabled_image == 1">
                            <span class="thumb-info thumb-info-lighten border-radius-0">
                                <span class="thumb-info-wrapper border-radius-0">
                                    <img src="{{spotlight.image}}" class="img-fluid border-radius-0" alt="">
                                </span>
                            </span>
                        </a>
                        <div *ngIf="spotlight.is_enabled_video == 1">
                            <iframe width="100%" height="146" [src]="transform(spotlight.video_url)" frameborder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                            <a class="watch-btn" href="{{spotlight.video_url}}" target="_blank">Watch on Youtube</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-height-3 m-0 border-0">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-8 pb-sm-8 pb-lg-0 pr-lg-5 mb-sm-5 mb-lg-0">
                    <h2 class="text-color-dark font-weight-normal text-6 mb-2">
                        Sri Venkateswara Temple Mission
                    </h2>
                    <p>The Mission of Sri Venkateswara Temple in Penn Hills, Pennsylvania is to provide a place for
                        Hindus to worship in traditional style and to provide religious, humanitarian, cultural and
                        educational resources to all interested in Hinduism. In addition, Sri Venkateswara Temple shall
                        propagate ideals for world peace, harmony, spiritual and personal health through the tenets
                        underlying Hindu faith which is Sanatana Dharma. Sri Venkateswara Temple (S. V. Temple) is
                        located in Penn Hills, an eastern suburb of Pittsburgh, PA, USA. It is one of the earliest
                        traditional Hindu Temples built in the United States. Sri Venkateswara Temple organization was
                        established on Aug 7, 1975. Assistance from Tirumal Tirupathi Devasthanam (TTD) for construction
                        of the temple was requested on Sept 11, 1975 and granted on the same day. On June 30, 1976
                        ground breaking was performed and was followed by pratisthapana on Nov 17, 1976. Daily worship
                        is being performed continuously ever since.</p>
                    <a class="nav-btn2" [routerLink]="'/about-us/temple-history'">READ MORE</a>
                </div>
                <div class="col-sm-4 col-md-4">
                    <img src="/assets/img/home_img1.jpg" />
                </div>
            </div>
        </div>
    </section>

    <section class="call-to-action call-to-action-strong-grey call-to-action-in-footer"
        [hidden]="!gallery || gallery.length <= 3">
        <div class="container">
            <div class="text-center">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let g of gallery" class="slide">
                        <img src="{{ g }}" alt="" width="100%">
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </section>

</div>