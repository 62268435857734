import { Component, OnInit, TemplateRef } from '@angular/core';
import { RequestService } from '../../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

import { AvailabilityCartService } from "../../../services/availability-cart.service";

@Component({
  selector: 'app-outside-temple',
  templateUrl: './outside-temple.component.html',
  styleUrls: ['./outside-temple.component.css'],
})
export class OutsideTempleComponent implements OnInit {
  loading: boolean = true;
  services: any;
  min = new Date();

  constructor(
    public requestService: RequestService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private availabilityCartService: AvailabilityCartService
  ) { }

  ngOnInit(): void {
    let today = new Date();
    today = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    this.min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    this.getData();
    window.scrollTo(0, 0);
  }

  getData() {
    this.requestService.getOutsideService().subscribe((res) => {
      if (res.status === 1) {
        this.services = res.data;
      } else {
        this.services = [];
      }
    });
  }

  selectLocation(i: number, event: any) {
    this.services[i].location = event.target.value;
  }

  selectDate(i: number, event: any) {
    let selected = new Date(event.value);
    let month = selected.getMonth()+1;
    let date = month+'/'+selected.getDate()+'/'+selected.getFullYear();
    this.services[i].date = date; //this.datepipe.transform(event.value, 'MM/dd/yyyy');
  }

  public addProductToCart(product: any): void {
    if (product.is_online === 0) {
      this.toastr.error(product.booking_message);
      return;
    }
    if (product.date === '') {
      this.toastr.error('Please select the date first');
      return;
    }
    // let isAlreadyExists = this.availabilityCartService.isAlreadyExists(product._id);
    // if (isAlreadyExists) {
    //   this.toastr.error('You have already added for inquiry');
    //   return;
    // }
    let today : any = new Date();
    today = today.toLocaleString('en-US', { timeZone: 'America/New_York' })
    today = new Date(today);
    let selected = new Date(product.date);
    if(today.getFullYear() === selected.getFullYear() && 
    today.getMonth() === selected.getMonth() && 
    today.getDate()  === selected.getDate()){
      var morning = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9, 0, 0);
      if(morning.getTime() < today.getTime()){
        this.toastr.error('Same day service booking allowed before 9AM Eastern Time');
        return;
      }
    }
    this.toastr.error('Service added successfully on basket');
    this.availabilityCartService.addItem({
      _id: product._id,
      type: 'Outside Service',
      type_id: 2,
      name: product.title,
      quantity: 1,
      price: product.amount,
      date: product.date,
      location: product.location,
      start_time: '',
      end_time: '',
      duration: 0,
      add_amount: 0,
      cleaning: 0,
      raw_data: product,
    });
    window.scrollTo(0, 0);
  }

}