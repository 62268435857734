<div class="flex-row row donation-block">
    <div *ngFor="let donation of donations; let i=index" class="col-xs-6 col-sm-4 col-lg-4">
        <div class="thumbnail">
            <a [routerLink]="['/services/donation/detail',(donation.slug != '' ?donation.slug : donation._id)]"
                routerLinkActive="router-link-active"><img src="{{ donation.image }}"
                    class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt="" /></a>
            <div class="caption">
                <h2 class="clr1 font-weight-semibold mb-2">
                    <a [routerLink]="['/services/donation/detail',(donation.slug != '' ?donation.slug : donation._id)]"
                        routerLinkActive="router-link-active">{{ donation.title }}</a>
                </h2>
                <!-- <div class="input-group">
                    <input type="text" class="form-control" [owlDateTime]="dt1" [disabled]="true"
                        [owlDateTimeTrigger]="dt1" [min]="min" readonly (dateTimeInput)="selectDate(i,$event)"
                        autocomplete="off" [value]="donation.date" data-select="datepicker" placeholder="Pick a date" />
                    <div class="input-group-append">
                        <button class="btn btn-secondary" [owlDateTimeTrigger]="dt1" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </div> -->
                <!-- <select id="qty" class="" (change)="selectQty(i,$event)" size="0" aria-invalid="false">
                        <option value="1" selected>1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                    <div class="clr1 ">
                        <span class="qty">Qty</span>
                    </div> -->
                <!-- <owl-date-time [pickerMode]="'popup'" [pickerType]="'calendar'" #dt1>
                    </owl-date-time>
                </div> -->
                <p class="flex-text">{{ donation.description }}</p>
                <div class="form-group">
                    <input type="text" placeholder="$ Donation Amount" value="{{ donation.amount }}"
                        class="form-control form-control-lg" (keyup)="changeAmount(i,$event)">
                </div>
                <a (click)="addProductToCart(donation)" class="btn btn-primary btn-modern">ADD to Basket</a>
            </div>
        </div>
    </div>
</div>