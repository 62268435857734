export class AvailabilityItem {
  public _id: string = '';
  public type: string = '';
  public type_id: number = 0;
  public name: string = '';
  public quantity: number = 0;
  public price: number = 0;
  public date: string = '';
  
  public duration: number = 0;
  public start_time: string = '';
  public end_time: string = '';
  public add_amount: number = 0;
  public cleaning: number = 0;
  
  public location: string = '0';
  
  public raw_data: object = {};
}
