import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/request.service';
import { CalendarOptions } from '@fullcalendar/angular';

@Component({
  selector: 'app-almanac',
  templateUrl: './almanac.component.html',
  styleUrls: ['./almanac.component.css']
})
export class AlmanacComponent implements OnInit {
  calendarOptions: any = {
    buttonText: {
      today: "Today's Almanac"
    }
  };
  Events = [];

  constructor(
    public requestService: RequestService,
  ) { }

  ngOnInit(): void {
    // this.calendarOptions = CalendarOptions;
    window.scrollTo(0, 0);
    // setTimeout(() => {
      this.requestService.getFullPanchang().subscribe((res) => {
        if (res.status === 1) {
          this.Events = res.data;
        } else {
          this.Events = [];
        }
        this.calendarOptions = {
          initialView: 'dayGridMonth',
          events: this.Events,
          eventContent: function (eventInfo: any) {
            return { html: eventInfo.event.extendedProps.customHtml }
          },
          height: 2800,
          buttonText: {
            today: "Today's Almanac"
          }

        };
      });
    // }, 2200);
  }

}