import { Component, OnInit, TemplateRef } from '@angular/core';
import { RequestService } from '../../../services/request.service';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-location-directions',
  templateUrl: './location-directions.component.html',
  styleUrls: ['./location-directions.component.css'],
})
export class LocationDirectionsComponent implements OnInit {  
  loading:boolean = true;
  url:any;
  description:String= '';

  constructor(
    public requestService: RequestService,
    private domSanitizer : DomSanitizer
    ) {}

  transformHtml(value: any) {
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.requestService.getVisitLocation().subscribe((res) => {
      if (res.status === 1) {
        this.description = res.data.description;
        this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(res.data.url);
      } else {
        this.description = '';
        this.url = '';
      }
    });
  }

}