import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { RequestService } from '../../services/request.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator } from '../../services/validator-service';
import { first } from 'rxjs/operators';
import { Event, Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-donation-report',
  templateUrl: './donation-report.component.html',
  styleUrls: ['./donation-report.component.css']
})
export class DonationReportComponent implements OnInit {
  userData: any;
  years: any;
  selected_year: number = 0;
  amount: number = 0;
  is_show_report: boolean = false;
  Form: any = FormArray;
  loading = false;
  submitted = false;

  constructor(
    public requestService: RequestService,
    private authenticationService: AuthenticationService,
    public router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.userData = this.authenticationService.currentUserValue;
    const today = new Date();
    this.selected_year = today.getFullYear();
    const year = today.getFullYear();
    this.years = [year, year - 1, year - 2];
    window.scrollTo(0, 0);
    this.Form = this.formBuilder.group({
      year: ['', Validators.required],
      user_id: [this.userData.user_id, Validators.required]
    });
  }

  submitForm() {
    this.submitted = true;
    if (this.Form.invalid) {
      this.toastr.error("Please select year");
      return;
    }
    this.loading = true;
    this.requestService.getDonationReport(this.Form.value).subscribe((res) => {
      if (res.status === 1) {
        this.is_show_report = true;
        this.amount = res.amount;
        this.selected_year = res.year;
        this.loading = false;
      } else {
        this.is_show_report = false;
        this.toastr.error(res.message);
        this.loading = false;
      }
    });
  }

}
