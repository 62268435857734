import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { Event, Router,ActivatedRoute , NavigationStart } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  Form: any = FormArray;
  loading = false;
  submitted = false;
  login_by:string = 'Username';
  redirect:string = '/';

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    public router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
      this.redirect = (params['redirect'] === undefined) ? '/' : params['redirect'];
    });
    window.scrollTo(0, 0);
    this.Form = this.formBuilder.group({
      login_by: ['username', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate([this.redirect]);
    }
  }

  get f() { return this.Form.controls; }

  changeLoginBy() {
    this.login_by = this.Form.value.login_by;
    this.login_by = this.login_by.replace(/_/gi, " ");
  }

  submitForm() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService.loginDevotee(this.Form.value)
      .pipe(first())
      .subscribe({
        next: (data:any) => {
          if(data.status === 1){
            window.location.reload();
          } else {
            this.toastr.error(data.message);
            this.loading = false;
          }
        },
        error: error => {
          this.toastr.error(error);
          this.loading = false;
        }
      });
  }

}
