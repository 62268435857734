<div role="main" class="main">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">{{year}} YEAR END REPORT</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">ABOUT US</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-12">
				<div style="background: #f9f9f9; padding: 35px 46px; border: 1px solid #dfdfdf; text-align: center;">
					<p
						style="margin-bottom: 4px; text-align:center; margin: 34px 0 20px 0; color: #323232; font-weight: bold;">
						{{title}}</p>
					<div [innerHTML]="transformHtml(description)"></div>
				</div>
			</div>




		</div>
	</div>

</div>