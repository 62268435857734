<div role="main" class="main form_bg">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Change Password</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Change Password</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div class="container form_bg_bdr">
		<div class="row">
			<div class="col-sm-12 col-md-12">
				<form [formGroup]="Form" (ngSubmit)="submitForm()">
					<div class="form-row">
						<div class="form-group col-lg-4">
							<input type="password" formControlName="old_password" autocomplete="off"
								[ngClass]="{ 'is-invalid': submitted && f.old_password.errors }"
								placeholder="Old password *" class="form-control form-control-lg" />
							<div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
								<div *ngIf="f.old_password.errors.required">Old password is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<input type="password" formControlName="new_password" autocomplete="off"
								[ngClass]="{ 'is-invalid': submitted && f.new_password.errors }" placeholder="New password *"
								class="form-control form-control-lg" />
							<div *ngIf="submitted && f.new_password.errors" class="invalid-feedback">
								<div *ngIf="f.new_password.errors.required">New password is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<input type="password" formControlName="confirm_password" autocomplete="off"
								[ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" placeholder="Confirm password *"
								class="form-control form-control-lg" />
							<div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
								<div *ngIf="f.confirm_password.errors.required">Confirm password is required</div>
								<div *ngIf="f.confirm_password.errors.confirmedValidator">Password and Confirm password
									must be match.</div>
							</div>
						</div>
					</div>
					<div class="form-row" style="margin-bottom:10px;">
						<div class="form-group col">
							<button type="submit" class="btn btn-primary btn-modern">Submit</button>
							<!-- <input type="submit" value="Cancel" class="btn btn-primary btn-modern"> -->
						</div>

					</div>
				</form>
			</div>
		</div>

	</div>