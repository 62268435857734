<div role="main" class="main">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">NEWS & EVENTS</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">NEWS & EVENTS</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
	<div class="container py-4">

		<div class="row">

			<div class="col-lg-3 order-lg-2">
				<aside class="sidebar">

					<h5 class="font-weight-bold pt-4">Latest News</h5>
					<ul class="nav nav-list flex-column mb-5">
						<li class="nav-item" *ngFor="let news of latest_news"><a class="nav-link"
								[routerLink]="['/news',news.slug != '' ?news.slug : news._id]">{{news.title}}</a></li>
					</ul>
					<!-- <div class="tabs tabs-dark mb-4 pb-2">
						<ul class="nav nav-tabs">
							<li class="nav-item active"><a
									class="nav-link show active text-1 font-weight-bold text-uppercase"
									href="#popularPosts" data-toggle="tab">Popular</a></li>
							<li class="nav-item"><a class="nav-link text-1 font-weight-bold text-uppercase"
									href="#recentPosts" data-toggle="tab">Recent</a></li>
						</ul>
						<div class="tab-content">
							<div class="tab-pane active" id="popularPosts">
								<ul class="simple-post-list">
									<li>
										<div class="post-image">
											<div class="img-thumbnail img-thumbnail-no-borders d-block">
												<a href="blog-post.html">
													<img src="img/blog/square/blog-11.jpg" width="50" height="50"
														alt="">
												</a>
											</div>
										</div>
										<div class="post-info">
											<a href="blog-post.html">KARTIKA MASA PUJA FOR AKKA DEVATALU</a>
											<div class="post-meta">
												Dec' 3, 2020
											</div>
										</div>
									</li>
									<li>
										<div class="post-image">
											<div class="img-thumbnail img-thumbnail-no-borders d-block">
												<a href="blog-post.html">
													<img src="img/blog/square/blog-24.jpg" width="50" height="50"
														alt="">
												</a>
											</div>
										</div>
										<div class="post-info">
											<a href="blog-post.html">VAIKUNTHA DWADASI UTSAVAM HELD IN LOCAL TEMPLES</a>
											<div class="post-meta">
												Dec' 26, 2020
											</div>
										</div>
									</li>
									<li>
										<div class="post-image">
											<div class="img-thumbnail img-thumbnail-no-borders d-block">
												<a href="blog-post.html">
													<img src="img/blog/square/blog-42.jpg" width="50" height="50"
														alt="">
												</a>
											</div>
										</div>
										<div class="post-info">
											<a href="blog-post.html">EIGHTH EDITION OF AKHANDA SUNDARAKANDA PARAYANAM
												HELD</a>
											<div class="post-meta">
												Sep' 10, 2020
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div class="tab-pane" id="recentPosts">
								<ul class="simple-post-list">
									<li>
										<div class="post-image">
											<div class="img-thumbnail img-thumbnail-no-borders d-block">
												<a href="blog-post.html">
													<img src="img/blog/square/blog-24.jpg" width="50" height="50"
														alt="">
												</a>
											</div>
										</div>
										<div class="post-info">
											<a href="blog-post.html">DRY SEED GARLANDS STEAL THE SHOW DURING
												SNAPANAM</a>
											<div class="post-meta">
												Sep' 20, 2020
											</div>
										</div>
									</li>
									<li>
										<div class="post-image">
											<div class="img-thumbnail img-thumbnail-no-borders d-block">
												<a href="blog-post.html">
													<img src="img/blog/square/blog-42.jpg" width="50" height="50"
														alt="">
												</a>
											</div>
										</div>
										<div class="post-info">
											<a href="blog-post.html">EIGHTH EDITION OF SUNDARAKANDA AKHANDA PARAYANA ON
												JAN 2</a>
											<div class="post-meta">
												Dec 29, 2020
											</div>
										</div>
									</li>
									<li>
										<div class="post-image">
											<div class="img-thumbnail img-thumbnail-no-borders d-block">
												<a href="blog-post.html">
													<img src="img/blog/square/blog-11.jpg" width="50" height="50"
														alt="">
												</a>
											</div>
										</div>
										<div class="post-info">
											<a href="blog-post.html">SRIVARI PRANAYOTSAVAM ON DECEMBER 30</a>
											<div class="post-meta">
												Dec 27, 2018
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div> -->
				</aside>
			</div>
			<div class="col-lg-9 order-lg-1">
				<div class="blog-posts">
					<div class="card" *ngIf="all_news.length === 0 && !loading">
						<div class="card-body text-center">
							<p class="text-center">
								Currently there are no news, please check in future for all updated news.</p>
						</div>
					</div>
					<article class="post post-medium with-sep"
						*ngFor="let news of all_news | paginate: { itemsPerPage: per_page_items, currentPage: current_page, totalItems: total_items }; let i = index">
						<div class="row mb-3">
							<div class="col-lg-5">
								<div class="post-image">
									<img src="{{news.image}}" class="img-fluid" alt="" />
								</div>
							</div>
							<div class="col-lg-7">
								<div class="post-content">
									<h2 class="line-height-4 mb-2"><a style="color:#9d5f03"
											[routerLink]="['/news',news.slug != '' ?news.slug : news._id]">{{news.title}}</a>
									</h2>
									<div class="post-meta">
										<span><i class="far fa-calendar-alt"></i> {{news.date}} </span>
									</div>
									<p class="mb-0" [innerHTML]="transformHtml(news.description)"></p>
								</div>
							</div>
						</div>
						<div *ngIf="i+1<all_news.length" class="sep-box"><img class="sep-img"
								src="/assets/img/sepation.png" /></div>
					</article>

					<div *ngIf="loadPagination">
						<pagination-controls class="my-pagination" (pageChange)="changePage($event)" previousLabel=""
							nextLabel="">
						</pagination-controls>
					</div>

				</div>
			</div>
		</div>

	</div>

</div>