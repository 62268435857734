import { AvailabilityItem } from './availability-item.model';

export class AvailabilityCart {
  public items: AvailabilityItem[] = new Array<AvailabilityItem>();
  public itemsTotal: number = 0;
  public grossTotal: number = 0;

  public updateFrom(src: AvailabilityCart) {
    this.items = src.items;
    this.itemsTotal = src.itemsTotal;
    this.grossTotal = src.grossTotal;
  }
}