import { Component, OnInit, TemplateRef } from '@angular/core';
import { RequestService } from '../../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

import { ShoppingCartService } from "../../../services/shopping-cart.service";

@Component({
  selector: 'app-donation-block',
  templateUrl: './donation-block.component.html',
  styleUrls: ['./donation-block.component.css'],
})
export class DonationBlockComponent implements OnInit {  
  loading:boolean = true;
  donations:any;
  min=new Date();

  constructor(
    public requestService: RequestService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private shoppingCartService: ShoppingCartService
  ) {}

  ngOnInit(): void {
    let today = new Date();
    today = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    this.min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    this.getData();
    window.scrollTo(0, 0);
  }

  getData() {
    this.requestService.getDonation().subscribe((res) => {
      if(res.status === 1){
        this.donations = res.data;
      } else {
        this.donations = [];
      }
    });
  }

  selectDate(i:number,event:any){ 
    this.donations[i].date = event.value;
  }
  
  selectQty(i:number,event:any){
    this.donations[i].quantity = event.target.value;
  }
  
  changeAmount(i:number,event:any){
    this.donations[i].amount = event.target.value;
  }

  public addProductToCart(product:any): void {
    if(product.date === ''){
      this.toastr.error('Please select the date first');
      return;
    }
    if (product.amount < 1 || product.amount == 0 || product.amount == '' || product.amount == null) {
      this.toastr.error('Please add donation amount');
      return;
    }
    let isAlreadyExists = this.shoppingCartService.isAlreadyExists(product._id);
    if(isAlreadyExists){
      this.shoppingCartService.removeQty(product._id);
      // this.shoppingCartService.addItem(product, 0);
    }
    let qty = parseInt(product.quantity);
    this.toastr.error('Donation added successfully on puja basket');
    let selected = new Date(product.date);
    let month = selected.getMonth()+1;
    let date = month+'/'+selected.getDate()+'/'+selected.getFullYear();

    this.shoppingCartService.addItem({
      _id : product._id,
      type : 'Donation',
      type_id : 5,
      name : product.title,
      quantity : 1,
      price : product.amount,
      page:'services/donation',
      date : date, //this.datepipe.transform(product.date, 'MM/dd/yyyy'),
      location : '',
      booking_config : '',
    }, qty);
  }

}