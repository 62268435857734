<div role="main" class="main">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Devotee Forgot Devotee Id</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Login</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-12">

				<div class="row justify-content-md-center">
					<div class=" col-md-6">
						<div class="featured-box featured-box-primary text-left mt-0">
							<div class="box-content">
								<h4 class="color-primary font-weight-semibold text-4 text-uppercase mb-0">Devotee
									Forgot
									Devotee Id</h4>
								<form *ngIf="status === 0" [formGroup]="Form" (ngSubmit)="submitForm()"
									class="needs-validation">
									<div class="form-row">
										<div class="form-group col">
											<label class="font-weight-bold text-dark text-2">Please enter your Email
												id</label>
											<input type="text" formControlName="username" autocomplete="off"
												[ngClass]="{ 'is-invalid': submitted && f.username.errors }"
												placeholder="Email id *" class="form-control form-control-lg" />
											<div *ngIf="submitted && f.username.errors" class="invalid-feedback">
												<div *ngIf="f.username.errors.required">Email id is
													required</div>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col">
											<button [disabled]="loading" class="btn btn-primary btn-modern float-right"
												type="submit"><span *ngIf="loading"
													class="spinner-border spinner-border-sm mr-1"></span>
												Submit</button>
										</div>
									</div>
								</form>
								<form *ngIf="status === 1" [formGroup]="Form2" (ngSubmit)="submitForm2()"
									class="needs-validation">
									<p class="text-2 opacity-7">{{ status_msg }}</p>
									<div class="form-row">
										<div class="form-group col">
											<label class="font-weight-bold text-dark text-2">Please enter otp</label>
											<input type="text" formControlName="otp" autocomplete="off"
												[ngClass]="{ 'is-invalid': submitted && f2.otp.errors }"
												placeholder="OTP *" class="form-control form-control-lg" />
											<div *ngIf="submitted && f2.otp.errors" class="invalid-feedback">
												<div *ngIf="f2.otp.errors.required">Otp is
													required</div>
											</div>
										</div>
									</div>
									<p class="text-2 opacity-7" *ngIf="usernames != ''">
										your other account ( {{usernames}} ) will be disabled
									</p>
									<div class="form-row">
										<div class="form-group col">
											<button [disabled]="loading" class="btn btn-primary btn-modern float-right"
												type="submit"><span *ngIf="loading"
													class="spinner-border spinner-border-sm mr-1"></span>
												Verify</button>
										</div>
									</div>
								</form>
								<form *ngIf="status === 3" [formGroup]="Form1" (ngSubmit)="submitForm1()"
									class="needs-validation">
									<p class="text-2 opacity-7">{{ status_msg }}</p>
									<p class="text-2 opacity-7">There are not your name. So please contact to the
										temple.</p>
									<div class="form-row">
										<div class="form-group col">
											<table class="table">
												<tr>
													<th>&nbsp;</th>
													<th>First Name</th>
													<th>Last Name</th>
												</tr>
												<tr *ngFor="let user of users">
													<td>
														<input type="radio" formControlName="user"
															value="{{user._id}}" />
													</td>
													<td>{{user.first_name}}</td>
													<td>{{user.last_name}}</td>
												</tr>
											</table>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col">
											<button [disabled]="loading" class="btn btn-primary btn-modern float-right"
												type="submit"><span *ngIf="loading"
													class="spinner-border spinner-border-sm mr-1"></span>
												Submit</button>
										</div>
									</div>
								</form>
								 <div *ngIf="status === 4" class="panel panel-default credit-card-box">
									<div class="panel-heading display-table">
										<div class="row display-tr">
											<i class="far fa-check-circle chk"></i>
											<h3 class="panel-title display-td suc_tt">E-mail Verified</h3>
											<p style="text-align:center;">{{ status_msg }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>