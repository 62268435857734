<div role="main" class="main">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Check Availability</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Check Availability</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-12">

				<div class="row justify-content-md-center">
					<div class=" col-md-6">
						<div class="featured-box featured-box-primary text-left mt-0">
							<div class="box-content">
								<h4 class="color-primary font-weight-semibold text-4 text-uppercase mb-0">Check
									Availability</h4>
								<div *ngIf="sentMail" class="panel panel-default credit-card-box">
									<div class="panel-heading display-table">
										<div class="row display-tr">
											<i class="far fa-check-circle chk"></i>
											<h3 class="panel-title display-td suc_tt">E-mail Sent</h3>
											<p style="text-align:center;">Your request is submitted to the temple,
												please wait for response from temple</p>
											<!-- <a (click)="back()" class="btn btn-primary btn-modern">Back</a> -->
										</div>
									</div>
								</div>
								<div *ngIf="!sentMail">
									<p>Dear {{user_data.first_name}} {{user_data.last_name}},<br />
										You are trying to enquire about</p>

									<div class="item" *ngFor="let item of cartItems;">
										<strong>{{ item.name }}</strong> ({{ item.type }})<br />
										<div *ngIf="item.type_id === 3">
											{{ item.date }}, from time {{ item.start_time }} to time {{ item.end_time }} ({{item.duration}} Hour's)
										</div>
										<div *ngIf="item.type_id==2">
											{{ item.date }}
										</div>
										<!-- <br /> -->
										Amount : $ {{ item.totalCost }}
									</div>

									<p>Temple Operations manager will check availibility and get back to you by email or
										phone.</p>
									<br />
									<div class="">
										<button type="button" class="btn btn-secondary"
											(click)="redirectStore()">Modify</button>
										<button [disabled]="loading" class="btn btn-primary ml-2"
											type="button" (click)="checkAvailability()"><span *ngIf="loading"
												class="spinner-border spinner-border-sm mr-1"></span>
												Check Availability</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>