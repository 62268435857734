export class User {
    public user_id: string = '';
    public first_name: string = '';
    public middle_name: string = '';
    public last_name: string = '';
    public email: string = '';
    public gotram: string = '';
    public nakshatram: string = '';
    public address: string = '';
    public country: string = '';
    public city: string = '';
    public spouse: any = [];
    public connection_token: string = '';
    public childrens: any = [];
}