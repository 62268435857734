import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/request.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  year: number = 0;
  // form_url: string = '';
  // paypal_url: string = '';
  // pdf: string = '';
  title: string = '';
  description: string = '';

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public requestService: RequestService,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.requestService.getYearEndReport().subscribe((res) => {
      if (res.status === 1) {
        this.year = res.data.year;
        // this.form_url = res.data.form_url;
        // this.paypal_url = res.data.paypal_url;
        // this.pdf = res.data.pdf;
        this.title = res.data.title;
        this.description = res.data.description;
      } else {
        this.year = 0;
        // this.form_url = '';
        // this.paypal_url = '';
        // this.pdf = '';
        this.title = '';
        this.description = '';
      }
    });
  }

  transformHtml(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}