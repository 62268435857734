<div role="main" class="main">
    <section class="page-header page-header-classic page-header-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
                    <span class="page-header-title-border visible" style="width: 85.3906px;"></span>
                    <h1 data-title-border="">SERVICE AT TEMPLE</h1>
                </div>
                <div class="col-md-4 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb d-block text-md-right">
                        <li><a [routerLink]="'/'">Home</a></li>
                        <li class="active">Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <div role="main" class="main">
        <div class="container py-4">
            <div class="row">
                <div class="col-lg-3 order-lg-2">
                    <app-cart-sidebar></app-cart-sidebar>
                </div>
                <div class="col-lg-8 order-lg-1 recurring_mrgn">
                    <div class="blog-posts">
                        <div class="row justify-content-md-center">
                            <form [formGroup]="myForm" (ngSubmit)="submitForm()">
                                <div class="row">
                                    <div class='sankashti_chaturthi lis_wk_bg'>
                                        <div class="col-sm-12 col-md-12">
                                            <h2>Recurring Puja Booking</h2>
                                            <div class="form-row">
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">First Name 1</label>
                                                    <input type="text" formControlName="first_name_1" autocomplete="off"
                                                        [ngClass]="{ 'is-invalid': submitted && f.first_name_1.errors }"
                                                        placeholder="First Name *"
                                                        class="form-control form-control-lg" />
                                                    <div *ngIf="submitted && f.first_name_1.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.first_name_1.errors.required">First name is
                                                            required</div>
                                                        <div *ngIf="f.first_name_1.errors.requiredValidator">First name is
                                                            required</div>
                                                        <div *ngIf="f.first_name_1.errors.maxlength">First name may not be greater than 35 characters</div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">Last Name</label>
                                                    <input type="text" formControlName="last_name_1" autocomplete="off"
                                                        [ngClass]="{ 'is-invalid': submitted && f.last_name_1.errors }"
                                                        placeholder="Last Name *"
                                                        class="form-control form-control-lg" />
                                                    <div *ngIf="submitted && f.last_name_1.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.last_name_1.errors.required">Last name is required
                                                        </div>
                                                        <div *ngIf="f.last_name_1.errors.requiredValidator">Last name is
                                                            required</div>
                                                        <div *ngIf="f.last_name_1.errors.maxlength">Last name may not be greater than 35 characters</div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">Gothram</label>
                                                    <input type="text" formControlName="gotram_1" autocomplete="off"
                                                        placeholder="Gotram" class="form-control form-control-lg" />
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">Nakshatram</label>
                                                    <select formControlName="nakshatram_1"
                                                        class="form-control is-valid valid" size="0"
                                                        aria-invalid="false">
                                                        <option value="">Select Nakshatram</option>
                                                        <option *ngFor="let nakshatram of nakshatrams"
                                                            value="{{ nakshatram._id }}">{{
                                                            nakshatram.name }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">First Name 2</label>
                                                    <input type="text" formControlName="first_name_2" autocomplete="off"
                                                        placeholder="First Name" class="form-control form-control-lg" />
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">Last Name</label>
                                                    <input type="text" formControlName="last_name_2" autocomplete="off"
                                                        placeholder="Last Name" class="form-control form-control-lg" />
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">Gothram</label>
                                                    <input type="text" formControlName="gotram_2" autocomplete="off"
                                                        placeholder="Gotram" class="form-control form-control-lg" />
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">Nakshatram</label>
                                                    <select formControlName="nakshatram_2"
                                                        class="form-control is-valid valid" size="0"
                                                        aria-invalid="false">
                                                        <option value="">Select Nakshatram</option>
                                                        <option *ngFor="let nakshatram of nakshatrams"
                                                            value="{{ nakshatram._id }}">{{
                                                            nakshatram.name }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">First Name 3</label>
                                                    <input type="text" formControlName="first_name_3" autocomplete="off"
                                                        placeholder="First Name" class="form-control form-control-lg" />
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">Last Name</label>
                                                    <input type="text" formControlName="last_name_3" autocomplete="off"
                                                        placeholder="Last Name" class="form-control form-control-lg" />
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">Gothram</label>
                                                    <input type="text" formControlName="gotram_3" autocomplete="off"
                                                        placeholder="Gotram" class="form-control form-control-lg" />
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <label class="label_text">Nakshatram</label>
                                                    <select formControlName="nakshatram_3"
                                                        class="form-control is-valid valid" size="0"
                                                        aria-invalid="false">
                                                        <option value="">Select Nakshatram</option>
                                                        <option *ngFor="let nakshatram of nakshatrams"
                                                            value="{{ nakshatram._id }}">{{
                                                            nakshatram.name }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div formArrayName="persons">
                                                <div *ngFor="let item of persons().controls; let Index = index"
                                                    [formGroupName]="Index" class="form-row">
                                                    <div [hidden]="Index===0" class="form-group col-lg-12">
                                                        <div class="r_btn">
                                                            <i (click)="removePersons(Index)"
                                                                class="btn-minus fas fa-minus-square"></i>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-lg-3">
                                                        <label class="label_text">First Name {{Index+4}}</label>
                                                        <input type="text" formControlName="first_name"
                                                            autocomplete="off" placeholder="First Name"
                                                            class="form-control form-control-lg" />
                                                    </div>
                                                    <div class="form-group col-lg-3">
                                                        <label class="label_text">Last Name</label>
                                                        <input type="text" formControlName="last_name"
                                                            autocomplete="off" placeholder="Last Name"
                                                            class="form-control form-control-lg" />
                                                    </div>
                                                    <div class="form-group col-lg-3">
                                                        <label class="label_text">Gotram</label>
                                                        <input type="text" formControlName="gotram" autocomplete="off"
                                                            placeholder="Gotram" class="form-control form-control-lg" />
                                                    </div>
                                                    <div class="form-group col-lg-3">
                                                        <label class="label_text">Nakshatram</label>
                                                        <select formControlName="nakshatram"
                                                            class="form-control is-valid valid" size="0"
                                                            aria-invalid="false">
                                                            <option value="">Select Nakshatram</option>
                                                            <option *ngFor="let nakshatram of nakshatrams"
                                                                value="{{ nakshatram._id }}">{{
                                                                nakshatram.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="r_btn">
                                                    <i (click)="addPersons()" class="btn-plus fas fa-plus-square"></i>
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label>Name of the Puja :</label>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <select id="puja" class="form-control is-valid valid" size="0"
                                                        [ngClass]="{ 'is-invalid': submitted && f.service.errors }"
                                                        aria-invalid="false" formControlName="service"
                                                        (change)="selectService($event)">
                                                        <option value="">Select Service</option>
                                                        <optgroup datatype="daily" label="Select Daily Puja">
                                                            <option
                                                                *ngFor="let service of daily_services; let Index = index"
                                                                value="{{ Index }}" >{{
                                                                service.title }}</option>
                                                        </optgroup>
                                                        <optgroup datatype="weekly" label="Select Weekly Puja">
                                                            <option
                                                                *ngFor="let service of weekly_services; let Index = index"
                                                                value="{{ Index }}" >{{
                                                                service.title }}</option>
                                                        </optgroup>
                                                        <optgroup datatype="monthly" label="Select Monthly Puja">
                                                            <option
                                                                *ngFor="let service of monthly_services; let Index = index"
                                                                value="{{ Index }}">{{
                                                                service.title }}</option>
                                                        </optgroup>
                                                    </select>
                                                    <div *ngIf="submitted && f.service.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.service.errors.required">Service is
                                                            required</div>
                                                            </div>
                                                </div>
                                            </div>



                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label>Quantity :</label>
                                                </div>
                                                <div class="form-group col-md-9">
                                                    <div class="quantity_id">
                                                        <i (click)="removeQty(quantity)" style="cursor: pointer;"
                                                            class="fas fa-minus-square text-danger"></i>
                                                        {{ quantity }}
                                                        <i (click)="addQty(quantity)" style="cursor: pointer;"
                                                            class="fas fa-plus-square text-success"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label>Starting Date :</label>
                                                </div>
                                                

                                                <div class="input-group col-lg-3" style="padding:0px;">
                                                    <input type="text" class="form-control" [owlDateTime]="dt1" style="cursor:pointer !important;"
                                                    [disabled]="true" [owlDateTimeTrigger]="dt1" [min]="min" readonly
                                                    (dateTimeInput)="selectDate($event)" autocomplete="off"
                                                    formControlName="date" [owlDateTimeFilter]="myFilter" 
                                                    [ngClass]="{ 'is-invalid': submitted && f.date.errors }"
                                                    data-select="datepicker" placeholder="Pick a date" />
                                                <div class="input-group-append" style="margin-bottom: 8px;">
                                                    <button class="btn btn-secondary" [owlDateTimeTrigger]="dt1"
                                                        type="button">
                                                        <i class="fa fa-calendar"></i>
                                                    </button>
                                                </div>
                                                <div *ngIf="submitted && f.date.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.date.errors.required">Date is
                                                    required</div>
                                                    </div>
                                                <owl-date-time [pickerMode]="'dialog'" [pickerType]="'calendar'" #dt1>
                                                </owl-date-time>
                                                    <!-- <input type="text" class="form-control" name="date" id="date"
                                                        data-select="datepicker" placeholder="07-27-2021"
                                                        autocomplete="off">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-secondary cldr_btn" type="button">
                                                            <i class="fa fa-calendar"></i>
                                                        </button>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label>Schedule :</label>
                                                </div>
                                                <div class="input-group col-lg-3" (click)="openScheduler()" style="padding:0px;"  data-toggle="tooltip" data-placement="top" title="View Schedule">
                                                    <input type="text" class="form-control" style="cursor:pointer;"
                                                        value="View Schedule" autocomplete="off" readonly >
                                                    <div class="input-group-append" style="margin-bottom: 8px;">
                                                        <button class="btn btn-secondary" type="button">
                                                            <i class="fa fa-eye"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label>Occasion :</label>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <select id="occasion" class="form-control is-valid valid" size="0"
                                                    formControlName="occasion"
                                                        aria-invalid="false">
                                                        <option value="" class="txt_b">--- Select Occasion ---</option>
                                                        <option value="birthday">Birthday</option>
                                                        <option value="marriage">Marriage</option>
                                                        <option value="anniversary_marriage">Anniversary Marriage</option>
                                                    </select>
                                                    <input type="text" placeholder="Other" formControlName="occasion_other"
                                                        class="form-control form-control-lg">
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label>Price :</label>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label>$ {{final_price}}</label>
                                                </div>
                                            </div>
                                            <div class="form-group col">
							<button type="submit" class="btn btn-primary btn-modern">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <ng-template #scheduleMdl let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"><strong>Number of Items in Cart:</strong> {{this.quantity}}  <strong>Total Amount:</strong>  ${{final_price}}</h5>
            <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <full-calendar [options]="calendarOptions" ></full-calendar>
            </div>
        </ng-template>
</div>