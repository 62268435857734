<div role="main" class="main form_bg">
  <section class="page-header page-header-classic page-header-sm">
    <div class="container">
      <div class="row">
        <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
          <span
            class="page-header-title-border visible"
            style="width: 85.3906px"
          ></span>
          <h1 data-title-border="">Proceed to Pay</h1>
        </div>
        <div class="col-md-4 order-1 order-md-2 align-self-center">
          <ul class="breadcrumb d-block text-md-right">
            <li><a [routerLink]="'/'">Home</a></li>
            <li><a [routerLink]="'/checkout'">Payment</a></li>
            <li class="active">Detail</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <div role="main" class="main shop">
    <div class="row justify-content-md-center">
      <div class="form_bg_bdr" [ngClass]="(!isLoggedIn && is_any_services_in_temple_and_donation) ? 'col-lg-8' : 'col-lg-6'">
        <div class="panel panel-default credit-card-box">
          <div class="panel-heading display-table">
            <div class="row display-tr">
              <h3 class="panel-title display-td"><span *ngIf="!isLoggedIn && is_any_services_in_temple_and_donation">Sankalpam and </span>Mailing Address</h3>
            </div>
          </div>
          <div class="panel-body" style="margin-top: 20px">
            <p>
              Please enter your <span *ngIf="!isLoggedIn && is_any_services_in_temple_and_donation">Sankalpam Details (Name, Gotram, Nakshatram) and </span>Mailing
              address, contact email, phone number for communication purpose as you are using
              Guest Checkout feature.
            </p>
            <form
              [formGroup]="myForm"
              (ngSubmit)="submitForm()"
              id="frmBillingAddress"
              method="post"
            >
              <div class="row" *ngIf="!isLoggedIn && is_any_services_in_temple_and_donation">
                <div class="col-md-12">
                  <h6><b><u>Sankalpam Detail</u> :-</b></h6>
                </div>
              </div>
              <div class="row" *ngIf="!isLoggedIn && is_any_services_in_temple_and_donation">
                <div class="col-md-12">
                  <div class="form-row">
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">First Name 1</label> -->
                        <input type="text" formControlName="first_name_1" autocomplete="off"
                            [ngClass]="{ 'is-invalid': submitted && f.first_name_1.errors }"
                            placeholder="First Name 1*" class="form-control form-control-lg" />
                        <div *ngIf="submitted && f.first_name_1.errors" class="invalid-feedback">
                            <div *ngIf="f.first_name_1.errors.required">First name is required</div>
                            <div *ngIf="f.first_name_1.errors.requiredValidator">First name is required</div>
                            <div *ngIf="f.first_name_1.errors.maxlength">First name may not be greater than 35 characters</div>
                        </div>
                    </div>
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">Last Name</label> -->
                        <input type="text" formControlName="last_name_1" autocomplete="off"
                            [ngClass]="{ 'is-invalid': submitted && f.last_name_1.errors }"
                            placeholder="Last Name *" class="form-control form-control-lg" />
                        <div *ngIf="submitted && f.last_name_1.errors" class="invalid-feedback">
                            <div *ngIf="f.last_name_1.errors.required">Last name is required</div>
                            <div *ngIf="f.last_name_1.errors.requiredValidator">Last name is required</div>
                            <div *ngIf="f.last_name_1.errors.maxlength">Last name may not be greater than 35 characters</div>
                        </div>
                    </div>
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">Gotram</label> -->
                        <input type="text" formControlName="gotram_1" autocomplete="off" placeholder="Gotram"
                            class="form-control form-control-lg" />
                    </div>
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">Nakshatram</label> -->
                        <select formControlName="nakshatram_1" class="form-control is-valid valid" size="0"
                            aria-invalid="false">
                            <option value="">Select Nakshatram</option>
                            <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                nakshatram.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">First Name 2</label> -->
                        <input type="text" formControlName="first_name_2" autocomplete="off"
                            placeholder="First Name 2" class="form-control form-control-lg" />
                    </div>
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">Last Name</label> -->
                        <input type="text" formControlName="last_name_2" autocomplete="off" placeholder="Last Name"
                            class="form-control form-control-lg" />
                    </div>
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">Gotram</label> -->
                        <input type="text" formControlName="gotram_2" autocomplete="off" placeholder="Gotram"
                            class="form-control form-control-lg" />
                    </div>
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">Nakshatram</label> -->
                        <select formControlName="nakshatram_2" class="form-control is-valid valid" size="0"
                            aria-invalid="false">
                            <option value="">Select Nakshatram</option>
                            <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                nakshatram.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">First Name 3</label> -->
                        <input type="text" formControlName="first_name_3" autocomplete="off"
                            placeholder="First Name 3" class="form-control form-control-lg" />
                    </div>
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">Last Name</label> -->
                        <input type="text" formControlName="last_name_3" autocomplete="off" placeholder="Last Name"
                            class="form-control form-control-lg" />
                    </div>
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">Gotram</label> -->
                        <input type="text" formControlName="gotram_3" autocomplete="off" placeholder="Gotram"
                            class="form-control form-control-lg" />
                    </div>
                    <div class="form-group col-lg-3">
                        <!-- <label class="label_text">Nakshatram</label> -->
                        <select formControlName="nakshatram_3" class="form-control is-valid valid" size="0"
                            aria-invalid="false">
                            <option value="">Select Nakshatram</option>
                            <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                nakshatram.name }}</option>
                        </select>
                    </div>
                </div>

                <div formArrayName="persons">
                    <div *ngFor="let item of persons().controls; let Index = index" [formGroupName]="Index"
                        class="form-row">
                        <div [hidden]="Index===0" class="form-group col-lg-12">
                            <div class="pull-right">
                                <i (click)="removePersons(Index)" class="btn-minus fas fa-minus-square"></i>
                                <!-- <img (click)="removePersons(Index)" src="/assets/img/minus.png" /> -->
                            </div>
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">First Name {{Index+4}}</label> -->
                            <input type="text" formControlName="first_name" autocomplete="off"
                                placeholder="First Name {{Index+4}}" class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Last Name</label> -->
                            <input type="text" formControlName="last_name" autocomplete="off"
                                placeholder="Last Name" class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Gotram</label> -->
                            <input type="text" formControlName="gotram" autocomplete="off" placeholder="Gotram"
                                class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Nakshatram</label> -->
                            <select formControlName="nakshatram" class="form-control is-valid valid" size="0"
                                aria-invalid="false">
                                <option value="">Select Nakshatram</option>
                                <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                    nakshatram.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="r_btn">
          <i (click)="addPersons()" class="btn-plus fas fa-plus-square"></i>
                        <!-- <img (click)="addPersons()" src="/assets/img/add.png"> -->
                    </div>
                </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h6><b><u>Mailing Address</u> :-</b></h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      formControlName="address"
                      placeholder="Address *"
                      class="form-control form-control-lg"
                      [ngClass]="{
                        'is-invalid': submitted && f.address.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.address.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.address.errors.required">
                        Address is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <select
                      formControlName="country"
                      class="form-control is-valid valid"
                      size="0"
					  (change)="onSelectCountry($event)"
                      [ngClass]="{
                        'is-invalid': submitted && f.country.errors
                      }"
                      aria-invalid="false"
                    >
                      <option value="">Select Country *</option>
                      <option
                        *ngFor="let country of countries"
                        value="{{ country._id }}"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && f.country.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.country.errors.required">
                        Country is required
                      </div>
                    </div>
                  </div>
                </div>
				
                <div class="col-md-6">
                  <div class="form-group">
                    <select
                      formControlName="state"
                      class="form-control is-valid valid"
                      size="0"
                      [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
                      aria-invalid="false"
                    >
                      <option value="">Select State *</option>
                      <option
                        *ngFor="let state of states"
                        value="{{ state._id }}"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && f.state.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.state.errors.required">
                        State is required
                      </div>
                    </div>
                  </div>
                </div>
				
				<div class="col-md-6">
					<div class="form-group">
						<input type="text" formControlName="city" placeholder="City *"
								[ngClass]="{ 'is-invalid': submitted && f.city.errors }"
								class="form-control form-control-lg">
							<div *ngIf="submitted && f.city.errors" class="invalid-feedback">
								<div *ngIf="f.city.errors.required">City is required</div>
							</div>
				</div>
			  </div>

				<div class="col-md-6">
					<div class="form-group">
						<input type="text" formControlName="zip_code" placeholder="Zip Code *"
								[ngClass]="{ 'is-invalid': submitted && f.zip_code.errors }"
								class="form-control form-control-lg">
							<div *ngIf="submitted && f.zip_code.errors" class="invalid-feedback">
								<div *ngIf="f.zip_code.errors.required">Zipcode is required</div>
							</div>
					</div>
				  </div>

			  <div class="col-md-6">
				<div class="form-group">
					<input type="email" formControlName="email" placeholder="Email *"
							[ngClass]="{ 'is-invalid': submitted && f.email.errors }"
							class="form-control form-control-lg">
						<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
							<div *ngIf="f.email.errors.required">Email is required</div>
						</div>
				</div>
			  </div>
			  
			  <div class="col-md-6">
				  <div class="form-group">
					<input type="text" formControlName="mobile" placeholder="Contact Phone *"
					[ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
					class="form-control form-control-lg">
				<div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
					<div *ngIf="f.mobile.errors.required">Contact number is required</div>
				</div>
				</div>
			  </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <div class="sv_btn" style="margin-top: 22px">
                      <button type="submit" class="btn btn-primary btn-modern">
                        Pay Now
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="col-md-6">
                  <div class="form-group">
					
				 
				</div>
			</div>

			  <div class="form-group">
				<input
				  type="text"
				  value="Zip Code *"
				  class="form-control form-control-lg"
				  required=""
				/>
			  </div>
			  <div class="form-group">
				<input
				  type="text"
				  value="Contact Phone *"
				  class="form-control form-control-lg"
				  required=""
				/>
			  </div>
			<div class="col-md-6">
			  <div class="form-group">
				<select
				  id="user_time_zone"
				  class="form-control is-valid valid"
				  size="0"
				  aria-invalid="false"
				>
				  <option value="-1">Select Country *</option>
				  <option value="Canada">CANADA</option>
				  <option value="Mexico">MEXICO</option>
				  <option value="United States">UNITED STATES</option>
				  <option value="India">INDIA</option>
				  <option value="Other">Other</option>
				</select>
			  </div>
			  <div class="form-group">
				<input
				  type="text"
				  value="City *"
				  class="form-control form-control-lg"
				  required=""
				/>
			  </div>
			  <div class="form-group">
				<input
				  type="text"
				  value="Email *"
				  class="form-control form-control-lg"
				  required=""
				/>
			  </div> -->
