<div role="main" class="main">
    <section class="page-header page-header-classic page-header-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-4 order-2 order-md-1 align-self-center p-static">
                    <span class="page-header-title-border visible" style="width: 85.3906px;"></span>
                    <h1 data-title-border="">SERVICES</h1>
                </div>
                <div class="col-md-8 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb d-block text-md-right">
                        <li><a [routerLink]="'/'">Home</a></li>
                        <li><a [routerLink]="'/services/'+type">{{page_title}}</a></li>
                        <li class="active">{{service.title}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <div role="main" class="main">
        <div class="container py-4">
            <div class="row">
                <div class="col-lg-3 order-lg-2">
                    <app-cart-sidebar></app-cart-sidebar>
                </div>
                <div class="col-lg-8 order-lg-1" style="border-right:1px solid #e4e4e4; margin-right:25px;">
                    <h2 class="word-rotator-title mb-0">
                        <strong>{{service.title}}</strong>
                    </h2>
                    <div class="row">
                        <div class="col-md-12">
                            <hr class="tall" />
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="service.image != ''" class="col-md-6 col-lg-6">
                            <img src="{{service.image}}" />
                        </div>
                        <div *ngIf="service.image != ''" class="col-md-6 col-lg-6">
                            <div class="input-group" *ngIf="type == 'daily' || type == 'personal'">
                                <input type="text" class="form-control" [owlDateTime]="dt1" [disabled]="true"
                                    [owlDateTimeTrigger]="dt1" [min]="min" readonly (dateTimeInput)="selectDate($event)"
                                    autocomplete="off" [value]="service.date" data-select="datepicker"
                                    placeholder="Pick a date">
                                <div class="input-group-append">
                                    <button class="btn btn-secondary" [owlDateTimeTrigger]="dt1" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                                <select id="qty" class="" (change)="selectQty($event)" size="0" aria-invalid="false">
                                    <option value="1" selected>1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                                <div class="clr1 ">
                                    <span class="qty">Qty</span>
                                </div>
                                <owl-date-time [pickerMode]="'popup'" [pickerType]="'calendar'" #dt1>
                                </owl-date-time>
                            </div>
                            <weekly-date-picker *ngIf="type == 'weekly'" [service]="service" [index]="0"
                                (selectDate)="selectDateHandler($event)"
                                (selectQty)="selectQtyHandler($event)"></weekly-date-picker>
                            <monthly-date-picker *ngIf="type == 'monthly'" [service]="service" [index]="0"
                                (selectDate)="selectDateHandler($event)"
                                (selectQty)="selectQtyHandler($event)"></monthly-date-picker>
                            <annual-date-picker *ngIf="type == 'annual'" [service]="service" [index]="0"
                                (selectDate)="selectDateHandler($event)"
                                (selectQty)="selectQtyHandler($event)"></annual-date-picker>
                            <vahan-date-picker *ngIf="type == 'vahana_sevas'" [service]="service" [index]="0"
                                (selectDate)="selectDateHandler($event)"
                                (selectQty)="selectQtyHandler($event)"></vahan-date-picker>
                            <special-date-picker *ngIf="type == 'special'" [service]="service" [index]="0"
                                (selectDate)="selectDateHandler($event)"
                                (selectQty)="selectQtyHandler($event)"></special-date-picker>
                            <div>
                                <a (click)="addProductToCart(service)" class="order_rbtn">Order Puja</a>
                                <a class="order_lbtn">$ {{ service.amount }}</a>
                            </div>

                            <p [innerHTML]="transformHtml(service.description)"></p>
                        </div>
                        <div *ngIf="service.image === ''" class="col-md-12 col-lg-12">
                            <p [innerHTML]="transformHtml(service.description)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
