import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestService } from '../../services/request.service';
import { Event, Router,ActivatedRoute , NavigationStart } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  keyword:string='';
  searchForm:any;
  results: any;
  // per_page_items: number = 10;
  // total_items: number = 0;
  // total_pages: number = 1;
  current_page: number = 1;
  // loadPagination: boolean = false;
  // loading:boolean = true;

  constructor(
    public router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    ) { 
      this.searchForm=FormGroup; 
      this.activeRoute.queryParams.subscribe(params => {
        this.keyword = (params['keyword'] === undefined) ? '' : params['keyword'];
      });
      this.searchForm = this.formBuilder.group({
        keyword: [this.keyword],
        page: [this.current_page],
      });
    }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.getResults();
  }

  search(){
    this.current_page = 1;
    // this.loading = true;
    this.searchForm.controls['page'].setValue(1);
    const form_data = this.searchForm.value;
    const urlTree = this.router.createUrlTree([], {
      queryParams: form_data,
      queryParamsHandling: "merge",
      preserveFragment: true 
    });
    this.router.navigateByUrl(urlTree);
    // this.getResults();
    window.scrollTo(0, 0);
  }

  // changePage(page:number){
  //   this.current_page = page;
  //   this.getResults();
  //   window.scrollTo(0, 0);
  // }

  getResults() {
    this.requestService.getSearchResult({keyword:this.keyword,page:this.current_page}).subscribe((res) => {
      if(res.status === 1){
        this.results = res.data;
        // this.total_pages = res.total_pages;
        // this.total_items = res.total_items;
        // if(res.total_pages > 1){
        //   this.loadPagination = true;
        // }
      } else {
        this.results = [];
        // this.total_pages = 1;
        // this.total_items = 0;
        // this.loadPagination = false;
      }
      // this.loading = false;
    });
  }

}
