import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Event, Router,ActivatedRoute, NavigationStart } from '@angular/router';
import { RequestService } from '../../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { AvailabilityCartService } from "../../../services/availability-cart.service";

@Component({
  selector: 'app-confirm-available',
  templateUrl: './confirm-available.component.html',
  styleUrls: ['./confirm-available.component.css']
})
export class ConfirmAvailableComponent implements OnInit {

  // Form: any = FormArray;
  loading = false;
  // submitted = false;
  // reset_by:string = 'Username';
  sentMail = false;
  user_data: any;
  cart: any;
  cartItems: any;
  grossTotal = 0;

  constructor(
    private authenticationService: AuthenticationService,
    public requestService: RequestService,
    private toastr: ToastrService,
    public router: Router,
    private activeRoute: ActivatedRoute,
    private availabilityCartService: AvailabilityCartService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    // this.Form = this.formBuilder.group({
    //   reset_by: ['username', Validators.required],
    //   username: ['', Validators.required],
    // });
    let isNotEmpty = this.availabilityCartService.isEmpty();
    if (!this.authenticationService.isLoggedIn() || !isNotEmpty) {
      this.router.navigate(['/']); return;
    }
    this.user_data = this.authenticationService.currentUserValue;
    this.getCartData();
  }

  getCartData() {
    this.cart = this.availabilityCartService.get();
    this.cart.subscribe((cart: any) => {
      this.grossTotal = cart.grossTotal;
      this.cartItems = cart.items.map((item: any) => { return { ...item, expand: false, totalCost: item.quantity * (item.price + item.add_amount + item.cleaning) }; });
    });
  }

  redirectStore(){
    this.activeRoute.queryParams.subscribe(params => {
      let redirect = (params['redirect'] === undefined) ? '/' : params['redirect'];
      this.router.navigate([redirect]);
    });
  }

  checkAvailability() {
    this.loading = true;
    this.requestService.checkAvailability(this.user_data.user_id,this.cartItems,this.grossTotal).subscribe((res) => {
      if (res.status === 1) {
        this.loading = false;
        this.sentMail = true;
        this.availabilityCartService.empty();
        window.scrollTo(0, 0);
      } else {
        this.toastr.error(res.message);
        this.loading = false;
      }
    });
  }

}
