<div role="main" class="main form_bg">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px"></span>
					<h1 data-title-border="">Profile</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Profile</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div *ngIf="isDueCorporateBody" class="container form_bg_bdr">
		<div class="row">
			<div class="col-sm-12 col-md-12">
				<h4>Corporate Body Membership Dues</h4>
				<table class="table table-bordered table-hover">
					<thead>
						<tr>
							<th>Term</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let cbdue of cbdues">
							<td>
								<h4 style="font-size: 20px;">
									<strong>
										{{cbdue.form}} to {{cbdue.to}}
									</strong>
								</h4>
							</td>
							<td>
								<h4 style="font-size: 20px;">
									<strong>${{cbdue.amount }}</strong>
									<strong *ngIf="cbdue.is_paid === 1"> ( Paid - {{cbdue.paid_by}} -
										{{cbdue.payment_date}})</strong>
								</h4>
							</td>
						</tr>
						<tr *ngIf="cbdues && cbdues[0] && cbdues[0].is_paid === 0">
							<td colspan="4">
								<div class="row">
									<div class="col-md-6 col-md-offset-3">
										<ngx-paypal [config]="payPalConfig"></ngx-paypal>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<ng-template #confMdl let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel"><strong>Payment Status</strong></h5>
		</div>
		<div class="modal-body">
			<p style="padding: 0px 10px; text-align: center;">
				{{payment_status_1}}<br />
				{{payment_status_2}}<br /><br />
				{{payment_status_3}}
			</p>
		</div>
		<div class="modal-footer">
			<a (click)="reloadPage()" type="button" class="btn btn-primary">Ok</a>
		</div>
	</ng-template>

	<br *ngIf="isDueCorporateBody" />

	<div class="container form_bg_bdr">
		<div class="row">
			<div class="col-sm-12 col-md-12">
				<form [formGroup]="Form" (ngSubmit)="submitForm()">
					<h4>Personal Information</h4>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">First Name *</label>
							<input type="text" formControlName="first_name" autocomplete="off"
								[ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
								placeholder="First Name *" class="form-control form-control-lg" />
							<div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
								<div *ngIf="f.first_name.errors.required">
									First name is required
								</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Middle Name</label>
							<input type="text" formControlName="middle_name" autocomplete="off"
								placeholder="Middle Name" class="form-control form-control-lg" />
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Last Name *</label>
							<input type="text" formControlName="last_name" autocomplete="off"
								[ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" placeholder="Last Name *"
								class="form-control form-control-lg" />
							<div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
								<div *ngIf="f.last_name.errors.required">
									Last name is required
								</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Date of Birth</label>
							<div class="input-group">
								<input type="text" class="form-control form-control-lg" [owlDateTime]="dt" style="
                    height: calc(1.5em + 1rem + 2px);
                    background-color: #ffffff;
                    cursor: pointer;
                  " [owlDateTimeTrigger]="dt" formControlName="dob" autocomplete="off" readonly
									data-select="datepicker" placeholder="Date of Birth" />

								<div class="input-group-append">
									<button class="btn btn-secondary" [owlDateTimeTrigger]="dt" type="button">
										<i class="fa fa-calendar"></i>
									</button>
								</div>
								<owl-date-time [pickerMode]="'popup'" [pickerType]="'calendar'" #dt>
								</owl-date-time>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Nakshatram</label>
							<select formControlName="nakshatram" class="form-control is-valid valid" size="0"
								aria-invalid="false">
								<option value="">Select Nakshatram</option>
								<option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">
									{{ nakshatram.name }}
								</option>
							</select>
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Gotram</label>
							<input type="text" formControlName="gotram" placeholder="Gotram"
								class="form-control form-control-lg" autocomplete="off" />
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Devotee Id</label>
							<input type="text" class="form-control form-control-lg" readonly
								formControlName="devotee_id" />
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Email</label>
							<input type="text" formControlName="email" placeholder="Email *"
								[ngClass]="{ 'is-invalid': submitted && f.email.errors }"
								class="form-control form-control-lg" readonly autocomplete="off" />
							<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
								<div *ngIf="f.email.errors.required">Email is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Language</label>
							<select formControlName="language" class="form-control is-valid valid" size="0"
								aria-invalid="false">
								<option value="">Preferred Language</option>
								<option *ngFor="let language of languages" value="{{ language._id }}">
									{{ language.name }}
								</option>
							</select>
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-12">
							<div id="content">
								<input type="checkbox" (change)="changeIsMarried()" formControlName="is_married"
									value="1" />
								Married
								<input type="checkbox" (change)="changeIsChildren()" formControlName="is_children"
									value="1" />
								Children
							</div>
						</div>
					</div>

					<h4 *ngIf="married_box">Spouse Information</h4>

					<div [hidden]="!married_box" formArrayName="spouse">
						<div *ngFor="let item of spouse().controls; let Index = index" [formGroupName]="Index"
							class="form-row">
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">First Name *</label>
								<input type="text" formControlName="first_name" autocomplete="off" [ngClass]="{
                    'is-invalid':
                      submitted &&
                      f.spouse.controls[Index].controls.first_name.errors
                  }" placeholder="First Name *" class="form-control form-control-lg" />
								<div *ngIf="
                    submitted &&
                    f.spouse.controls[Index].controls.first_name.errors
                  " class="invalid-feedback">
									<div *ngIf="
                      f.spouse.controls[Index].controls.first_name.errors
                        .required
                    ">
										First name is required
									</div>
								</div>
							</div>
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">Middle Name</label>
								<input type="text" formControlName="middle_name" placeholder="Middle Name"
									class="form-control form-control-lg" />
							</div>
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">Last Name *</label>
								<input type="text" formControlName="last_name" autocomplete="off" [ngClass]="{
                    'is-invalid':
                      submitted &&
                      f.spouse.controls[Index].controls.last_name.errors
                  }" placeholder="Last Name *" class="form-control form-control-lg" />
								<div *ngIf="
                    submitted &&
                    f.spouse.controls[Index].controls.last_name.errors
                  " class="invalid-feedback">
									<div *ngIf="
                      f.spouse.controls[Index].controls.last_name.errors
                        .required
                    ">
										Last name is required
									</div>
								</div>
							</div>
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">Date of Birth</label>
								<div class="input-group">
									<input type="text" class="form-control form-control-lg" [owlDateTime]="sdt" style="
                      height: calc(1.5em + 1rem + 2px);
                      background-color: #ffffff;
                      cursor: pointer;
                    " [owlDateTimeTrigger]="sdt" readonly formControlName="dob" autocomplete="off"
										data-select="datepicker" placeholder="Date of Birth" />

									<div class="input-group-append">
										<button class="btn btn-secondary" [owlDateTimeTrigger]="sdt" type="button">
											<i class="fa fa-calendar"></i>
										</button>
									</div>
									<owl-date-time [pickerMode]="'popup'" [pickerType]="'calendar'" #sdt>
									</owl-date-time>
								</div>
							</div>
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">Nakshatram</label>
								<select formControlName="nakshatram" class="form-control is-valid valid" size="0"
									aria-invalid="false">
									<option value="">Select Nakshatram</option>
									<option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">
										{{ nakshatram.name }}
									</option>
								</select>
							</div>
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">Email</label>
								<input type="text" formControlName="email" placeholder="Email"
									class="form-control form-control-lg" />
							</div>
						</div>
					</div>

					<h4 *ngIf="children_box">Children Information</h4>

					<div [hidden]="!children_box" formArrayName="childrens">
						<div *ngFor="let item of childrens().controls; let Index = index" [formGroupName]="Index"
							class="form-row">
							<div class="form-group col-lg-12">
								<div class="pull-right">
									<!-- <img (click)="removeChildrens(Index)" src="/assets/img/minus.png" /> -->
									<i (click)="removeChildrens(Index)" class="btn-minus fas fa-minus-square"></i>
								</div>
							</div>
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">First Name *</label>
								<input type="text" formControlName="first_name" autocomplete="off" [ngClass]="{
                    'is-invalid':
                      submitted &&
                      f.childrens.controls[Index].controls.first_name.errors
                  }" placeholder="First Name *" class="form-control form-control-lg" />
								<div *ngIf="
                    submitted &&
                    f.childrens.controls[Index].controls.first_name.errors
                  " class="invalid-feedback">
									<div *ngIf="
                      f.childrens.controls[Index].controls.first_name.errors
                        .required
                    ">
										First name is required
									</div>
								</div>
							</div>
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">Middle Name</label>
								<input type="text" formControlName="middle_name" placeholder="Middle Name"
									class="form-control form-control-lg" />
							</div>
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">Last Name *</label>
								<input type="text" formControlName="last_name" autocomplete="off" [ngClass]="{
                    'is-invalid':
                      submitted &&
                      f.childrens.controls[Index].controls.last_name.errors
                  }" placeholder="Last Name *" class="form-control form-control-lg" />
								<div *ngIf="
                    submitted &&
                    f.childrens.controls[Index].controls.last_name.errors
                  " class="invalid-feedback">
									<div *ngIf="
                      f.childrens.controls[Index].controls.last_name.errors
                        .required
                    ">
										Last name is required
									</div>
								</div>
							</div>
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">Date of Birth</label>
								<div class="input-group">
									<input type="text" class="form-control form-control-lg" [owlDateTime]="cdt" style="
                      height: calc(1.5em + 1rem + 2px);
                      background-color: #ffffff;
                      cursor: pointer;
                    " [owlDateTimeTrigger]="cdt" readonly formControlName="dob" autocomplete="off"
										data-select="datepicker" placeholder="Date of Birth" />
									<div class="input-group-append">
										<button class="btn btn-secondary" [owlDateTimeTrigger]="cdt" type="button">
											<i class="fa fa-calendar"></i>
										</button>
									</div>
									<owl-date-time [pickerMode]="'popup'" [pickerType]="'calendar'" #cdt>
									</owl-date-time>
								</div>
							</div>
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">Nakshatram</label>
								<select formControlName="nakshatram" class="form-control is-valid valid" size="0"
									aria-invalid="false">
									<option value="">Select Nakshatram</option>
									<option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">
										{{ nakshatram.name }}
									</option>
								</select>
							</div>
							<div class="form-group col-lg-4">
								<label class="font-weight-bold text-dark text-2">Email</label>
								<input type="text" formControlName="email" placeholder="Email"
									class="form-control form-control-lg" />
							</div>
						</div>
						<div *ngIf="children_box" class="r_btn">
							<i (click)="addChildrens()" class="btn-plus fas fa-plus-square"></i>
							<!-- <img  src="/assets/img/add.png"> -->
						</div>
					</div>

					<hr />
					<div class="clear"></div>
					<h4>Contact Information</h4>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Address *</label>
							<input type="text" formControlName="address" placeholder="Address *"
								[ngClass]="{ 'is-invalid': submitted && f.address.errors }"
								class="form-control form-control-lg" />
							<div *ngIf="submitted && f.address.errors" class="invalid-feedback">
								<div *ngIf="f.address.errors.required">Address is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Country *</label>
							<select formControlName="country" class="form-control is-valid valid" size="0"
								[ngClass]="{ 'is-invalid': submitted && f.country.errors }"
								(change)="onSelectCountry($event)" aria-invalid="false">
								<option value="">Select Country *</option>
								<option *ngFor="let country of countries" value="{{ country._id }}">
									{{ country.name }}
								</option>
							</select>
							<div *ngIf="submitted && f.country.errors" class="invalid-feedback">
								<div *ngIf="f.country.errors.required">Country is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">State *</label>
							<select formControlName="state" class="form-control is-valid valid" size="0"
								[ngClass]="{ 'is-invalid': submitted && f.state.errors }" aria-invalid="false">
								<option value="">Select State *</option>
								<option *ngFor="let state of states" value="{{ state._id }}">
									{{ state.name }}
								</option>
							</select>
							<div *ngIf="submitted && f.state.errors" class="invalid-feedback">
								<div *ngIf="f.state.errors.required">State is required</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">City *</label>
							<input type="text" formControlName="city" placeholder="City *"
								[ngClass]="{ 'is-invalid': submitted && f.city.errors }"
								class="form-control form-control-lg" />
							<div *ngIf="submitted && f.city.errors" class="invalid-feedback">
								<div *ngIf="f.city.errors.required">City is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Zip Code *</label>
							<input type="text" formControlName="zip_code" placeholder="Zip Code *"
								[ngClass]="{ 'is-invalid': submitted && f.zip_code.errors }"
								class="form-control form-control-lg" />
							<div *ngIf="submitted && f.zip_code.errors" class="invalid-feedback">
								<div *ngIf="f.zip_code.errors.required">
									Zipcode is required
								</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Secondary Email</label>
							<input type="text" formControlName="secondary_email" placeholder="Email"
								class="form-control form-control-lg" />
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Home Phone</label>
							<input type="text" formControlName="home_phone" placeholder="Home Phone"
								class="form-control form-control-lg" />
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Office Phone</label>
							<input type="text" formControlName="office_phone" placeholder="Office Phone"
								class="form-control form-control-lg" />
						</div>
						<div class="form-group col-lg-4">
							<label class="font-weight-bold text-dark text-2">Mobile *</label>
							<input type="text" formControlName="contact_number" placeholder="Mobile *" [ngClass]="{
                  'is-invalid': submitted && f.contact_number.errors
                }" class="form-control form-control-lg" />
							<div *ngIf="submitted && f.contact_number.errors" class="invalid-feedback">
								<div *ngIf="f.contact_number.errors.required">
									Mobile number is required
								</div>
								<div *ngIf="f.contact_number.errors.pattern">
									Mobile number must be a numeric
								</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<div class="custom-control custom-checkbox pb-3">
								<input type="checkbox" formControlName="is_subscribed" value="1"
									class="custom-control-input" id="shipbillingaddress" />
								<label class="custom-control-label" for="shipbillingaddress">Subscribe to
									Newsletter?</label>
							</div>
						</div>
					</div>

					<div class="form-row">
						<div class="form-group col">
							<button type="submit" class="btn btn-primary btn-modern">
								Submit
							</button>
							<!-- <input type="submit" value="Cancel" class="btn btn-primary btn-modern"> -->
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>