import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Inject,
  Output,
  TemplateRef,
  EventEmitter
} from '@angular/core';
import { Event, Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { Observable } from "rxjs/Observable";
import { ShoppingCart } from "../../../models/shopping-cart.model";
import { CartItem } from "../../../models/cart-item.model";
import { ShoppingCartService } from "../../../services/shopping-cart.service";
import { Subscription } from "rxjs/Subscription";
import { ToastrService } from 'ngx-toastr';
import { RequestService } from '../../../services/request.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

interface ICartItemWithProduct extends CartItem {
  product: any;
  totalCost: number;
}

@Component({
  selector: 'app-cart-sidebar',
  templateUrl: './cart-sidebar.component.html',
  styleUrls: ['./cart-sidebar.component.css'],
})
export class CartSidebarComponent implements OnInit {
  base = '';
  page = '';
  splitVal: any;
  cart: any;
  cartItems: any;
  grossTotal = 0;
  isProceed = false;
  showDonation = false;
  donations:any;
  default_donation='';
  Form: any = FormArray;
  loading = false;
  submitted = false;
  // itemCount: number;
  // public cart: Observable<ShoppingCart>;
  // public cartItems: ICartItemWithProduct[];
  // private cartSubscription: Subscription;
  // @Output() toggleDonation = new EventEmitter<string>();

  constructor(
    public requestService: RequestService,
    public router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private shoppingCartService: ShoppingCartService
  ) {
    this.page = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      donation_id: [''],
      price: [0, Validators.required],
    });
    this.requestService.getDonation().subscribe((res) => {
      if(res.status === 1){
        this.donations = res.data;
        this.default_donation = res.default_donation;
        this.Form.controls['donation_id'].setValue(res.default_donation);
      } else {
        this.donations = [];
      }
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.splitVal = event.url.split('/');
        this.base = this.splitVal[1];
        this.page = this.splitVal[2];
      }
    });
    this.getCartData();
    this.isProceed = this.shoppingCartService.isEmpty();
  }

  getCartData() {
    this.cart = this.shoppingCartService.get();
    this.cart.subscribe((cart: any) => {
      this.grossTotal = cart.grossTotal;
      this.cartItems = cart.items.map((item: any) => { return { ...item, expand: true, totalCost: item.price * item.quantity }; });
    });
  }

  checkedDonation(event: any): void {
    this.showDonation = event.target.checked;
  }

  hideShow(i: number, bl: boolean): void {
    this.cartItems[i].expand = (bl) ? false : true;
  }

  public emptyCart(): void {
    this.shoppingCartService.empty();
    this.getCartData();
  }

  public removeItem(product: any): void {
    this.shoppingCartService.addItem(product, 0);
    this.getCartData();
  }

  proceedToPay() {
    this.isProceed = this.shoppingCartService.isEmpty();
    if (this.isProceed) {
      this.router.navigate(['/checkout']);
    } else {
      this.toastr.error("Your cart is empty!"); return;
    }
  }

  get f() { return this.Form.controls; }

  submitForm() {
    this.submitted = true;
    if (this.Form.value.price < 1 || this.Form.value.price == 0 || this.Form.value.price == '' || this.Form.value.price == null) {
      this.toastr.error('Please add donation amount');
      return;
    }
    let donation = this.donations.find((p:any) => p._id === this.Form.value.donation_id);
    let isAlreadyExists = this.shoppingCartService.isAlreadyExists(this.Form.value.donation_id);
    if(isAlreadyExists){
      this.shoppingCartService.removeQty(donation._id);
      // this.shoppingCartService.addItem(donation, 0);
    }
    let date : any = new Date();
    date = date.toLocaleString('en-US', { timeZone: 'America/New_York' })
    date = new Date(date);
    let datep = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
    this.shoppingCartService.addItem({
      _id: donation._id,
      type: 'Donation',
      type_id: 5,
      name: donation.title,
      quantity: 1,
      price: this.Form.value.price,
      page:'services/donation',
      date: datep,
      location: '',
      booking_config: '',
    }, 1);
    this.toastr.error('Donation added successfully on puja basket');
    this.Form.controls['donation_id'].setValue(this.default_donation);
    this.Form.controls['price'].setValue(0);
    this.loading = true;
  }

}

