<div role="main" class="main form_bg">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">SPECIAL STANDING COMMITTEE</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">SPECIAL STANDING COMMITTEE</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
	<div class="container form_bg_bdr">
		<div *ngIf="display_form" class="col-md-12">
			<div class="row">
				<h2 class="word-rotator-title">
					Special Standing Committee <small>({{start_date +' to '+ end_date}})</small>
				</h2>
			</div>
		</div>
		<div *ngIf="display_form" class="col-md-12">
			<form id="contactForm" class="contact-form" [formGroup]="Form" (ngSubmit)="submitForm()">
				<div class="form-row">
					<div class="form-group col-lg-4">
						<input type="text" readonly value="{{ user_data.first_name }}"
							class="form-control form-control-lg">
					</div>
					<div class="form-group col-lg-4">
						<input type="text" readonly value="{{ user_data.last_name }}"
							class="form-control form-control-lg">
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-lg-4">
						<input type="text" readonly value="{{ user_data.contact_number }}"
							class="form-control form-control-lg">
					</div>
					<div class="form-group col-lg-4">
						<input type="email" readonly value="{{ user_data.email }}" class="form-control form-control-lg">
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-lg-4">
						<input type="text" readonly value="{{ user_data.address }}"
							class="form-control form-control-lg">
					</div>
					<div class="form-group col-lg-4">
						<input type="text" readonly value="{{ user_data.state }}" class="form-control form-control-lg">
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-lg-4">
						<input type="text" readonly value="{{ user_data.city }}" class="form-control form-control-lg">
					</div>
					<div class="form-group col-lg-4">
						<input type="text" readonly value="{{ user_data.zipcode }}"
							class="form-control form-control-lg">
					</div>
				</div>
				<p class="p5"><strong>*2. Please select one or more committees that you feel committed to attend at
						least of 25% meetings for each committee.</strong></p>
				<div class="form-row">
					<div class="form-group col-md-12">

						<div *ngFor="let term of terms"><label for="section_{{ term.term_id }}">
								<input type="checkbox"
									id="section_{{ term.term_id }}"
									 (change)="onSectionChange($event)"
									[value]="term.term_id"> {{ term.name }}</label>
						</div>

						<div class="form-row">
							<div class="form-group col">
								<button [disabled]="loading" class="btn btn-primary btn-modern" type="submit"><span *ngIf="loading"
									class="spinner-border spinner-border-sm mr-1"></span> Submit</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div *ngIf="!display_form" class="col-md-12 text-center">
			{{ message }}
		</div>
	</div>

	<ng-template #confirmMdl let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel"><strong>Registration Confirmation</strong></h5>
			<button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<div style="padding: 0px 10px;">
				<table class="table table-bordered table-hover">
                    <tbody>
						<tr>
							<th>Name</th>
							<td>{{ user_data.first_name }} {{ user_data.last_name }}</td>
						</tr>
						<tr>
							<th>Term</th>
							<td>{{start_date +' to '+ end_date}}</td>
						</tr>
						<tr>
							<th>Committee</th>
							<td>
								<div *ngFor="let selected_term of selected_terms">
									- {{ selected_term.name }}
							</div>
							</td>
						</tr>
                    </tbody>
                </table>
			</div>
		</div>
		<div class="modal-footer">
			<a (click)="confirmSubmit()" type="button" class="btn btn-primary">Yes</a>
			<button type="button" class="btn btn-secondary" (click)="modal.dismiss()">No</button>
		</div>
	</ng-template>


	<ng-template #successMdl let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel"><strong>Registration Success</strong></h5>
			<button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
						<p style="padding: 0px 10px;"><strong>
							You are successfully registered for the <span *ngFor="let selected_term of selected_terms; let i=index"> {{ (selected_terms.length === i+1) ? selected_term.name : selected_term.name+',' }} </span> Enrolment.</strong></p>
		</div>
		<div class="modal-footer">
			<a (click)="reloadPage()" type="button" class="btn btn-primary">Ok</a>
		</div>
	</ng-template>
	
</div>