import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/request.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {

  payout:any;
  items:any;
  order:any;
  isLoggedIn: boolean = false;
  user_data: any;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    public requestService: RequestService,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    const id = this.route.snapshot.params['id'];
    this.getOrderDetail(id);
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.user_data = this.authenticationService.currentUserValue;
  }

  getOrderDetail(id:string) {
    this.requestService.getOrderDetail(id).subscribe((res) => {
      if(res.status === 1){
        this.payout = res.data.payout;
        this.items = res.data.items;
        this.order = res.data;
      }
    });
  }

}