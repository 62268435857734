import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Event, Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { RequestService } from '../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator } from '../../services/validator-service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  Form: any = FormArray;
  loading = false;
  submitted = false;
  reset_by:string = 'Username';
  status=0;
  status_msg='';

  constructor(
    private authenticationService: AuthenticationService,
    public requestService: RequestService,
    private toastr: ToastrService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    const id = this.activatedRoute.snapshot.params['id'];
    this.isValidResetToken(id);

    this.Form = this.formBuilder.group({
      token: [id],
      user: [''],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    },{
      validator: [
        ConfirmedValidator('password', 'confirm_password')
      ]
    });
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

  get f() { return this.Form.controls; }

  changeResetBy() {
    this.reset_by = this.Form.value.reset_by;
    this.reset_by = this.reset_by.replace(/_/gi, " ");
  }

  back(){
    // this.success = '';
  }

  isValidResetToken(id:string){
    this.requestService.isValidResetToken(id).subscribe((res) => {
      if (res.status === 1) {
        this.status = 0;
        this.status_msg = '';
        this.Form.controls['user'].setValue(res.user);
      } else {
        this.status = 2;
        this.status_msg = res.message;
      }
    });
  }

  submitForm() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;
    this.requestService.resetPassword(this.Form.value).subscribe((res) => {
      if (res.status === 1) {
        this.status = 1;
        this.status_msg = res.message;
        this.loading = false;
      } else {
        this.toastr.error(res.message);
        this.loading = false;
      }
    });
  }

}
