import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { RequestService } from '../../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { ShoppingCartService } from "../../../services/shopping-cart.service";
import { RequiredValidator } from '../../../services/validator-service';
import * as moment from 'moment';

@Component({
  selector: 'app-express',
  templateUrl: './express.component.html',
  styleUrls: ['./express.component.css'],
})
export class ExpressComponent implements OnInit {  
  nakshatrams:any;
  services:any;
  min:any;
  user_data:any;

  myForm:any; 
  loading = false;
  submitted = false;
  error = '';

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    public router: Router,
    private shoppingCartService: ShoppingCartService
  ) {
    this.myForm= FormGroup;
  }
  
  ngOnInit(): void {
    let today = new Date();
    today = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    this.min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    this.getData();
    window.scrollTo(0, 0);
    this.myForm = this.formBuilder.group({
      first_name_1: ['',[Validators.required,Validators.maxLength(35)]],
      last_name_1: ['',[Validators.required,Validators.maxLength(35)]],
      gotram_1: [''],
      nakshatram_1: [''],
      first_name_2: [''],
      last_name_2: [''],
      gotram_2: [''],
      nakshatram_2: [''],
      first_name_3: [''],
      last_name_3: [''],
      gotram_3: [''],
      nakshatram_3: [''],
      persons: this.formBuilder.array([]),
      service: ['',Validators.required],
      price: [0,Validators.required],
      date: ['',Validators.required],
      quantity: ['1',Validators.required],
    }, { 
      validator: [
        RequiredValidator('first_name_1'),
        RequiredValidator('last_name_1')
      ]
    });
    // this.persons().push(
    //   this.formBuilder.group({
    //     first_name: [''],
    //     last_name: [''],
    //     gotram: [''],
    //     nakshatram: ['']
    //   })
    // );
    if(this.authenticationService.isLoggedIn()){
      this.syncUserDetail();
    }
  }

  persons() : FormArray {
    return this.myForm.get("persons") as FormArray
  }

  newpersons(): FormGroup {
    return this.formBuilder.group({
      first_name: [''],
      last_name: [''],
      gotram: [''],
      nakshatram: ['']
    });
  }
   
  addPersons() {
    this.persons().push(this.newpersons());
  }
   
  removePersons(i:number) {
    this.persons().removeAt(i);
  }

  selectService(event:any){
    let amount = (event.target.value !== '') ? this.services[event.target.value].amount : 0;
    this.myForm.controls['price'].setValue(amount);
  }

  getData() {
    this.requestService.getNakshatrams().subscribe((res) => {
      if(res.status === 1){
        this.nakshatrams = res.data;
      } else {
        this.nakshatrams = [];
      }
    });
    this.requestService.getServiceAtTemple('daily').subscribe((res) => {
      if(res.status === 1){
        this.services = res.data;
      } else {
        this.services = [];
      }
    });
  }

  syncUserDetail() {
    this.user_data = this.authenticationService.currentUserValue;
    this.myForm.controls['first_name_1'].setValue(this.user_data.first_name);
    this.myForm.controls['last_name_1'].setValue(this.user_data.last_name);
    this.myForm.controls['gotram_1'].setValue(this.user_data.gotram);
    this.myForm.controls['nakshatram_1'].setValue(this.user_data.nakshatram);
    if(this.user_data.spouse.length > 0){
      var spouse = this.user_data.spouse[0];
      this.myForm.controls['first_name_2'].setValue(spouse.first_name);
      this.myForm.controls['last_name_2'].setValue(spouse.last_name);
      this.myForm.controls['gotram_2'].setValue(spouse.gotram);
      this.myForm.controls['nakshatram_2'].setValue(spouse.nakshatram);
    }
    if(this.user_data.childrens.length > 0){
      var childrens = this.user_data.childrens;
      var self = this;
      childrens.forEach(function(value:any,i:number){
        if(i===0){
          self.myForm.controls['first_name_3'].setValue(childrens[i].first_name);
          self.myForm.controls['last_name_3'].setValue(childrens[i].last_name);
          self.myForm.controls['gotram_3'].setValue(childrens[i].gotram);
          self.myForm.controls['nakshatram_3'].setValue(childrens[i].nakshatram);
        } else {
          self.persons().push(
            self.formBuilder.group({
              first_name: [childrens[i].first_name],
              last_name: [childrens[i].last_name],
              gotram: [childrens[i].gotram],
              nakshatram: [childrens[i].nakshatram]
            })
          );
        }
      });
      
    }
  }

  get f() { return this.myForm.controls; }

  submitForm() {
    this.submitted = true;
   
    if (this.myForm.invalid) {
      return;
    }

    const product = this.services[this.myForm.value.service];
    
    if(product.is_online === 0){
      this.toastr.error(product.booking_message);
      return;
    }

    let selected = new Date(this.myForm.value.date);
    var cc = product.cut_off_service_time.split(':');

    /* Selected Date with Last service time */
    let estimate_booking_date = new Date(selected.getFullYear(), selected.getMonth(), selected.getDate(), cc[0], cc[1], cc[2]);

    /* Selected DateTime substract cut off hours */
    estimate_booking_date.setHours(estimate_booking_date.getHours() - product.cut_off_hours);

    /* Booking DateTime */
    let today : any = new Date();
    today = today.toLocaleString('en-US', { timeZone: 'America/New_York' })
    today = new Date(today);

    if(today >= estimate_booking_date){
      // this.toastr.error('Cut-off time has passed so the Online booking is not available for the '+moment(estimate_booking_date).format('MMMM Do YYYY, hh:mm a')+' Eastern Time');
      this.toastr.error('Cut-off time has passed so the Online booking is not available for the date and service selected');
      return;
    }

    // let today : any = new Date();
    // today = today.toLocaleString('en-US', { timeZone: 'America/New_York' })
    // today = new Date(today);
    // let selected = new Date(this.myForm.value.date);
    // if(today.getFullYear() === selected.getFullYear() && 
    // today.getMonth() === selected.getMonth() && 
    // today.getDate()  === selected.getDate()){
    //   var morning = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9, 0, 0);
    //   if(morning.getTime() < today.getTime()){
    //     this.toastr.error('Same day service booking allowed before 9 AM Eastern Time');
    //     return;
    //   }
    // }
    
    let qty = parseInt(this.myForm.value.quantity);
    let month = selected.getMonth()+1;
    let date = month+'/'+selected.getDate()+'/'+selected.getFullYear();
    // let date = this.datepipe.transform(this.myForm.value.date, 'MM/dd/yyyy');
    this.myForm.controls['date'].setValue(date);

    this.shoppingCartService.addItem({
      _id : product._id,
      type : 'Express Booking',
      type_id : 4,
      name : product.title,
      quantity : 1,
      price : product.amount,
      date : date,
      cut_off_hours : product.cut_off_hours,
      cut_off_service_time : product.cut_off_service_time,
      cut_off_type : product.cut_off_type,
      page:'services/express',
      location : '',
      booking_config : product.booking_config,
      raw_data : this.myForm.value
    }, qty);

    this.error = ''; // res.message;
    this.loading = false;

    this.router.navigate(['/checkout']);

  }

}