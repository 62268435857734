<div role="main" class="main">
    <section class="page-header page-header-classic page-header-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
                    <span class="page-header-title-border visible" style="width: 85.3906px;"></span>
                    <h1 data-title-border="">SERVICE AT TEMPLE</h1>
                </div>
                <div class="col-md-4 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb d-block text-md-right">
                        <li><a [routerLink]="'/'">Home</a></li>
                        <li class="active">Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <div role="main" class="main">
        <div class="container py-4">
            <div class="row">
                <!-- <div class="col-lg-12">
                    <p style="text-align: center;"><strong>Attention Devotees : All service bookings require website registration and login, if you do not want to register, please use express booking feature.</strong></p>
                </div> -->
                <div class="col-lg-12 order-lg-1">
                    <div class="blog-posts">
                        <div class="row justify-content-md-center">
                            <div class="flex-row row">
                                <div *ngFor="let service of services; let i=index" class="col-xs-6 col-sm-3 col-lg-3">
                                    <div class="thumbnail">
                                        <a [routerLink]="'/services/'+service.slug"><img src="{{ service.image }}"
                                                class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0"
                                                alt="" /></a>
                                        <div class="caption">
                                            <h2 class="clr1 font-weight-semibold mb-2"><a
                                                    [routerLink]="'/services/'+service.slug">{{ service.title }}</a></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>