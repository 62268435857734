import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { RequestService } from '../../services/request.service';
import { LocalStorageService } from '../../services/local-storage-service';
import { RequiredValidator } from '../../services/validator-service';
import { AuthenticationService } from '../../services/authentication.service';
import { ShoppingCartService } from "../../services/shopping-cart.service";
@Component({
  selector: 'app-checkout-detail',
  templateUrl: './checkout-detail.component.html',
  styleUrls: ['./checkout-detail.component.css']
})
export class CheckoutDetailComponent implements OnInit {
  countries: any;
  states: any;
  nakshatrams:any;
  isLoggedIn: boolean = false;
  is_any_services_in_temple_and_donation: boolean = false;
  cart: any;
  // cartItems: any;

  myForm: any;
  loading = false;
  submitted = false;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    private toastr: ToastrService,
    public router: Router,
    public localStorageService: LocalStorageService,
    private shoppingCartService: ShoppingCartService
    ) { 
      this.getCartData();
    }

  ngOnInit(): void {
    this.getData();
    window.scrollTo(0, 0);
    this.getCartData();
    this.localStorageService.removeItem('mailing');
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    const payoutData = this.localStorageService.getItem('payout');
    const payoutArr = (payoutData) ? JSON.parse(payoutData) : {};
    let fields = {
      address: ['',Validators.required],
      country: [payoutArr.country || '', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zip_code: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required]
    };
    let valid = {};
    if (!this.isLoggedIn && this.is_any_services_in_temple_and_donation) {
      let fields1 = {
        first_name_1: ['',[Validators.required,Validators.maxLength(35)]],
        last_name_1: ['',[Validators.required,Validators.maxLength(35)]],
        gotram_1: [''],
        nakshatram_1: [''],
        first_name_2: [''],
        last_name_2: [''],
        gotram_2: [''],
        nakshatram_2: [''],
        first_name_3: [''],
        last_name_3: [''],
        gotram_3: [''],
        nakshatram_3: [''],
        persons: this.formBuilder.array([]),
      }
      fields = Object.assign(fields,fields1);
      valid = { 
        validator: [
          RequiredValidator('first_name_1'),
          RequiredValidator('last_name_1')
        ]
      };
    }
    this.myForm = this.formBuilder.group(fields,valid);
    if(payoutData){
      this.getStates(payoutArr.country || '');
    }
  }

  persons() : FormArray {
    return this.myForm.get("persons") as FormArray
  }

  newpersons(): FormGroup {
    return this.formBuilder.group({
      first_name: [''],
      last_name: [''],
      gotram: [''],
      nakshatram: ['']
    });
  }

  addPersons() {
    this.persons().push(this.newpersons());
  }
   
  removePersons(i:number) {
    this.persons().removeAt(i);
  }

  getData() {
    this.requestService.getCountries().subscribe((res) => {
      if (res.status === 1) {
        this.countries = res.data;
      } else {
        this.countries = [];
      }
    });
    this.requestService.getNakshatrams().subscribe((res) => {
      if(res.status === 1){
        this.nakshatrams = res.data;
      } else {
        this.nakshatrams = [];
      }
    });
  }

  getCartData() {
    this.is_any_services_in_temple_and_donation = false;
    this.cart = this.shoppingCartService.get();
    this.cart.subscribe((cart: any) => {
      cart.items.map((item: any) => {
        if(item.type_id == 1 || item.type_id == '1' || item.type_id == 5 || item.type_id == '5'){
          this.is_any_services_in_temple_and_donation = true;
        }
      });
      // this.cartItems = cart.items.map((item: any) => { return { ...item, expand: false, totalCost: item.price * item.quantity }; });
    });
  }

  onSelectCountry(event: any) {
    this.getStates(event.target.value);
  }

  getStates(country_id: string) {
    this.myForm.controls['state'].setValue('');
    if (country_id === '') {
      this.states = [];
    } else {
      this.requestService.getStates(country_id).subscribe((res) => {
        if (res.status === 1) {
          this.states = res.data;
        }
      });
    }
  }

  get f() { return this.myForm.controls; }

  submitForm() {
    this.submitted = true;
    if (this.myForm.invalid) {
      this.toastr.error('Please fill the all required fields');
      return;
    }
    if (!this.isLoggedIn && this.is_any_services_in_temple_and_donation) {
      const row_data = {
        first_name_1: this.myForm.value.first_name_1,
        first_name_2: this.myForm.value.first_name_2,
        first_name_3: this.myForm.value.first_name_3,
        gotram_1: this.myForm.value.gotram_1,
        gotram_2: this.myForm.value.gotram_2,
        gotram_3: this.myForm.value.gotram_3,
        last_name_1: this.myForm.value.last_name_1,
        last_name_2: this.myForm.value.last_name_2,
        last_name_3: this.myForm.value.last_name_3,
        nakshatram_1: this.myForm.value.nakshatram_1,
        nakshatram_2: this.myForm.value.nakshatram_2,
        nakshatram_3: this.myForm.value.nakshatram_3,
        persons: this.myForm.value.persons
      };
      this.cart.subscribe((cart: any) => {
        cart.items.map((item: any) => {
          if(item.type_id == 1 || item.type_id == '1' || item.type_id == 5 || item.type_id == '5'){
            this.shoppingCartService.addItemRowData(item._id,row_data);
          }
        });
      });
    }
    this.localStorageService.setItem('mailing', JSON.stringify(this.myForm.value));
    this.router.navigate(['/checkout/payment']);
  }

}