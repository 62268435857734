<aside class="sidebar">
    <p>
        <strong>Availability:</strong>
    </p>
    <div class="toggle toggle-primary toggle-simple" data-plugin-toggle="">
        <section *ngFor="let item of cartItems; let i=index" (click)="hideShow(i,item.expand)" class="toggle"
            [ngClass]="(item.expand) ? 'active' : ''">
            <label>{{ item.type }}: <span class="p_txt">$ {{ item.totalCost }}</span>
                <br />{{ item.name }}<div class="cr_btn">
                    <i (click)="removeItem(item)" class="btn-minus fas fa-minus-square"></i>
                </div></label>
            <div *ngIf="item.expand">
                <p>Date: <span class="p_txt">{{ item.date }}</span></p>
                <p *ngIf="item.type_id == 3">Duration: <span class="p_txt">{{ item.duration }} Hour's</span></p>
                <p *ngIf="item.type_id == 3">Amount: <span class="p_txt">$ {{item.raw_data.amount}} / {{item.raw_data.amount_hour}} Hour's</span></p>
                <p *ngIf="item.type_id == 3">Additional Amount: <span class="p_txt">$ {{item.raw_data.additional_hour_amount}} / Hour</span></p>
                <p *ngIf="item.type_id == 3">Cleaning charge: <span class="p_txt">$ {{item.raw_data.cleaning_amount}}</span></p>
                <p *ngIf="item.type_id == 2">Quantity: <span class="p_txt">{{ item.quantity }}</span></p>
                <!-- <p *ngIf="item.type_id == 2">{{ item.booking_config }}</p> -->
            </div>
        </section>
        <hr>
        <p>Total Amount: <span class="p_txt">$ {{ grossTotal }}</span></p>
        <hr>
    </div>
    <div class="box-content">
        <div class="form-row">
            <div class="form-group">
                <a (click)="checkAvailability()" class="btn btn-primary btn-modern float-right">Check Availability</a>
            </div>
        </div>
    </div>
</aside>
