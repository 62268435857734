<div role="main" class="main">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">DAILY PUJA SCHEDULE</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">DAILY PUJA SCHEDULE</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-12">
				<table class="table table-bordered table-hover">
					<thead>
						<tr>
							<td colspan="3" class="blt_c">Services/Pujas at S. V. Temple overview</td>
						</tr>
						<tr>
							<th>SERVICE / EVENT</th>
							<th>TIME</th>
							<th>Details</th>
						</tr>
					</thead>
					<tbody *ngIf="monday_schedules.length > 0">
						<tr>
							<td colspan="3" class="blt_c text-left">Monday</td>
						</tr>
						<tr *ngFor="let puja of monday_schedules">
							<td>{{puja.title}}</td>
							<td>{{puja.time}}</td>
							<td>{{puja.description}}</td>
						</tr>
					</tbody>
					<tbody *ngIf="tuesday_schedules.length > 0">
						<tr>
							<td colspan="3" class="blt_c text-left">Tuesday</td>
						</tr>
						<tr *ngFor="let puja of tuesday_schedules">
							<td>{{puja.title}}</td>
							<td>{{puja.time}}</td>
							<td>{{puja.description}}</td>
						</tr>
					</tbody>
					<tbody *ngIf="wednesday_schedules.length > 0">
						<tr>
							<td colspan="3" class="blt_c text-left">Wednesday</td>
						</tr>
						<tr *ngFor="let puja of wednesday_schedules">
							<td>{{puja.title}}</td>
							<td>{{puja.time}}</td>
							<td>{{puja.description}}</td>
						</tr>
					</tbody>
					<tbody *ngIf="thursday_schedules.length > 0">
						<tr>
							<td colspan="3" class="blt_c text-left">Thursday</td>
						</tr>
						<tr *ngFor="let puja of thursday_schedules">
							<td>{{puja.title}}</td>
							<td>{{puja.time}}</td>
							<td>{{puja.description}}</td>
						</tr>
					</tbody>
					<tbody *ngIf="friday_schedules.length > 0">
						<tr>
							<td colspan="3" class="blt_c text-left">Friday</td>
						</tr>
						<tr *ngFor="let puja of friday_schedules">
							<td>{{puja.title}}</td>
							<td>{{puja.time}}</td>
							<td>{{puja.description}}</td>
						</tr>
					</tbody>
					<tbody *ngIf="saturday_schedules.length > 0">
						<tr>
							<td colspan="3" class="blt_c text-left">Saturday</td>
						</tr>
						<tr *ngFor="let puja of saturday_schedules">
							<td>{{puja.title}}</td>
							<td>{{puja.time}}</td>
							<td>{{puja.description}}</td>
						</tr>
					</tbody>
					<tbody *ngIf="sunday_schedules.length > 0">
						<tr>
							<td colspan="3" class="blt_c text-left">Sunday</td>
						</tr>
						<tr *ngFor="let puja of sunday_schedules">
							<td>{{puja.title}}</td>
							<td>{{puja.time}}</td>
							<td>{{puja.description}}</td>
						</tr>
					</tbody>
				</table>

			</div>
		</div>
	</div>
</div>