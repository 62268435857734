<div role="main" class="main">
    <section class="page-header page-header-classic page-header-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
                    <span class="page-header-title-border visible" style="width: 85.3906px;"></span>
                    <h1 data-title-border="">Express Booking</h1>
                </div>
                <div class="col-md-4 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb d-block text-md-right">
                        <li><a [routerLink]="'/'">Home</a></li>
                        <li class="active">Express Booking</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <div class="container">
        <p><strong>Attention Devotees : Please fill all your family details before selecting Archana and Date</strong></p>
    </div>
    <div class="container form_bg_bdr">
        <form [formGroup]="myForm" (ngSubmit)="submitForm()">
            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <h4>Express Booking</h4>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">First Name 1</label> -->
                            <input type="text" formControlName="first_name_1" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted && f.first_name_1.errors }"
                                placeholder="First Name 1*" class="form-control form-control-lg" />
                            <div *ngIf="submitted && f.first_name_1.errors" class="invalid-feedback">
                                <div *ngIf="f.first_name_1.errors.required">First name is required</div>
                                <div *ngIf="f.first_name_1.errors.requiredValidator">First name is required</div>
                                <div *ngIf="f.first_name_1.errors.maxlength">First name may not be greater than 35 characters</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Last Name</label> -->
                            <input type="text" formControlName="last_name_1" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted && f.last_name_1.errors }"
                                placeholder="Last Name *" class="form-control form-control-lg" />
                            <div *ngIf="submitted && f.last_name_1.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name_1.errors.required">Last name is required</div>
                                <div *ngIf="f.last_name_1.errors.requiredValidator">Last name is required</div>
                                <div *ngIf="f.last_name_1.errors.maxlength">Last name may not be greater than 35 characters</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Gotram</label> -->
                            <input type="text" formControlName="gotram_1" autocomplete="off" placeholder="Gotram"
                                class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Nakshatram</label> -->
                            <select formControlName="nakshatram_1" class="form-control is-valid valid" size="0"
                                aria-invalid="false">
                                <option value="">Select Nakshatram</option>
                                <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                    nakshatram.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">First Name 2</label> -->
                            <input type="text" formControlName="first_name_2" autocomplete="off"
                                placeholder="First Name 2" class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Last Name</label> -->
                            <input type="text" formControlName="last_name_2" autocomplete="off" placeholder="Last Name"
                                class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Gotram</label> -->
                            <input type="text" formControlName="gotram_2" autocomplete="off" placeholder="Gotram"
                                class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Nakshatram</label> -->
                            <select formControlName="nakshatram_2" class="form-control is-valid valid" size="0"
                                aria-invalid="false">
                                <option value="">Select Nakshatram</option>
                                <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                    nakshatram.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">First Name 3</label> -->
                            <input type="text" formControlName="first_name_3" autocomplete="off"
                                placeholder="First Name 3" class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Last Name</label> -->
                            <input type="text" formControlName="last_name_3" autocomplete="off" placeholder="Last Name"
                                class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Gotram</label> -->
                            <input type="text" formControlName="gotram_3" autocomplete="off" placeholder="Gotram"
                                class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Nakshatram</label> -->
                            <select formControlName="nakshatram_3" class="form-control is-valid valid" size="0"
                                aria-invalid="false">
                                <option value="">Select Nakshatram</option>
                                <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                    nakshatram.name }}</option>
                            </select>
                        </div>
                    </div>

                    <div formArrayName="persons">
                        <div *ngFor="let item of persons().controls; let Index = index" [formGroupName]="Index"
                            class="form-row">
                            <div [hidden]="Index===0" class="form-group col-lg-12">
                                <div class="pull-right">
                                    <i (click)="removePersons(Index)" class="btn-minus fas fa-minus-square"></i>
                                    <!-- <img (click)="removePersons(Index)" src="/assets/img/minus.png" /> -->
                                </div>
                            </div>
                            <div class="form-group col-lg-3">
                                <!-- <label class="label_text">First Name {{Index+4}}</label> -->
                                <input type="text" formControlName="first_name" autocomplete="off"
                                    placeholder="First Name {{Index+4}}" class="form-control form-control-lg" />
                            </div>
                            <div class="form-group col-lg-3">
                                <!-- <label class="label_text">Last Name</label> -->
                                <input type="text" formControlName="last_name" autocomplete="off"
                                    placeholder="Last Name" class="form-control form-control-lg" />
                            </div>
                            <div class="form-group col-lg-3">
                                <!-- <label class="label_text">Gotram</label> -->
                                <input type="text" formControlName="gotram" autocomplete="off" placeholder="Gotram"
                                    class="form-control form-control-lg" />
                            </div>
                            <div class="form-group col-lg-3">
                                <!-- <label class="label_text">Nakshatram</label> -->
                                <select formControlName="nakshatram" class="form-control is-valid valid" size="0"
                                    aria-invalid="false">
                                    <option value="">Select Nakshatram</option>
                                    <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                        nakshatram.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="r_btn">
							<i (click)="addPersons()" class="btn-plus fas fa-plus-square"></i>
                            <!-- <img (click)="addPersons()" src="/assets/img/add.png"> -->
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <!-- <label class="label_text">Puja Name</label> -->
                            <select class="form-control is-valid valid" size="0" (change)="selectService($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.service.errors }"
                                aria-invalid="false" formControlName="service">
                                <option value="">Please Select Puja Name</option>
                                <option *ngFor="let service of services; let Index = index" value="{{ Index }}">{{
                                    service.title }}</option>
                            </select>
                            <div *ngIf="submitted && f.service.errors" class="invalid-feedback">
                                <div *ngIf="f.service.errors.required">Service is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <label class="label_text">Price</label>
                            <input type="text" readonly formControlName="price" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted && f.price.errors }"
                                placeholder="Price" class="form-control form-control-lg" />
                            <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
                                <div *ngIf="f.price.errors.required">Price is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <label class="label_text">Puja Date</label>
                            <div class="input-group">
                                <input type="text"
                                [owlDateTime]="dt1"  [disabled]="true" [min]="min"
                                [owlDateTimeTrigger]="dt1" formControlName="date"
                                [ngClass]="{ 'is-invalid': submitted && f.date.errors }"
                                class="form-control" readonly />
                                <!-- (dateTimeInput)="selectDate(i,$event)" -->
                                <div class="input-group-append">
                                    <button class="btn btn-secondary" [owlDateTimeTrigger]="dt1" 
                                    type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                                <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                                    <div *ngIf="f.date.errors.required">Date is required</div>
                                </div>
                                <owl-date-time [pickerMode]="'popup'" [pickerType]="'calendar'" 
                                #dt1></owl-date-time>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <label class="label_text">Quantity</label>
                            <select formControlName="quantity" class="form-control is-valid valid" 
                            [ngClass]="{ 'is-invalid': submitted && f.quantity.errors }"
                            size="0" aria-invalid="false">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </select>
                            <div *ngIf="submitted && f.quantity.errors" class="invalid-feedback">
                                <div *ngIf="f.quantity.errors.required">Quantity is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row" style="margin-top:18px;">
                <div>
                    <button type="submit" class="btn btn-primary btn-modern">Proceed to Pay</button>
                </div>
            </div>
        </form>


    </div>
</div>