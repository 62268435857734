// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  paypalCurrency: 'USD',

  /* dev */
  // adminUrl: 'http://localhost:5000/',
  // apiUrl: 'http://localhost:5000/api/',
  // paypalClientId: 'AWEWP3dBgJDm0966NxRSEm9EbUGt7INH33sn7xI9o3jLyQwjhxHGcirQw4K5VbZb5H-D0T8mUWqVpBu9', // TEST
  // reCaptchaSiteKey: '6LcMiX8bAAAAAFR33Ca8X77ePkCMsJsNclFPm8sh' // TEST

  /* uat */
  adminUrl: 'https://uatadmin.svtemple.org/',
  apiUrl: 'https://uatadmin.svtemple.org/api/',
  paypalClientId: 'Ac9v2Mn3LK_-1VlhQi_47BmizN9ZBiqHCmw69bnPYz7XtwBzzo_kGj6zxNQERa1hrgrdLOZkkLRE2o8h', // LIVE
  reCaptchaSiteKey: '6LcKBIIbAAAAAGR7JCMIBcFwfde30_76RtdxnCH2' // LIVE

  /* production */
  // adminUrl: 'https://admin.svtemple.org/',
  // apiUrl:'https://admin.svtemple.org/api/',
  // paypalClientId: 'Ac9v2Mn3LK_-1VlhQi_47BmizN9ZBiqHCmw69bnPYz7XtwBzzo_kGj6zxNQERa1hrgrdLOZkkLRE2o8h', // LIVE
  // reCaptchaSiteKey: '6LcKBIIbAAAAAGR7JCMIBcFwfde30_76RtdxnCH2' // LIVE
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
