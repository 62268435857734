<div role="main" class="main form_bg">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Donation Report</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Donation Report</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div class="container form_bg_bdr">
		<div class="row">
			<div class="col-sm-12 col-md-12">
				<form [formGroup]="Form" (ngSubmit)="submitForm()">
					<div class="form-row">
						<div class="form-group col-lg-2">
							<h4>Select Year</h4>
						</div>
						<div class="form-group col-lg-4">
							<select formControlName="year" class="form-control is-valid valid" size="0"
								aria-invalid="false">
								<option value="">Select Year</option>
								<option *ngFor="let year of years" value="{{ year }}">{{
									year }}</option>
							</select>
						</div>
						<div class="form-group col">
							<button type="submit" class="btn btn-primary btn-modern">Submit</button>
						</div>
					</div>
				</form>
			</div>

		</div>

	</div>

	<table *ngIf="is_show_report" border="0" cellpadding="0" cellspacing="0" width="100%">
		<tr>
			<td align="center">
				<table border="0" cellpadding="0" cellspacing="0" width="100%"
					style="max-width: 750px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; background-color:#fff; padding: 24px; border-top: 3px solid #d4dadf; border:1px solid #ccc;">
					<tr>
						<td>
							<table>
								<tbody>
									<tr style="border-bottom:2px solid #c00000; margin-bottom:46px">
										<td><a [routerLink]="'/'" target="_blank" style="display: inline-block;">
												<img src="/assets/img/report-logo.png" alt="Logo" border="0" width="120"
													style="display: block; width: 120px; max-width: 120px; min-width: 120px;" /></a>
										</td>
										<td>
											<h1
												style="color: #833c0b; margin: 10px 0px 0px; padding: 0px; font-weight: 800; font-size: 25px; line-height: 22px;">
												SRI VENKATESWARA TEMPLE</h1>
											<h3 style="font-size: 22px; margin: 9px 0;">1230 McCully Dr.,
												Pittsburgh, PA 15235</h3>
											<p style="font-size: 17px; margin: 9px 0; font-weight: 700;">
												Phone:
												412-373-3380, Email: srivaru@svtemple.org</p>
											<p
												style="font-size: 18px; margin: 9px 0; font-weight: 700; text-align:center;">
												TAX ID: 25-1307095 </p>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>

					<tr>
						<td>
							<p style="margin: 20px 0 10px 0; font-size: 14px; line-height: 21px;">
								<strong>{{ userData.first_name }} {{ userData.middle_name }} {{ userData.last_name
									}},</strong><br />
								{{ userData.address }},<br />
								{{ userData.city }}, <br />
								{{ userData.state }} {{ userData.zipcode }}
							</p>
							<p style="margin: 20px 0 0 0;">Dear {{ userData.first_name }},</p>
						</td>
					</tr>
					<tr>
						<td>
							<p style="margin:2px 0 ">Sri Venkateswara Temple appreciates your contribution
								for ${{ amount }} for the year {{ selected_year }}.</p><br />
							<p style="margin:0px;"><strong>May Lord Venkateswara and Goddess Padmavathi
									blessing
									be with us always.</strong></p>
						</td>
					</tr>
					<tr>
						<td
							style="border-bottom: 1px solid #d4dadf; font-weight:600; color:#c00000; padding-bottom:28px;">
							<p><strong style="color:#000000;">Regards,</strong><br />
								SV Temple Management<br />
								Pittsburgh, PA 15235</p>
						</td>
					</tr>
					<tr>
						<td>
							<p style="text-align: center;">
								<strong>Note : </strong>This report includes donations received through the website only,<br/>
								Any other form of donations to temple, please contact temple office
							</p>
						</td>
					</tr>
				</table>
			</td>
		</tr>
	</table>