import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/request.service';

@Component({
  selector: 'app-live-streaming',
  templateUrl: './live-streaming.component.html',
  styleUrls: ['./live-streaming.component.css']
})
export class LiveStreamingComponent implements OnInit {

  urls:any = [];

  constructor(
    public requestService: RequestService,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getData();
  }

  getData() {
    this.requestService.getLiveStreamings().subscribe((res) => {
      if(res.status === 1){
        this.urls = res.data;
      } else {
        this.urls = [];
      }
    });
  }

}