import { FormGroup } from '@angular/forms';
    
export function ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmedValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function RequiredValidator(name: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[name];
        if (control.errors && !control.errors.requiredValidator) {
            return;
        }
        if (control.value.trim() === '') {
            control.setErrors({ requiredValidator: true });
        } else {
            control.setErrors(null);
        }
    }
}