<div role="main" class="main">
    <section class="page-header page-header-classic page-header-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
                    <span class="page-header-title-border visible" style="width: 85.3906px;"></span>
                    <h1 data-title-border="">DONATIONS</h1>
                </div>
                <div class="col-md-4 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb d-block text-md-right">
                        <li><a [routerLink]="'/'">Home</a></li>
                        <li class="active">Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <div role="main" class="main">
        <div class="container py-4">
            <div class="row">
                <div class="col-lg-3 order-lg-2">
                    <app-cart-sidebar></app-cart-sidebar>
                </div>
                <div class="col-lg-8 order-lg-1" style="border-right:1px solid #e4e4e4; margin-right:25px;">
                    <div class="blog-posts">
                        <div class="row justify-content-md-center">
                            <div class="row px-3">
                                <app-donation-block></app-donation-block>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>