import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage-service';

import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { CartItem } from '../models/cart-item.model';
// import { DeliveryOption } from '../models/delivery-option.model';
// import { Product } from '../models/product.model';
import { ShoppingCart } from '../models/shopping-cart.model';
// import { DeliveryOptionsDataService } from '../services/delivery-options.service';
// import { ProductsDataService } from '../services/products.service';

const CART_KEY = 'cart';

@Injectable()
export class ShoppingCartService {
  // private storage: Storage;
  private subscriptionObservable: Observable<ShoppingCart>;
  private subscribers: Array<Observer<ShoppingCart>> = new Array<Observer<ShoppingCart>>();
  // private products: Product[];
  // private deliveryOptions: DeliveryOption[];

  public constructor(
    private storageService: LocalStorageService,
    //                  private productService: ProductsDataService,
    //                  private deliveryOptionsService: DeliveryOptionsDataService
  ) {
    // this.storage = this.storageService.get();
    // this.productService.all().subscribe((products) => this.products = products);
    // this.deliveryOptionsService.all().subscribe((options) => this.deliveryOptions = options);

    this.subscriptionObservable = new Observable<ShoppingCart>((observer: Observer<ShoppingCart>) => {
      this.subscribers.push(observer);
      observer.next(this.retrieve());
      return () => {
        this.subscribers = this.subscribers.filter((obs) => obs !== observer);
      };
    });
  }

  public get(): Observable<ShoppingCart> {
    return this.subscriptionObservable;
  }

  public isAlreadyExists(id: string) {
    const cart = this.retrieve();
    let item = cart.items.find((p) => p._id === id);
    if (item === undefined) {
      return false;
    } else {
      return true;
    }
  }

  public addQty(id: string) {
    const cart = this.retrieve();
    let item = cart.items.find((p) => p._id === id);
    if (item != undefined) {
      item.quantity += 1;
      this.calculateCart(cart);
      this.save(cart);
      this.dispatch(cart);
    }
  }

  public removeQty(id: string) {
    const cart = this.retrieve();
    let item = cart.items.find((p) => p._id === id);
    if (item != undefined) {
      item.quantity = item.quantity - 1;
      cart.items = cart.items.filter((cartItem: any) => cartItem.quantity > 0);
      if (cart.items.length === 0) {
        cart.deliveryOptionId = 'undefined';
      }
      this.calculateCart(cart);
      this.save(cart);
      this.dispatch(cart);
    }
  }

  public addItem(product: any, quantity: number) {
    // type_id - 1=at_temple | 2=outside | 3=rental | 4=express booking | 5=donation
    const cart = this.retrieve();
    let item = cart.items.find((p) => (p._id === product._id && p.date === product.date));
    if (item === undefined) {
      item = new CartItem();
      item._id = product._id;
      item.name = product.name;
      item.type = product.type;
      item.type_id = product.type_id;
      item.price = product.price;
      item.date = product.date;
      item.cut_off_hours = (product.cut_off_hours != undefined) ? product.cut_off_hours : 0;
      item.cut_off_service_time = (product.cut_off_service_time != undefined) ? product.cut_off_service_time : 0;
      item.cut_off_type = (product.cut_off_type != undefined) ? product.cut_off_type : 1;
      item.location = product.location;
      item.booking_config = product.booking_config;
      item.raw_data = (product.raw_data !== undefined) ? product.raw_data : {};
      item.page = (product.page !== undefined) ? product.page : '';
      cart.items.push(item);
    }
    if (quantity != 0) {
      item.quantity += quantity;
    } else {
      item.quantity = 0;
    }
    if (product.raw_data !== undefined) {
      item.raw_data = product.raw_data;
    }
    cart.items = cart.items.filter((cartItem: any) => cartItem.quantity > 0);
    if (cart.items.length === 0) {
      cart.deliveryOptionId = 'undefined';
    }

    this.calculateCart(cart);
    this.save(cart);
    this.dispatch(cart);
  }

  public addItemRowData(id: any, raw_data: any) {
    const cart = this.retrieve();
    let item = cart.items.find((p) => (p._id === id));
    if (item != undefined) {
    item.raw_data = raw_data;
      this.calculateCart(cart);
      this.save(cart);
      this.dispatch(cart);
    }
  }

  public empty(): void {
    const newCart = new ShoppingCart();
    this.save(newCart);
    this.dispatch(newCart);
  }

  public isEmpty() {
    const cart = this.retrieve();
    if (cart.grossTotal > 0) {
      return true;
    } else {
      return false;
    }
  }

  // public setDeliveryOption(deliveryOption: DeliveryOption): void {
  //   const cart = this.retrieve();
  //   cart.deliveryOptionId = deliveryOption.id;
  //   this.calculateCart(cart);
  //   this.save(cart);
  //   this.dispatch(cart);
  // }

  private calculateCart(cart: ShoppingCart): void {
    cart.itemsTotal = cart.items
      .map((item) => item.quantity * item.price)
      .reduce((previous, current) => previous + current, 0);
    // cart.deliveryTotal = cart.deliveryOptionId ?
    //                       this.deliveryOptions.find((x) => x.id === cart.deliveryOptionId).price :
    //                       0;
    cart.donationTotal = cart.items.map(function (item, i) {
      if (item.type_id === 5) { return item.quantity * item.price; } else { return 0; }
    }).reduce((previous, current) => previous + current, 0);
    cart.deliveryTotal = 0;
    cart.grossTotal = cart.itemsTotal + cart.deliveryTotal;
  }

  private retrieve() {
    const cart = new ShoppingCart();
    const storedCart = this.storageService.getItem(CART_KEY);
    if (storedCart) {
      cart.updateFrom(JSON.parse(storedCart));
    }
    return cart;
  }

  private save(cart: ShoppingCart): void {
    this.storageService.setItem(CART_KEY, JSON.stringify(cart));
  }

  private dispatch(cart: ShoppingCart): void {
    this.subscribers
      .forEach((sub) => {
        try {
          sub.next(cart);
        } catch (e) {
          // we want all subscribers to get the update even if one errors.
        }
      });
  }
}
