import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { RequestService } from '../../services/request.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator } from '../../services/validator-service';
import { first } from 'rxjs/operators';
import {
  Event,
  Router,
  ActivatedRoute,
  NavigationStart,
} from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-devotee-committee',
  templateUrl: './devotee-committee.component.html',
  styleUrls: ['./devotee-committee.component.css'],
})
export class DevoteeCommitteeComponent implements OnInit {
  terms: any;
  selected_terms: any;

  Form: any = FormArray;
  loading = false;
  submitted = false;
  isLoggedIn: boolean = false;
  user_data: any;
  display_form: boolean = false;
  message = '';
  start_date = '';
  end_date = '';
  @ViewChild('confirmMdl') confirmMdl: any;
  @ViewChild('successMdl') successMdl: any;

  constructor(
    public requestService: RequestService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    public router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    if (!this.isLoggedIn) {
      this.router.navigate(['/login'], {
        queryParams: { redirect: '/devotee_committee' },
      });
    }
    this.user_data = this.authenticationService.currentUserValue;
    this.getData(this.user_data.user_id);
    this.Form = this.formBuilder.group({
      user_id: [this.user_data.user_id, Validators.required],
      terms: this.formBuilder.array([], Validators.required),
    });
  }

  onSectionChange(e: any) {
    const commiteeTerms: FormArray = this.Form.get('terms') as FormArray;
    if (e.target.checked) {
      commiteeTerms.push(new FormControl(e.target.value));
    } else {
      const index = commiteeTerms.controls.findIndex(
        (x) => x.value === e.target.value
      );
      commiteeTerms.removeAt(index);
    }
  }

  getData(user_id: string) {
    this.requestService
      .isValidDevoteeCommitteeForm(user_id)
      .subscribe((res) => {
        if (res.status === 1) {
          this.display_form = true;
          this.terms = res.data;
          this.message = '';
          this.start_date = res.start_date;
          this.end_date = res.end_date;
        } else {
          this.display_form = false;
          this.terms = [];
          this.message = res.message;
          this.start_date = '';
          this.end_date = '';
        }
      });
  }

  get f() {
    return this.Form.controls;
  }

  submitForm() {
    this.submitted = true;
    if (this.Form.invalid) {
      if (this.f.terms.errors && this.f.terms.errors.required) {
        this.toastr.error('Please fill the all required fields');
      }
      return;
    }
    this.selected_terms = [];
    this.selected_terms = this.terms.filter((t: any) => {
      if (this.Form.value.terms.includes(t.term_id)) {
        return true;
      }
      return false;
    });
    this.modalService.open(this.confirmMdl);
  }

  confirmSubmit() {
    this.modalService.dismissAll();
    this.loading = true;
    this.requestService
      .addDevoteeCommitteeMember(this.Form.value)
      .subscribe((res) => {
        if (res.status === 1) {
          this.modalService.open(this.successMdl);
          setTimeout(() => {
            this.reloadPage();
          }, 2500);
          this.loading = false;
        } else {
          this.toastr.error(res.message);
          this.loading = false;
        }
      });

  }
  reloadPage() {
    window.location.href = '/';
    // location.reload();
  }
}
