import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from "../../services/shopping-cart.service";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { RequestService } from '../../services/request.service';
import { LocalStorageService } from '../../services/local-storage-service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  cart: any;
  cartItems: any;
  itemCount = 0;
  donationTotal = 0;
  grossTotal = 0;
  isProceed = false;
  countries: any;
  isLoggedIn: boolean = false;
  user_data: any;
  all_new_service: boolean = true;
  is_any_services: boolean = false;

  myForm: any;
  loading = false;
  submitted = false;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    private toastr: ToastrService,
    public router: Router,
    public localStorageService: LocalStorageService,
    private shoppingCartService: ShoppingCartService
  ) { }

  ngOnInit(): void {
    this.getData();
    window.scrollTo(0, 0);
    this.localStorageService.removeItem('payout');
    this.getCartData();
    this.isProceed = this.shoppingCartService.isEmpty();
    if (!this.isProceed) {
      this.router.navigate(['/']);
    }
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.user_data = this.authenticationService.currentUserValue;
    this.myForm = this.formBuilder.group({
      country: [this.user_data.country || '', Validators.required],
      first_name: [this.user_data.first_name],
      last_name: [this.user_data.last_name],
      company_name: [''],
      address: [this.user_data.address],
      city: [this.user_data.city],
    });
  }

  getCartData() {
    this.is_any_services = false;
    this.all_new_service = true;
    let today : any = new Date();
    today = today.toLocaleString('en-US', { timeZone: 'America/New_York' })
    today = new Date(today);
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    this.cart = this.shoppingCartService.get();
    this.cart.subscribe((cart: any) => {
      this.itemCount = cart.items.map((x: any) => x.quantity).reduce((p: any, n: any) => p + n, 0);
      this.donationTotal = cart.donationTotal;
      this.grossTotal = cart.grossTotal;
      cart.items.map((item: any) => {
        if((item.type_id == 1 || item.type_id == '1') && item.cut_off_service_time != undefined && item.cut_off_hours != undefined){
          let selected = new Date(item.date);
          var cc = item.cut_off_service_time.split(':');
          let estimate_booking_date = new Date(selected.getFullYear(), selected.getMonth(), selected.getDate(), cc[0], cc[1], cc[2]);
          estimate_booking_date.setHours(estimate_booking_date.getHours() - item.cut_off_hours);
          let etoday : any = new Date();
          etoday = etoday.toLocaleString('en-US', { timeZone: 'America/New_York' })
          etoday = new Date(etoday);
          if(etoday >= estimate_booking_date){
            this.all_new_service = false;
          }
        } else {
          if (new Date(item.date) < today) {
            this.all_new_service = false;
          }
        }
        if(item.type_id == 1 || item.type_id == '1'){
          this.is_any_services = true;
        }
      });
      this.cartItems = cart.items.map((item: any) => { return { ...item, expand: false, totalCost: item.price * item.quantity }; });
    });
  }

  getData() {
    this.requestService.getCountries().subscribe((res) => {
      if (res.status === 1) {
        this.countries = res.data;
      } else {
        this.countries = [];
      }
    });
  }

  public removeItem(product: any): void {
    this.shoppingCartService.addItem(product, 0);
    this.getCartData();
  }

  public addQty(_id: any): void {
    this.shoppingCartService.addQty(_id);
    this.getCartData();
  }

  public removeQty(_id: any): void {
    this.shoppingCartService.removeQty(_id);
    this.getCartData();
  }

  public emptyCart(): void {
    this.shoppingCartService.empty();
    this.getCartData();
  }

  get f() { return this.myForm.controls; }

  submitForm() {
    this.submitted = true;
    if (!this.all_new_service) {
      this.toastr.error('Please remove the services of old date from the pooja basket');
      return;
    }
    // if(this.is_any_services && !this.isLoggedIn){
    //   this.router.navigate(['/login'], { queryParams: { redirect: '/checkout' } });
    //   return;
    // }
    if (this.myForm.invalid) {
      this.toastr.error('Please fill the all required fields');
      return;
    }
    this.requestService.preValidItem(this.cartItems, this.donationTotal).subscribe((res) => {
      if (res.status === 1) {
        this.loading = false;
        this.localStorageService.setItem('payout', JSON.stringify(this.myForm.value));
        if (this.isLoggedIn) {
          this.router.navigate(['/checkout/payment']);
        } else {
          this.router.navigate(['/checkout/detail']);
        }
      } else if (res.status === 0) {
        this.toastr.error(res.message);
        this.loading = false;
      } else { /* login required */
        if (this.isLoggedIn) {
          this.loading = false;
          this.localStorageService.setItem('payout', JSON.stringify(this.myForm.value));
          this.router.navigate(['/checkout/payment']);
        } else {
          this.loading = false;
          this.router.navigate(['/login'], { queryParams: { redirect: '/checkout' } });
        }
      }
    });
  }

}