import { Component, OnInit, TemplateRef } from '@angular/core';
import { RequestService } from '../../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ShoppingCartService } from "../../../services/shopping-cart.service";
import { Event, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-donation-detail',
  templateUrl: './donation-detail.component.html',
  styleUrls: ['./donation-detail.component.css'],
})
export class DonationDetailComponent implements OnInit {
  loading: boolean = true;
  donation: any;
  min = new Date();

  constructor(
    private sanitizer: DomSanitizer,
    public requestService: RequestService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router,
    private shoppingCartService: ShoppingCartService
  ) {
    this.route.params.subscribe(routeParams => {
      const id = this.route.snapshot.params['id'];
      this.getDonationDetail(id);
    });
  }

  transformHtml(value:any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  ngOnInit(): void {
    let today = new Date();
    today = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    this.min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
      window.scrollTo(0, 0);
    }

  getDonationDetail(id: string) {
      this.requestService.getDonationDetail({ id }).subscribe((res) => {
        if (res.status === 1) {
          this.donation = res.data;
        } else {
          this.donation = [];
        }
      });
    }

  selectDate(event: any){
      this.donation.date = event.value;
    }

  selectQty(event: any){
      this.donation.quantity = event.target.value;
    }

  changeAmount(event: any){
      this.donation.amount = event.target.value;
    }

  public addProductToCart(): void {
      if(this.donation.date === ''){
      this.toastr.error('Please select the date first');
      return;
    }
    if (this.donation.amount < 1 || this.donation.amount == 0 || this.donation.amount == '' || this.donation.amount == null) {
      this.toastr.error('Please add donation amount');
      return;
    }
    let isAlreadyExists = this.shoppingCartService.isAlreadyExists(this.donation._id);
    if(isAlreadyExists){
      this.shoppingCartService.removeQty(this.donation._id);
      // this.shoppingCartService.addItem(this.donation, 0);
    }
    let qty = parseInt(this.donation.quantity);
    this.toastr.error('Donation added successfully on puja basket');
    let selected = new Date(this.donation.date);
    let month = selected.getMonth()+1;
    let date = month+'/'+selected.getDate()+'/'+selected.getFullYear();

    this.shoppingCartService.addItem({
      _id: this.donation._id,
      type: 'Donation',
      type_id: 5,
      name: this.donation.title,
      quantity: 1,
      price: this.donation.amount,
      page:'services/donation',
      date: date, //this.datepipe.transform(this.donation.date, 'MM/dd/yyyy'),
      location: '',
      booking_config: '',
    }, qty);
  }

}