import { Component, OnInit, TemplateRef } from '@angular/core';
import { RequestService } from '../../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

import { AvailabilityCartService } from "../../../services/availability-cart.service";

@Component({
  selector: 'app-rentals',
  templateUrl: './rentals.component.html',
  styleUrls: ['./rentals.component.css'],
})
export class RentalsComponent implements OnInit {
  loading: boolean = true;
  services: any;
  min = new Date();

  constructor(
    public requestService: RequestService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private availabilityCartService: AvailabilityCartService
  ) { }

  ngOnInit(): void {
    let today = new Date();
    today = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    this.min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    this.getData();
    window.scrollTo(0, 0);
  }

  getData() {
    this.requestService.getRentalService().subscribe((res) => {
      if (res.status === 1) {
        this.services = res.data;
      } else {
        this.services = [];
      }
    });
  }

  selectDate(i: number, event: any) {
    let selected = new Date(event.value);
    let month = selected.getMonth()+1;
    let date = month+'/'+selected.getDate()+'/'+selected.getFullYear();
    this.services[i].date = date; //this.datepipe.transform(event.value, 'MM/dd/yyyy');
  }

  selectStartTime(i: number, event: any) {
    this.services[i].start_time = event.value;
  }

  selectEndTime(i: number, event: any) {
    this.services[i].end_time = event.value;
  }

  public addProductToCart(product: any): void {
    if (product.date === '') {
      this.toastr.error('Please select the date');
      return;
    }
    if (product.start_time === '') {
      this.toastr.error('Please select the start time');
      return;
    }
    if (product.end_time === '') {
      this.toastr.error('Please select the end time');
      return;
    }
    let today : any = new Date();
    today = today.toLocaleString('en-US', { timeZone: 'America/New_York' })
    today = new Date(today);
    let selected = new Date(product.date);
    if(today.getFullYear() === selected.getFullYear() && 
    today.getMonth() === selected.getMonth() && 
    today.getDate()  === selected.getDate()){
      var morning = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9, 0, 0);
      if(morning.getTime() < today.getTime()){
        this.toastr.error('Same day service booking allowed before 9AM Eastern Time');
        return;
      }
    }
    const diffInMs = Date.parse(product.end_time) - Date.parse(product.start_time);
    let diffInHours = diffInMs / 1000 / 60 / 60;
    diffInHours = Math.round(diffInHours);
    // let isAlreadyExists = this.availabilityCartService.isAlreadyExists(product._id);
    // if(isAlreadyExists){
    //   this.toastr.error('You have already added for inquiry');
    //   return;
    // }
    let add_amount = 0;
    if(diffInHours <= product.amount_hour){
      add_amount = 0;
    } else {
      add_amount = product.additional_hour_amount*(diffInHours-product.amount_hour);
    }
    this.toastr.error('Service added successfully on basket');
    this.availabilityCartService.addItem({
      _id: product._id,
      type: 'Rental',
      type_id: 3,
      name: product.title,
      quantity: 1,
      price:product.amount,
      date:product.date,
      location : '0',
      start_time:this.datepipe.transform(product.start_time, 'hh:mm a'),
      end_time:this.datepipe.transform(product.end_time, 'hh:mm a'),
      duration:diffInHours,
      add_amount:add_amount,
      cleaning:product.cleaning_amount,
      raw_data: product,
    });
    window.scrollTo(0, 0);
  }

}