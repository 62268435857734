import { Component, OnInit, TemplateRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'monthly-date-picker',
  templateUrl: './monthly-date-picker.component.html',
})
export class MonthlyDatePickerComponent implements OnInit {
  min = new Date();
  @Input() service!: any;
  @Input() index!: number;
  @Output() selectDate: EventEmitter<number> = new EventEmitter();
  @Output() selectQty: EventEmitter<number> = new EventEmitter();
  enable_dates:any = [];

  constructor(
  ) { }

  public myFilter = (d: any): boolean => {
    this.getDatesArr(d.getMonth(), d.getFullYear());
    let dd = d.getDate();
    let position = this.service.positions;
    if(position === 'last' && this.enable_dates[this.enable_dates.length-1] == dd){
      return true;
    }
    position = position - 1;
    if (this.enable_dates[position] !== undefined && this.enable_dates[position] == dd) {
      return true;
    }
    return false;
  }

  getDatesArr(m: any, y: any) {
    this.enable_dates = [];
    var getTot = new Date(y, parseInt(m)+1, 0).getDate();
    for (var i = 1; i <= getTot; i++) {
      var newDate = new Date(y, m, i);
      let dt = newDate.getDate();
      if (this.service.days === newDate.getDay()+'') {
        this.enable_dates.push(dt);
      }
    }
  }

  ngOnInit(): void {
    let today = new Date();
    today = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    this.min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
  }

  selectingDate(event: any) {
    let selected = new Date(event.value);
    let month = selected.getMonth()+1;
    let date = month+'/'+selected.getDate()+'/'+selected.getFullYear();
    let data: any = {
      index: this.index,
      date: date //this.datepipe.transform(event.value, 'MM/dd/yyyy')
    };
    this.selectDate.emit(data);
  }

  selectingQty(event: any) {
    let data: any = {
      index: this.index,
      qty: event.target.value
    };
    this.selectQty.emit(data);
  }

}
