<div role="main" class="main">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">{{news.title}}</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li><a [routerLink]="'/news'">NEWS & EVENTS</a></li>
						<li class="active">{{news.title}}</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h2 class="word-rotator-title">
					<strong>{{news.title}}</strong>
				</h2>
			</div>
		</div>

		<div class="row">
			<div *ngIf="news.sub_title !== ''" class="col-sm-12 col-md-12">
				<p class="lead">
					{{news.sub_title}}
				</p>

			</div>
			<div class="col-sm-12 col-md-12">

				<div class="row">
					<div class="col-md-12">
						<hr class="tall"/>
					</div>
				</div>

				<div class="row">
					<div class="col-md-6 col-lg-6">
						<p [innerHTML]="transformHtml(news.description)"></p>
					</div>
					<div class="col-md-6 col-lg-6">
						<img src="{{news.image}}" />
					</div>
				</div>
			</div>
		</div>


		<form *ngIf="news.is_donation !== 0" [formGroup]="myForm" (ngSubmit)="submitForm()">
            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <h4>Donation</h4>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <label class="label_text">First Name 1</label>
                            <input type="text" formControlName="first_name_1" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted && f.first_name_1.errors }"
                                placeholder="First Name *" class="form-control form-control-lg" />
                            <div *ngIf="submitted && f.first_name_1.errors" class="invalid-feedback">
                                <div *ngIf="f.first_name_1.errors.required">First name is required</div>
                                <div *ngIf="f.first_name_1.errors.requiredValidator">First name is
                                    required</div>
                                <div *ngIf="f.first_name_1.errors.maxlength">First name may not be greater than 35 characters</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-3">
                            <label class="label_text">Last Name</label>
                            <input type="text" formControlName="last_name_1" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted && f.last_name_1.errors }"
                                placeholder="Last Name *" class="form-control form-control-lg" />
                            <div *ngIf="submitted && f.last_name_1.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name_1.errors.required">Last name is required</div>
                                <div *ngIf="f.last_name_1.errors.requiredValidator">Last name is
                                    required</div>
                                <div *ngIf="f.last_name_1.errors.maxlength">Last name may not be greater than 35 characters</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-3">
                            <label class="label_text">Gotram</label>
                            <input type="text" formControlName="gotram_1" autocomplete="off" placeholder="Gotram"
                                class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <label class="label_text">Nakshatram</label>
                            <select formControlName="nakshatram_1" class="form-control is-valid valid" size="0"
                                aria-invalid="false">
                                <option value="">Select Nakshatram</option>
                                <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                    nakshatram.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <label class="label_text">First Name 2</label>
                            <input type="text" formControlName="first_name_2" autocomplete="off"
                                placeholder="First Name" class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <label class="label_text">Last Name</label>
                            <input type="text" formControlName="last_name_2" autocomplete="off" placeholder="Last Name"
                                class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <label class="label_text">Gotram</label>
                            <input type="text" formControlName="gotram_2" autocomplete="off" placeholder="Gotram"
                                class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <label class="label_text">Nakshatram</label>
                            <select formControlName="nakshatram_2" class="form-control is-valid valid" size="0"
                                aria-invalid="false">
                                <option value="">Select Nakshatram</option>
                                <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                    nakshatram.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <label class="label_text">First Name 3</label>
                            <input type="text" formControlName="first_name_3" autocomplete="off"
                                placeholder="First Name" class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <label class="label_text">Last Name</label>
                            <input type="text" formControlName="last_name_3" autocomplete="off" placeholder="Last Name"
                                class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <label class="label_text">Gotram</label>
                            <input type="text" formControlName="gotram_3" autocomplete="off" placeholder="Gotram"
                                class="form-control form-control-lg" />
                        </div>
                        <div class="form-group col-lg-3">
                            <label class="label_text">Nakshatram</label>
                            <select formControlName="nakshatram_3" class="form-control is-valid valid" size="0"
                                aria-invalid="false">
                                <option value="">Select Nakshatram</option>
                                <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                    nakshatram.name }}</option>
                            </select>
                        </div>
                    </div>

                    <div formArrayName="persons">
                        <div *ngFor="let item of persons().controls; let Index = index" [formGroupName]="Index"
                            class="form-row">
                            <div [hidden]="Index===0" class="form-group col-lg-12">
                                <div class="r_btn"><img (click)="removePersons(Index)" src="/assets/img/minus.png" />
                                </div>
                            </div>
                            <div class="form-group col-lg-3">
                                <label class="label_text">First Name {{Index+4}}</label>
                                <input type="text" formControlName="first_name" autocomplete="off"
                                    placeholder="First Name" class="form-control form-control-lg" />
                            </div>
                            <div class="form-group col-lg-3">
                                <label class="label_text">Last Name</label>
                                <input type="text" formControlName="last_name" autocomplete="off"
                                    placeholder="Last Name" class="form-control form-control-lg" />
                            </div>
                            <div class="form-group col-lg-3">
                                <label class="label_text">Gotram</label>
                                <input type="text" formControlName="gotram" autocomplete="off" placeholder="Gotram"
                                    class="form-control form-control-lg" />
                            </div>
                            <div class="form-group col-lg-3">
                                <label class="label_text">Nakshatram</label>
                                <select formControlName="nakshatram" class="form-control is-valid valid" size="0"
                                    aria-invalid="false">
                                    <option value="">Select Nakshatram</option>
                                    <option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
                                        nakshatram.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="r_btn"><img (click)="addPersons()" src="/assets/img/add.png"></div>
                    </div>
                    <div class="form-row" *ngIf="news.is_donation == 1">
                        <div class="form-group col-lg-3">
                            <label class="label_text">Donation Amount</label>
                            <input type="number" formControlName="price" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted && f.price.errors }"
                                class="form-control form-control-lg" />
                            <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
                                <div *ngIf="f.price.errors.required">Price is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="news.is_donation == 2">
                        <div class="form-group col-lg-3">
                            <label class="label_text">Donation Amount</label>
                            <input type="text" readonly formControlName="price" autocomplete="off"
                                placeholder="Price" class="form-control form-control-lg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row" style="margin-top:18px;">
                <div>
                    <button type="submit" class="btn btn-primary btn-modern">Proceed to Pay</button>
                </div>
            </div>
        </form>

	</div>
</div>