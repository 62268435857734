import { Component, OnInit, TemplateRef } from '@angular/core';
import { RequestService } from '../../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ShoppingCartService } from "../../../services/shopping-cart.service";
import { Event, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.css'],
})
export class ServiceDetailComponent implements OnInit {
  loading: boolean = true;
  service: any;
  id: string = '';
  type: string = '';
  page_title = 'Services';
  today = new Date();
  min = new Date();

  constructor(
    private sanitizer: DomSanitizer,
    public requestService: RequestService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router,
    private shoppingCartService: ShoppingCartService
  ) {
    this.route.params.subscribe(routeParams => {
      this.id = this.route.snapshot.params['id'];
      this.type = this.route.snapshot.params['type'];
      this.page_title = this.type == 'vahana_sevas' ? 'Vahana Sevas' : this.type + ' Services';
      this.getServiceDetail();
    });
  }

  transformHtml(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  ngOnInit(): void {
    this.today = new Date(this.today.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    this.min = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
    window.scrollTo(0, 0);
  }

  getServiceDetail() {
    this.requestService.getServiceDetail({ id: this.id, type: this.type }).subscribe((res) => {
      if (res.status === 1) {
        this.service = res.data;
      } else {
        this.service = [];
      }
    });
  }
  selectDate(event: any) {
    this.service.date = event.value;
  }

  selectQty(event: any) {
    this.service.quantity = event.target.value;
  }

  selectDateHandler(event: any) {
    this.service.date = event.date;
  }

  selectQtyHandler(event: any) {
    this.service.quantity = event.qty;
  }

  public addProductToCart(product: any): void {
    if (product.is_online === 0) {
      this.toastr.error(product.booking_message);
      return;
    }
    if (product.date === '') {
      this.toastr.error('Please select the date first');
      return;
    }

    let selected = new Date(product.date);
    var cc = product.cut_off_service_time.split(':');

    /* Selected Date with Last service time */
    let estimate_booking_date = new Date(selected.getFullYear(), selected.getMonth(), selected.getDate(), cc[0], cc[1], cc[2]);

    /* Selected DateTime substract cut off hours */
    estimate_booking_date.setHours(estimate_booking_date.getHours() - product.cut_off_hours);

    /* Booking DateTime */
    let today: any = new Date();
    today = today.toLocaleString('en-US', { timeZone: 'America/New_York' })
    today = new Date(today);

    if (today >= estimate_booking_date) {
      // this.toastr.error('Cut-off time has passed so the Online booking is not available for the '+moment(estimate_booking_date).format('MMMM Do YYYY, hh:mm a')+' Eastern Time');
      this.toastr.error('Cut-off time has passed so the Online booking is not available for the date and service selected');
      return;
    }

    let qty = parseInt(product.quantity);
    this.toastr.error('Service added successfully on puja basket');

    let month = selected.getMonth() + 1;
    let date = month + '/' + selected.getDate() + '/' + selected.getFullYear();
    if (this.type == 'monthly') {
      date = product.date;
    }

    this.shoppingCartService.addItem({
      _id: product._id,
      type: this.type == 'vahana_sevas' ? 'Vahana Sevas' : this.type.charAt(0).toUpperCase() + this.type.slice(1) + ' Service',
      type_id: 1,
      name: product.title,
      quantity: 1,
      price: product.amount,
      page: 'services/' + this.type + '/detail/' + this.id,
      // date: this.date0pipe.transform(product.date, 'MM/dd/yyyy'),
      date: date,
      cut_off_hours: product.cut_off_hours,
      cut_off_service_time: product.cut_off_service_time,
      cut_off_type: product.cut_off_type,
      location: '',
      booking_config: product.booking_config,
    }, qty);
  }

}