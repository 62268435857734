<header id="header"
  data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyStartAt': 142, 'stickyChangeLogo': true}">
  <div class="header-body border-color-primary border-top-0 box-shadow-none header_bg">
    <div class="containerfull header_bg">
      <div class="row">
        <div class="container">
          <img src="/assets/img/header2.png">
        </div>
      </div>
    </div>

    <div class="ng-header-menu">
      <div class="_container col-md-12">
        <nav class="navbar navbar-icon-top navbar-expand-lg navbar-dark">

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#_navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" (click)="toggleMainMenu()"
            aria-label="Toggle navigation">
            <!-- <span class="navbar-toggler-icon"></span> -->
            <i class="fas fa-bars"></i>
          </button>
          <div class="right-nav">
            <a (click)="toggleSearch()"
              class="nav-link header-nav-features-toggle ml-4 p-0 d-block d-sm-block d-lg-none d-md-none d-xl-none float-right"
              data-focus="headerSearch"><i class="fas fa-search header-nav-top-icon"></i></a>
            <div class="position-relative d-block d-sm-block d-lg-none d-md-none d-xl-none">
              <div [hidden]="!searchBox" class="header-nav-features-dropdown show " id="headerTopSearchDropdown" style="opacity: 1;
              top: 6px;
              right: 9px;">
                <form [formGroup]="Form" (ngSubmit)="submitForm()">
                  <div class="simple-search input-group">
                    <input class="form-control text-1" id="headerSearch" formControlName="keyword" type="search"
                      placeholder="Search...">
                    <span class="input-group-append">
                      <button class="btn" type="submit">
                        <i class="fa fa-search header-nav-top-icon"></i>
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
            <a [routerLink]="'/checkout'"
              class="nav-link bucket-menu d-block d-sm-block p-0 d-lg-none d-md-none d-xl-none float-right"><img
                src="/assets/img/icons/puja_basket.png" /><span
                class="badge badge-warning text-dark">{{itemCount}}</span></a>

          </div>



          <div class="collapse navbar-collapse" [ngClass]="(main_menu) ? 'show' : ''" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto" style="margin: 0 auto;">
              <li class="nav-item active">
                <a class="nav-link text-uppercase" [routerLink]="'/'">Home<span class="sr-only">(current)</span></a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link text-uppercase dropdown-toggle" href="#" id="navbarDropdown1" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Services
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
                  <a class="dropdown-item" [routerLink]="'/services/express'">Express Booking</a>
                  <a class="dropdown-item" [routerLink]="'/services/temple'">Service at Temple</a>
                  <a class="dropdown-item" [routerLink]="'/services/outside-temple'">Services Outside
                    Temple</a>
                  <a *ngIf="!donations" class="dropdown-item" [routerLink]="'/services/donation'">Donations</a>
                  <a *ngIf="donations" class="nav-link dropdown-toggle d-block d-sm-block d-lg-none d-md-none d-xl-none"
                    href="#" id="navbarDropdown12" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    Donations
                  </a>
                  <div *ngIf="donations" class="dropdown-menu" aria-labelledby="navbarDropdown12">
                    <a *ngFor="let donation of donations" class="dropdown-item"
                      [routerLink]="['/services/donation/detail',(donation.slug != '' ?donation.slug : donation._id)]">{{
                      donation.title }}</a>
                  </div>

                  <div *ngIf="donations" class="dropdown-submenu d-none d-sm-none d-lg-block d-md-block d-xl-block">
                    <a [routerLink]="'/services/donation'" class="dropdown-toggle nav-link"
                      data-toggle="dropdown">Donations</a>
                    <ul class="dropdown-menu">
                      <li *ngFor="let donation of donations"><a
                          [routerLink]="['/services/donation/detail',(donation.slug != '' ?donation.slug : donation._id)]"
                          class="dropdown-item">{{
                          donation.title }}</a></li>
                    </ul>
                  </div>

                  <a class="dropdown-item" [routerLink]="'/services/rentals'">Rentals</a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link text-uppercase dropdown-toggle" href="#" id="navbarDropdown2" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Events &amp; Activities
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                  <a class="dropdown-item" [routerLink]="'/events/religious'">Religious Events</a>
                  <a class="dropdown-item" [routerLink]="'/events'">All Events</a>
                  <a (click)="openMenu($event,'c')"
                    class="nav-link dropdown-toggle position-relative d-block d-sm-block d-lg-none d-md-none d-xl-none"
                    href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    Cultural
                  </a>
                  <div [hidden]="!c_menu" class="_dropdown-menu dropdown-menu-one" aria-labelledby="navbarDropdown2">
                    <a class="dropdown-item" [routerLink]="'/events/cultural'">Cultural Events</a>
                    <a class="dropdown-item" [routerLink]="'/activities/cultural'">Cultural Activities</a>
                  </div>

                  <div class="dropdown-submenu d-none d-sm-none d-lg-block d-md-block d-xl-block">
                    <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">Cultural</a>
                    <ul class="dropdown-menu">
                      <li><a [routerLink]="'/events/cultural'" class="dropdown-item">Cultural Events</a></li>
                      <li><a [routerLink]="'/activities/cultural'" class="dropdown-item">Cultural Activities</a></li>
                    </ul>
                  </div>
                  <a (click)="openMenu($event,'e')"
                    class="nav-link dropdown-toggle position-relative d-block d-sm-block d-lg-none d-md-none d-xl-none"
                    href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    Education
                  </a>
                  <div [hidden]="!e_menu" class="_dropdown-menu dropdown-menu-one" aria-labelledby="navbarDropdown2">
                    <a class="dropdown-item" [routerLink]="'/events/education'">Educational Events</a>
                    <a class="dropdown-item" [routerLink]="'/activities/education'">Educational
                      Activities</a>
                  </div>

                  <div class="dropdown-submenu d-none d-sm-none d-lg-block d-md-block d-xl-block">
                    <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">Education</a>
                    <ul class="dropdown-menu">
                      <li><a [routerLink]="'/events/education'" class="dropdown-item">Educational Events</a></li>
                      <li><a [routerLink]="'/activities/education'" class="dropdown-item">Educational Activities</a>
                      </li>
                    </ul>
                  </div>

                  <a class="dropdown-item" [routerLink]="'/events/youth'">Youth Events</a>
                  <a class="dropdown-item" [routerLink]="'/events/other'">Other Events</a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link text-uppercase dropdown-toggle" href="#" id="navbarDropdown3" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Gallery
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown3">
                  <a class="dropdown-item" [routerLink]="'/gallery/photos'">Photos</a>
                  <a class="dropdown-item" [routerLink]="'/gallery/videos'">Videos</a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link text-uppercase dropdown-toggle" href="#" id="navbarDropdown4" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Visit Info
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown4">
                  <a class="dropdown-item" [routerLink]="'/visit-info/location-directions'">Location /
                    Directions</a>
                  <a class="dropdown-item" [routerLink]="'/visit-info/hotels'">Hotels</a>
                  <a class="dropdown-item" [routerLink]="'/visit-info/faq'">Help/ FAQ</a>
                  <a class="dropdown-item" [routerLink]="'/visit-info/temple-prasadam-policy'">SV Temple
                    Prasadam Policy</a>
                </div>
              </li>
              <!-- <li class="nav-item dropdown">
                <a class="nav-link  text-uppercase dropdown-toggle" href="#" id="navbarDropdown5" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  New Information
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown5">
                  <a class="dropdown-item" [routerLink]="'/report/2020'">2020 Year End Report</a>
                </div>
              </li> -->
              <li class="nav-item dropdown">
                <a class="nav-link text-uppercase dropdown-toggle" href="#" id="navbarDropdown" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  About Us
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" [routerLink]="'/about-us/temple-history'">Temple History</a>
                  <a class="dropdown-item" [routerLink]="'/about-us/about-temple'">About Temple</a>
                  <a class="dropdown-item" [routerLink]="'/about-us/article-bylaws'">Articles of Inc &amp;
                    Bylaws</a>
                  <a class="dropdown-item" [routerLink]="'/about-us/management'">Management</a>
                  <a class="dropdown-item" [routerLink]="'/about-us/voluntary-service'">Voluntary
                    Service</a>
                  <a class="dropdown-item" [routerLink]="'/about-us/careers'">Careers</a>
                  <a class="dropdown-item" [routerLink]="'/report'">Year End Report</a>
                  <a class="dropdown-item" [routerLink]="'/about-us/contact-us'">Contact Us</a>
                </div>
              </li>
              <li class="nav-item"><a [routerLink]="'/services/donation'" class="text-uppercase nav-link">Donate Now</a>
              </li>
              <li class="nav-item" *ngIf="!isLoggedIn">
                <a [routerLink]="'/login'" [queryParams]="{ redirect: redirectUrl}" class="text-uppercase nav-link"><i
                    class="far fa-user p-relative"></i>&nbsp;Login</a>
              </li>
              <li *ngIf="isLoggedIn" class="nav-item dropdown">
                <a class="nav-link text-uppercase dropdown-toggle" href="#" id="navbarDropdown6" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="far fa-user p-relative"></i>&nbsp; {{user_data.first_name}}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown6">
                  <a class="dropdown-item" *ngIf="dashboard_url != ''" target="_BLANK"
                    href="{{dashboard_url}}">Dashboard</a>
                  <a class="dropdown-item" [routerLink]="'/profile'">Profile</a>
                  <a class="dropdown-item" [routerLink]="'/change_password'">Change Password</a>
                  <a class="dropdown-item" [routerLink]="'/donation_report'">Donation Report</a>
                  <a class="dropdown-item" (click)="logout()">Logout</a>
                </div>
              </li>
              <li class="nav-item d-none d-sm-none d-lg-block d-md-block d-xl-block">
                <a [routerLink]="'/checkout'" class="nav-link bucket-menu"><img
                    src="/assets/img/icons/puja_basket.png" /><span
                    class="badge badge-warning text-dark">{{itemCount}}</span></a>
              </li>
              <li class="nav-item d-none d-sm-none d-lg-block d-md-block d-xl-block position-relative">
                <a (click)="toggleSearch()" class="nav-link header-nav-features-toggle" data-focus="headerSearch"><i
                    class="fas fa-search header-nav-top-icon"></i></a>

                <div [hidden]="!searchBox" class="header-nav-features-dropdown show" id="headerTopSearchDropdown"
                  style="opacity: 1;top: 0;">
                  <form [formGroup]="Form" (ngSubmit)="submitForm()">
                    <div class="simple-search input-group">
                      <input class="form-control text-1" id="headerSearch" formControlName="keyword" type="search"
                        placeholder="Search...">
                      <span class="input-group-append">
                        <button class="btn" type="submit">
                          <i class="fa fa-search header-nav-top-icon"></i>
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </li>


            </ul>

          </div>
        </nav>

      </div>

    </div>


  </div>
</header>