import { Component, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.css'],
})
export class DonationComponent implements OnInit {  

  constructor(
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}