<div role="main" class="main">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Search Results</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Search</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
	<div class="container py-2">

		<div class="row">

			<div class="col-lg-8">
				<div class="header-nav-features-dropdown show" id="headerTopSearchDropdown">
					<form [formGroup]="searchForm" (ngSubmit)="search()">
						<div class="simple-search input-group">
							<input class="form-control text-1" id="headerSearch" formControlName="keyword" type="search"
								placeholder="Search...">
							<span class="input-group-append">
								<button class="btn" type="submit">
									<i class="fa fa-search header-nav-top-icon"></i>
								</button>
							</span>
						</div>
					</form>
				</div>
				<hr class="hr_top">
			
				<div class="tabs tabs-dark mb-4 pb-2">
					<ul class="nav nav-tabs">
						<li class="nav-item active"><a class="nav-link show text-1 font-weight-bold text-uppercase active" href="#popularPosts" data-toggle="tab">News</a></li>
						<!-- <li class="nav-item"><a class="nav-link text-1 font-weight-bold text-uppercase" href="#recentPosts" data-toggle="tab">Events</a></li> -->
					</ul>
					<div class="tab-content">
						<div class="tab-pane active" id="popularPosts" *ngIf="results.length > 0">
							<article class="custom-post-style-2 mb-5" *ngFor="let news of results">
								<h5 class="event_tt"><a [routerLink]="['/news',news._id]">{{news.title}}</a></h5>
								<p>{{news.description}}</p>
							</article>
						</div>
						<div class="tab-pane active" id="popularPosts" *ngIf="results.length == 0">
							<p class="text-center">Result Not found!</p>
						</div>
						<div class="tab-pane" id="recentPosts">
							
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-3 mt-4 mt-lg-0">
				<aside class="sidebar">
					<ul class="nav nav-list flex-column mb-5">
						<li class="nav-item"><a class="nav-link" [routerLink]="'/services/donation'">Donation Now</a></li>
						<li class="nav-item"><a class="nav-link" [routerLink]="'/services/temple'">Services at Temple</a></li>
						<li class="nav-item"><a class="nav-link" [routerLink]="'/services/outside-temple'">Service Outside Temple</a></li>
					</ul>
				</aside>
			</div>
		</div>

	</div>

</div>