<div role="main" class="main log_bg">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Login</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Login</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div class="container ">
		<div class="row">
			<div class="col-sm-12 col-md-12">
				<div class="order-1 order-lg-2">

					<div class="row justify-content-md-center">
						<div class="col-md-4">
							<div class="featured-box featured-box-primary text-left mt-0">
								<div class="box-content form_bg_bdr">
									<form [formGroup]="Form" (ngSubmit)="submitForm()" class="needs-validation">
										<div class="form-row">
											<div class="form-group col">
												<label class="font-weight-bold text-dark text-2">Login By</label>
											</div>
										</div>
										<div class="form-row">
											<div class="form-group col">
												<label class="radio-inline">
													<input type="radio" (change)="changeLoginBy()" formControlName="login_by" value="username"> Username
												</label>
												<label class="radio-inline">
													<input type="radio" (change)="changeLoginBy()" formControlName="login_by" value="devotee_id"> Devotee Id
												</label>
											</div>
										</div>
										<div class="form-row">
											<div class="form-group col">
												<a *ngIf="login_by == 'username' || login_by == 'Username'" class="float-right" [routerLink]="'/forgot-username'">(Forgot Username?)</a>
												<a *ngIf="login_by == 'devotee_id' || login_by == 'devotee id'" class="float-right" [routerLink]="'/forgot-devotee-id'">(Forgot Devotee Id?)</a>
												<label class="font-weight-bold text-dark text-2">{{ login_by | titlecase }}</label>
												<input type="text" formControlName="username" autocomplete="off"
													[ngClass]="{ 'is-invalid': submitted && f.username.errors }"
													placeholder="{{ login_by | titlecase }} *" class="form-control form-control-lg" />
												<div *ngIf="submitted && f.username.errors" class="invalid-feedback">
													<div *ngIf="f.username.errors.required">{{ login_by | titlecase }} is required</div>
												</div>
											</div>
										</div>
										<div class="form-row">
											<div class="form-group col">
												<a class="float-right" [routerLink]="'/forgot-password'">(Forgot Password?)</a>
												<label class="font-weight-bold text-dark text-2">Password</label>
												<input type="password" formControlName="password" autocomplete="off"
													[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
													placeholder="Password *" class="form-control form-control-lg" />
												<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
													<div *ngIf="f.password.errors.required">Password is required</div>
												</div>
											</div>
										</div>
										<div class="form-row">
											<div class="form-group col-lg-6">
												<div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input" id="rememberme">
													<label class="custom-control-label text-2" for="rememberme">Remember
														Me</label>
													<!-- <a class="float-right" href="login_guest.html">Login Guest</a> -->
													<a class="float-right"
														[routerLink]="'/devotee-registration'">Register Now</a>
												</div>
											</div>
											<div class="form-group col-lg-6">
												<input type="submit" value="Login" class="btn btn-primary btn-modern float-right">
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
	</div>
</div>