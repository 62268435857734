<div class="slider-container light rev_slider_wrapper home-slider" *ngIf="slider.length > 0">
    <div class="home-wrapper">
        <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="false" [interval]="5000"
            [keyboard]="true" [pauseOnHover]="false" [animation]="false" [wrap]="true" [activeId]="'0'">
            <ng-template *ngFor="let slide of slider;let i = index" ngbSlide id="{{i}}">
                <a target="_blank" href="{{ slide.button_url_1 }}" *ngIf="slide.button_url_1 != ''"
                    style="cursor: pointer;">
                    <div class="picsum-img-wrapper">
                        <img src="{{slide.image}}" alt="Carousel One" class="res_img">
                    </div>
                    <div class="carousel-caption">
                        <h3 class="slider-title slider-title-{{i}}">{{ slide.title }}</h3>
                        <p class="slider-subtitle slider-subtitle-{{i}}">{{ slide.sub_title }}</p>
                        <a *ngIf="slide.button_label_1 != '' && slide.button_url_1 != ''" target="_blank"
                            class="nav-btn2 nav-btn2a" href="{{ slide.button_url_1 }}">{{ slide.button_label_1 }}</a>
                        <a *ngIf="slide.button_label_2 != '' && slide.button_url_2 != ''" target="_blank"
                            class="nav-btn2 nav-btn2a ml-3" href="{{ slide.button_url_2 }}">{{ slide.button_label_2
                            }}</a>
                    </div>
                </a>
                <div *ngIf="slide.button_url_1 == ''" class="picsum-img-wrapper">
                    <img src="{{slide.image}}" alt="Carousel One">
                </div>
                <div *ngIf="slide.button_url_1 == ''" class="carousel-caption">
                    <h3 class="slider-title slider-title-{{i}}">{{ slide.title }}</h3>
                    <p class="slider-subtitle slider-subtitle-{{i}}">{{ slide.sub_title }}</p>
                    <a *ngIf="slide.button_label_1 != '' && slide.button_url_1 != ''" target="_blank"
                        class="nav-btn2 nav-btn2a" href="{{ slide.button_url_1 }}">{{ slide.button_label_1 }}</a>
                    <a *ngIf="slide.button_label_2 != '' && slide.button_url_2 != ''" target="_blank"
                        class="nav-btn2 nav-btn2a ml-3" href="{{ slide.button_url_2 }}">{{ slide.button_label_2 }}</a>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</div>