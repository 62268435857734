import { Component, OnInit, Pipe } from '@angular/core';
import { RequestService } from '../../services/request.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit {
  scrollers: any;
  news: any;
  darshan_timings: string = '';
  spotlight: any;
  survey_url: string = '';
  current_date: Date = new Date();
  panchang: any;
  monthly_newsletter_pdf_url: string = '';
  annual_calendar_pdf_url: string = '';
  daily_puja_schedules: any;
  gallery = [];

  slideConfig = {
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    nextArrow: '<button class="NextArrow"><i class= "fa fa-chevron-right"></i></button>',
    prevArrow: '<button class="PreArrow"><i class= "fa fa-chevron-left"></i></button>',
    arrows: false,
    dots: true,
  };

  constructor(
    private sanitizer: DomSanitizer,
    config: NgbCarouselConfig,
    public requestService: RequestService,
  ) {
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {
    this.current_date = new Date(this.current_date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    this.current_date = new Date(this.current_date);
    window.scrollTo(0, 0);
    this.getData();
  }

  transformHtml(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getData() {
    this.requestService.getScroller().subscribe((res) => {
      if (res.status === 1) {
        this.scrollers = res.data;
      } else {
        this.scrollers = [];
      }
    });
    this.requestService.getPanchang(this.current_date).subscribe((res) => {
      if (res.status === 1) {
        this.panchang = res.data;
      } else {
        this.panchang = [];
      }
    });
    this.requestService.getMonthlyNewsLetterUrl().subscribe((res) => {
      if (res.status === 1) {
        this.monthly_newsletter_pdf_url = res.data;
      } else {
        this.monthly_newsletter_pdf_url = '';
      }
    });
    this.requestService.getAnnualCalendarUrl().subscribe((res) => {
      if (res.status === 1) {
        this.annual_calendar_pdf_url = res.data;
      } else {
        this.annual_calendar_pdf_url = '';
      }
    });
    this.requestService.getTodayPujaSchedules(this.current_date).subscribe((res) => {
      if (res.status === 1) {
        this.daily_puja_schedules = res.data;
      } else {
        this.daily_puja_schedules = [];
      }
    });
    this.requestService.getLatestNews(5).subscribe((res) => {
      if (res.status === 1) {
        this.news = res.data;
      } else {
        this.news = [];
      }
    });
    this.requestService.getDarshanTimings().subscribe((res) => {
      if (res.status === 1) {
        this.darshan_timings = res.data.darshan_timings;
      } else {
        this.darshan_timings = '';
      }
    });
    this.requestService.getSpotlight().subscribe((res) => {
      if (res.status === 1) {
        this.spotlight = res.data;
      } else {
        this.spotlight = [];
      }
    });
    this.requestService.getSurveyUrl().subscribe((res) => {
      if (res.status === 1) {
        this.survey_url = res.survey_url;
      } else {
        this.survey_url = '';
      }
    });
    this.requestService.getFooterGallery().subscribe((res) => {
      if (res.status === 1) {
        this.gallery = res.data;
        let slides = 1;
        if (this.gallery.length > 7) {
          slides = 7;
        } else if (this.gallery.length == 4 || this.gallery.length == 5) {
          slides = 3;
        } else if (this.gallery.length == 6 || this.gallery.length == 7) {
          slides = 5;
        }
        this.slideConfig = {
          centerMode: true,
          autoplay: true,
          autoplaySpeed: 2500,
          slidesToShow: slides, // (this.gallery.length % 2 == 0) ? this.gallery.length - 1 : this.gallery.length,
          nextArrow: '<button class="NextArrow"><i class= "fa fa-chevron-right"></i></button>',
          prevArrow: '<button class="PreArrow"><i class= "fa fa-chevron-left"></i></button>',
          arrows: false,
          dots: true,
        };
      } else {
        this.gallery = [];
      }
    });
  }

}
