<div role="main" class="main form_bg">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Proceed to Pay</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Payment</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div role="main" class="main shop row">

		<div class="col-lg-12">
			<div class="container form_bg_bdr">

				<!-- <p *ngIf="is_any_services && !isLoggedIn"><strong>Attention Devotees : All service bookings require website registration and login, if you do not want to register, please use express booking feature.</strong></p> -->

				<div class="row" *ngIf="!isLoggedIn">
					<div class="col">
						<p>Returning Devotee? <a [routerLink]="'/login'" [queryParams]="{ redirect: '/checkout'}">Click
								here to login.</a></p>
					</div>
				</div>
				<form [formGroup]="myForm" (ngSubmit)="submitForm()" class="form-row">
					<div class="col-lg-4">
						<h4 class="h4_ttl">Customer Details</h4>
						<div class="form-row">
							<div class="form-group col">
								<select formControlName="country" class="form-control is-valid valid" size="0"
									[ngClass]="{ 'is-invalid': submitted && f.country.errors }" aria-invalid="false">
									<option value="">Select Country *</option>
									<option *ngFor="let country of countries" value="{{ country._id }}">{{
										country.name }}</option>
								</select>
								<div *ngIf="submitted && f.country.errors" class="invalid-feedback">
									<div *ngIf="f.country.errors.required">Country is required</div>
								</div>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col-lg-12">
								<input type="text" formControlName="first_name" placeholder="First Name"
									class="form-control form-control-lg">
							</div>
							<div class="form-group col-lg-12">
								<input type="text" formControlName="last_name" placeholder="Last Name"
									class="form-control form-control-lg">
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col">
								<input type="text" formControlName="company_name" placeholder="Company Name"
									class="form-control form-control-lg">
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col">
								<input type="text" formControlName="address" placeholder="Address"
									class="form-control form-control-lg">
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col">
								<input type="text" formControlName="city" placeholder="City"
									class="form-control form-control-lg">
							</div>
						</div>
					</div>
					<div class="col-lg-8">
						<h4 class="h4_ttl">Payment Details
							<a class="float-right btn btn-primary text-white" (click)="emptyCart()">Empty Puja Basket</a>
						</h4>
						<table class="table table-striped">
							<thead>
								<tr>
									<th style="width:58%;">
										Puja
									</th>
									<th style="width:10%;">
										Date
									</th>
									<th style="width:10%;">
										Price
									</th>
									<th style="width:10%;">
										Quantity
									</th>
									<th style="width:10%;">
										Total
									</th>
									<th style="width:2%;">
									</th>
								</tr>
							</thead>
							<tbody>
								<tr class="cart_table_item" *ngFor="let item of cartItems; let i=index">
									<td class="product-name">
										<a *ngIf="item.page != ''" [routerLink]="'/'+item.page" style="color: #9d5f03;"><strong>{{ item.type }} :</strong> {{ item.name }}</a>
										<div *ngIf="item.page == ''" style="color: #9d5f03;"><strong>{{ item.type }} :</strong> {{ item.name }}</div><br/>
										<sup>{{ item.booking_config }}</sup>
									</td>
									<td>
										{{ item.date }}
									</td>
									<td class="product-price">
										<span class="amount">$ {{ item.price }}</span>
									</td>
									<td class="product-quantity">
										<i (click)="removeQty(item._id)" style="cursor: pointer;" class="fas fa-minus-square text-danger"></i>
										{{ item.quantity }}
										<i (click)="addQty(item._id)" style="cursor: pointer;" class="fas fa-plus-square text-success"></i>
									</td>
									<td class="product-subtotal">
										<span class="amount">$ {{ item.totalCost }}</span>
									</td>
									<td><i (click)="removeItem(item)" style="cursor: pointer;" class="fas fa-trash text-danger"></i></td>
								</tr>
							</tbody>
						</table>
						<div class="row">
							<div class="col-lg-6">
								<h4 class="h4_ttl">Puja Basket Totals</h4>
								<table class="table">
									<tbody>
										<tr class="cart-subtotal">
											<th>
												<strong class="text-dark">Cart Subtotal</strong>
											</th>
											<td>
												<strong class="text-dark"><span class="amount">$ {{grossTotal}}</span></strong>
											</td>
										</tr>
										<tr class="shipping">
											<th>
												Services
											</th>
											<td>
												{{itemCount}}<input type="hidden" value="free_shipping"
															id="shipping_method" name="shipping_method">
											</td>
										</tr>
										<tr class="total">
											<th>
												<strong class="text-dark">Order Total</strong>
											</th>
											<td>
												<strong class="text-dark"><span class="amount">$ {{grossTotal}}</span></strong>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="col-lg-6">
								<h4 class="h4_ttl">Payment</h4>
								<form action="/" id="frmPayment" method="post">
									<div class="form-row">
										<div class="form-group col">
											<div class="custom-control custom-checkbox">
												<input type="checkbox" checked disabled class="custom-control-input"
														id="paymentpaypal">
												<label class="custom-control-label" for="paymentpaypal">Paypal</label>
											</div>
										</div>
									</div>
								</form>
								<div class="form-row" style="margin-bottom:58px;">
									<div class="form-group">
										<button type="submit" class="btn btn-primary btn-modern">Pay Now</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>