import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Event, Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { RequestService } from '../../../services/request.service';
import { Subscription } from 'rxjs';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import '@angular/localize/init';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.css'],
  providers: [NgbCarouselConfig]
})


export class HomeSliderComponent implements OnInit, OnDestroy {
  slider: any = [];
  private sub: any;

  constructor(
    // private searchEventSubscription: Subscription,
    public requestService: RequestService,
    public router: Router,
    config: NgbCarouselConfig
  ) {
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {
    this.sub = this.requestService.getSlider().subscribe((res) => {
      if (res.status === 1) {
        this.slider = res.data;
      } else {
        this.slider = [];
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


}