<div role="main" class="main">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Forgot Password</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Login</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-12">

					<div class="row justify-content-md-center">
						<div class=" col-md-6">
							<div class="featured-box featured-box-primary text-left mt-0">
								<div class="box-content">
									<h4 class="color-primary font-weight-semibold text-4 text-uppercase mb-0">Forgot
										Password</h4>
									<p class="text-2 opacity-7">Enter your Username or Devotee id below and we'll send you a link
										to
										reset your password.</p>
									<form [hidden]="success !== ''" [formGroup]="Form" (ngSubmit)="submitForm()" class="needs-validation">
										<div class="form-row">
											<div class="form-group col">
												<label class="font-weight-bold text-dark text-2">Password reset by</label>
											</div>
										</div>
										<div class="form-row">
											<div class="form-group col">
												<label class="radio-inline">
													<input type="radio" (change)="changeResetBy()" formControlName="reset_by" value="username"> Username
												</label>
												<label class="radio-inline">
													<input type="radio" (change)="changeResetBy()" formControlName="reset_by" value="devotee_id"> Devotee Id
												</label>
											</div>
										</div>
										<div class="form-row">
											<div class="form-group col">
												<label class="font-weight-bold text-dark text-2">Please enter your
													{{ reset_by | titlecase }}</label>
												<input type="text" formControlName="username" autocomplete="off"
													[ngClass]="{ 'is-invalid': submitted && f.username.errors }"
													placeholder="{{ reset_by | titlecase }} *" class="form-control form-control-lg" />
												<div *ngIf="submitted && f.username.errors" class="invalid-feedback">
													<div *ngIf="f.username.errors.required">{{ reset_by | titlecase }} is required</div>
												</div>
											</div>
										</div>
										<div class="form-row">
											<div class="form-group col">
												<button [disabled]="loading" class="btn btn-primary btn-modern float-right" type="submit"><span *ngIf="loading"
													class="spinner-border spinner-border-sm mr-1"></span> Submit</button>
											</div>
										</div>
									</form>
									<div [hidden]="success === ''" class="panel panel-default credit-card-box">
										<div class="panel-heading display-table">
											<div class="row display-tr">
												<i class="far fa-check-circle chk"></i>
												<h3 class="panel-title display-td suc_tt">Mail Sent</h3>
												<p style="text-align:center;">{{ success }}</p>
														<a (click)="back()"
															class="btn btn-primary btn-modern">Back</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

			</div>
		</div>
	</div>
</div>