<div role="main" class="main form_bg">
    <section class="page-header page-header-classic page-header-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
                    <span class="page-header-title-border visible" style="width: 85.3906px;"></span>
                    <h1 data-title-border="">Proceed to Pay</h1>
                </div>
                <div class="col-md-4 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb d-block text-md-right">
                        <li><a [routerLink]="'/'">Home</a></li>
                        <li class="active">Payment</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <div role="main" class="main shop">

        <div class="container">

            <div class="container">
                <div class="row ">
                    <div class="col-md-10 form_bg_bdr" style="margin: 0 auto;">
                        <div class="panel panel-default credit-card-box">
                            <div class="panel-heading display-table">
                                <div class="row display-tr">
                                    <i class="far fa-check-circle chk"></i>
                                    <h3 class="panel-title display-td suc_tt">Thank you, your payment has been
                                        Successful</h3>
                                    <p style="text-align:center;">.
                                        <br>
                                        <span *ngIf="isLoggedIn">A confirmation email has been sent to <a
                                                href="javascript:void(0);">{{user_data.email}}</a></span>
                                        <span *ngIf="!isLoggedIn">Please print this receipt for your records</span>
                                    </p>
                                    <!-- Please print this receipt for your records -->
                                    <div class="col-md-3"></div>
                                    <div *ngIf="order" class="toggle-content col-md-6 bd" style="margin:0 auto;">
                                        <h3 class="panel-title display-td suc_tt h3_btm">Puja/Donation Details</h3>
                                        <p>Order No. : <span class="p_txt">{{ order.order_id }}</span></p>
                                        <p
                                            *ngIf="payout.first_name !== null && payout.last_name !== null && payout.first_name !== '' && payout.last_name !== ''">
                                            Name : <span class="p_txt">{{ (payout.first_name !== null &&
                                                payout.first_name !== '') ? payout.first_name : '' + ' ' +
                                                (payout.last_name !== null && payout.last_name !== '') ?
                                                payout.last_name : ''
                                                }}</span></p>
                                        <div *ngFor="let item of items">
                                            <hr>
                                            <p *ngIf="item.type != 5">Puja Name : <span class="p_txt">{{ item.name
                                                    }}</span></p>
                                            <p *ngIf="item.type == 5">Donation : <span class="p_txt">{{ item.name
                                                    }}</span></p>
                                            <p>Date: <span class="p_txt">{{ item.date }}</span></p>
                                            <p>Quantity: <span class="p_txt">{{ item.quantity }}</span></p>
                                            <p>Amount: <span class="p_txt">$ {{ item.final_price }}</span></p>
                                            <p *ngIf="item.booking_config != ''"><b>{{ item.booking_config }}</b></p>
                                        </div>
                                        <hr>
                                        <p><strong>Total Amount: <span class="p_txt">$ {{ order.amount
                                                    }}</span></strong></p>
                                    </div>
                                </div>
                                <br />
                                <p style="padding: 0px 20px;text-align: center;"><b>Attention: If the service booked
                                        allows in person attendance,<br />Pls carry copy of email / printed copy of the
                                        receipt to the temple</b></p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
</div>