import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { RequestService } from '../../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { ShoppingCartService } from "../../../services/shopping-cart.service";
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { RequiredValidator } from '../../../services/validator-service';
import * as moment from 'moment';

@Component({
  selector: 'app-recurring',
  templateUrl: './recurring.component.html',
  styleUrls: ['./recurring.component.css'],
})
export class RecurringComponent implements OnInit {
  nakshatrams: any;
  loading: boolean = true;
  daily_services: any;
  weekly_services: any;
  monthly_services: any;
  user_data: any;
  quantity: Number = 1;
  price: Number = 0;
  final_price: Number = 0;
  min = new Date();
  // service_date:any;
  selected_services: any = [];
  selected_service: any = {};
  selected_type = '';
  enable_dates:any = [];
  scheduled_dates:any = [];

  myForm: any;
  submitted = false;
  error = '';
  @ViewChild('scheduleMdl') scheduleMdl:any;
  calendarOptions: any = {
      height: 560,
    buttonText: {
      today: "Current Month"
    }
  };

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public router: Router,
    private shoppingCartService: ShoppingCartService
  ) {
    this.myForm = FormGroup;
  }

  ngOnInit(): void {
    let today = new Date();
    today = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    this.min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    this.getData();
    window.scrollTo(0, 0);
    this.myForm = this.formBuilder.group({
      first_name_1: ['', [Validators.required,Validators.maxLength(35)]],
      last_name_1: ['', [Validators.required,Validators.maxLength(35)]],
      gotram_1: [''],
      nakshatram_1: [''],
      first_name_2: [''],
      last_name_2: [''],
      gotram_2: [''],
      nakshatram_2: [''],
      first_name_3: [''],
      last_name_3: [''],
      gotram_3: [''],
      nakshatram_3: [''],
      persons: this.formBuilder.array([]),
      service: ['', Validators.required],
      quantity: [this.quantity, Validators.required],
      price: [0, Validators.required],
      date: ['',Validators.required],
      occasion: [''],
      occasion_other: [''],
    }, { 
      validator: [
        RequiredValidator('first_name_1'),
        RequiredValidator('last_name_1')
      ]
    });
    if (this.authenticationService.isLoggedIn()) {
      this.syncUserDetail();
    }
  }

  persons(): FormArray {
    return this.myForm.get("persons") as FormArray
  }

  newpersons(): FormGroup {
    return this.formBuilder.group({
      first_name: [''],
      last_name: [''],
      gotram: [''],
      nakshatram: ['']
    });
  }

  addPersons() {
    this.persons().push(this.newpersons());
  }

  removePersons(i: number) {
    this.persons().removeAt(i);
  }

  getData() {
    this.requestService.getNakshatrams().subscribe((res) => {
      if (res.status === 1) {
        this.nakshatrams = res.data;
      } else {
        this.nakshatrams = [];
      }
    });
    this.requestService.getServiceAtTemple('daily').subscribe((res) => {
      if (res.status === 1) {
        this.daily_services = res.data;
      } else {
        this.daily_services = [];
      }
    });
    this.requestService.getServiceAtTemple('weekly').subscribe((res) => {
      if (res.status === 1) {
        this.weekly_services = res.data;
      } else {
        this.weekly_services = [];
      }
    });
    this.requestService.getServiceAtTemple('monthly').subscribe((res) => {
      if (res.status === 1) {
        this.monthly_services = res.data;
      } else {
        this.monthly_services = [];
      }
    });
  }

  syncUserDetail() {
    this.user_data = this.authenticationService.currentUserValue;
    this.myForm.controls['first_name_1'].setValue(this.user_data.first_name);
    this.myForm.controls['last_name_1'].setValue(this.user_data.last_name);
    this.myForm.controls['gotram_1'].setValue(this.user_data.gotram);
    this.myForm.controls['nakshatram_1'].setValue(this.user_data.nakshatram);
    if (this.user_data.spouse.length > 0) {
      var spouse = this.user_data.spouse[0];
      this.myForm.controls['first_name_2'].setValue(spouse.first_name);
      this.myForm.controls['last_name_2'].setValue(spouse.last_name);
      this.myForm.controls['gotram_2'].setValue(spouse.gotram);
      this.myForm.controls['nakshatram_2'].setValue(spouse.nakshatram);
    }
    if (this.user_data.childrens.length > 0) {
      var childrens = this.user_data.childrens;
      var self = this;
      childrens.forEach(function (value: any, i: number) {
        if (i === 0) {
          self.myForm.controls['first_name_3'].setValue(childrens[i].first_name);
          self.myForm.controls['last_name_3'].setValue(childrens[i].last_name);
          self.myForm.controls['gotram_3'].setValue(childrens[i].gotram);
          self.myForm.controls['nakshatram_3'].setValue(childrens[i].nakshatram);
        } else {
          self.persons().push(
            self.formBuilder.group({
              first_name: [childrens[i].first_name],
              last_name: [childrens[i].last_name],
              gotram: [childrens[i].gotram],
              nakshatram: [childrens[i].nakshatram]
            })
          );
        }
      });

    }
  }


  selectService(event: any) {
    const selectedIndex = event.target.selectedIndex;
    const type = event.target.options[selectedIndex].parentNode.getAttribute('datatype');
    this.selected_type = type;
    switch (type) {
      case 'monthly':
        this.selected_services = this.monthly_services;
        break;
      case 'weekly':
        this.selected_services = this.weekly_services;
        break;
      default:
        this.selected_type = 'daily';
        this.selected_services = this.daily_services;
        break;
    }
    if (event.target.value !== '') {
      this.selected_service = this.selected_services[event.target.value];
      this.myForm.controls['price'].setValue(this.selected_service.amount);
      this.price = this.selected_service.amount;
      this.final_price = this.selected_service.amount;
    } else {
      this.myForm.controls['price'].setValue(0);
      this.price = 0;
      this.final_price = 0;
    }
    this.myForm.controls['date'].setValue('');
    this.scheduled_dates = [];
    this.quantity = 1;
    this.calendarOptions = {
      height: 560,
      buttonText: {
        today: "Current Month"
      }
    };
  }

  getDatesArr(m: any, y: any) {
    this.enable_dates = [];
    var getTot = new Date(y, m, 0).getDate();
    for (var i = 1; i <= getTot; i++) {
      var newDate = new Date(y, m, i);
      if (this.selected_service.days === newDate.getDay()+'') {
        this.enable_dates.push(newDate.getDate());
      }
    }
  }

  public myFilter = (d: any): boolean => {
    switch (this.selected_type) {
      case 'monthly':
        this.getDatesArr(d.getMonth(), d.getFullYear());
        let dd = d.getDate();
        let position = this.selected_service.positions;
        if(position === 'last' && this.enable_dates[this.enable_dates.length-1] == dd){
          return true;
        }
        position = position-1;
        if(this.enable_dates[position] !== undefined && this.enable_dates[position] == dd){
          return true;
        }
        return false;
        break;
      case 'weekly':
        const day = d.getDay();
        if (this.selected_service.days === day + '') {
          return true;
        }
        return false;
        break;
      default:
        return true;
        break;
    }
  }

  selectDate(event: any) {
    if(this.selected_service.title !== undefined) this.fetchSchedule(event.value);
  }

  addQty(num: any) {
    this.quantity = num + 1;
    if(this.selected_service.amount != undefined) this.final_price = parseInt(num+1)*parseInt(this.selected_service.amount);
    if(this.myForm.value.date!='') this.fetchSchedule(this.myForm.value.date);
  }

  removeQty(num: any) {
    const nn : Number = (num > 1) ? num - 1 : 1;
    this.quantity = nn
    if(this.selected_service.amount != undefined) this.final_price = Math.floor(Number(nn))*parseInt(this.selected_service.amount);
    if(this.myForm.value.date!='') this.fetchSchedule(this.myForm.value.date);
  }
  
  fetchSchedule(d:any){
    this.scheduled_dates = [];
    let _title = '$'+this.selected_service.amount+' - '+this.selected_service.title;
    this.scheduled_dates.push({title:_title,date:d,start:this.datepipe.transform(d, 'YYYY-MM-dd')});
    while (true) {
      if(this.quantity===1){
        break;
      }
      d = this.addDays(d,1);
      switch (this.selected_type) {
        case 'monthly':
          this.getDatesArr(d.getMonth(), d.getFullYear());
          let dd = d.getDate();
          let position = this.selected_service.positions;
          if(position === 'last' && this.enable_dates[this.enable_dates.length-1] == dd){
            this.scheduled_dates.push({title:_title,date:d,start:this.datepipe.transform(d, 'YYYY-MM-dd')});
            break;
          }
          position = position-1;
          if(this.enable_dates[position] !== undefined && this.enable_dates[position] == dd){
            this.scheduled_dates.push({title:_title,date:d,start:this.datepipe.transform(d, 'YYYY-MM-dd')});
            break;
          }
          break;
        case 'weekly':
          const day = d.getDay();
          if (this.selected_service.days === day + '') {
            this.scheduled_dates.push({title:_title,date:d,start:this.datepipe.transform(d, 'YYYY-MM-dd')});
          }
          break;
        default:
          this.scheduled_dates.push({title:_title,date:d,start:this.datepipe.transform(d, 'YYYY-MM-dd')});
          break;
      }
      if(this.scheduled_dates.length === this.quantity){
        break;
      }
    }
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      events: this.scheduled_dates,
      // eventContent: function (eventInfo: any) {
      //   return { html: eventInfo.event.extendedProps.customHtml }
      // },
      allDaySlot: false,
      height: 560,
      // height: 2800,
    };
  }

   addDays(d:any, qty:number) {
    var dd = d.getDate();
    var mm = d.getMonth();
    var yyyy = d.getFullYear();
    return new Date(yyyy, mm, dd + qty);
  }

  openScheduler(){
    this.modalService.open(this.scheduleMdl);
  }

  get f() { return this.myForm.controls; }

  submitForm() {
    this.submitted = true;
    if (this.myForm.invalid) {
      return;
    }
    if (this.selected_service.is_online === 0) {
      this.toastr.error(this.selected_service.booking_message);
      return;
    }
    if(this.myForm.value.occasion_other == '' && this.myForm.value.occasion == ''){
      this.toastr.error('Please enter or select occasion');
      return;
    }
    if(this.scheduled_dates.length == 0){
      this.toastr.error('Something has gone wrong');
      return;
    }

    let selected = new Date(this.scheduled_dates[0].date);
    var cc = this.selected_service.cut_off_service_time.split(':');

    /* Selected Date with Last service time */
    let estimate_booking_date = new Date(selected.getFullYear(), selected.getMonth(), selected.getDate(), cc[0], cc[1], cc[2]);

    /* Selected DateTime substract cut off hours */
    estimate_booking_date.setHours(estimate_booking_date.getHours() - this.selected_service.cut_off_hours);

    /* Booking DateTime */
    let today : any = new Date();
    today = today.toLocaleString('en-US', { timeZone: 'America/New_York' })
    today = new Date(today);

    if(today >= estimate_booking_date){
      // this.toastr.error('Cut-off time has passed so the Online booking is not available for the '+moment(estimate_booking_date).format('MMMM Do YYYY, hh:mm a')+' Eastern Time');
      this.toastr.error('Cut-off time has passed so the Online booking is not available for the date and service selected');
      return;
    }
   
    // let today : any = new Date();
    // today = today.toLocaleString('en-US', { timeZone: 'America/New_York' })
    // today = new Date(today);
    // let selected = new Date(this.scheduled_dates[0].date);
    // if(today.getFullYear() === selected.getFullYear() && 
    // today.getMonth() === selected.getMonth() && 
    // today.getDate()  === selected.getDate()){
    //   var morning = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9, 0, 0);
    //   if(morning.getTime() < today.getTime()){
    //     this.toastr.error('Same day service booking allowed before 9 AM Eastern Time');
    //     return;
    //   }
    // }

    this.loading = true;
    let type = 'Daily Service';
    let page = 'services/weekly';
    if(this.selected_type==='monthly'){
      type = 'Monthly Service';
      page = 'services/monthly';
    } else if(this.selected_type==='weekly'){
      type = 'Weekly Service';
      page = 'services/weekly';
    } 

    for (let i = 0; i < this.scheduled_dates.length; i++) {

      let _selected = new Date(this.scheduled_dates[i].date);
      let month = _selected.getMonth()+1;
      let date = month+'/'+_selected.getDate()+'/'+_selected.getFullYear();
      this.shoppingCartService.addItem({
        _id: this.selected_service._id,
        type: type,
        type_id: 1,
        name: this.selected_service.title,
        quantity: 1,
        price: this.selected_service.amount,
        page:page,
        date: date, //this.datepipe.transform(this.scheduled_dates[i].date, 'MM/dd/yyyy'),
        cut_off_hours : this.selected_service.cut_off_hours,
        cut_off_service_time : this.selected_service.cut_off_service_time,
        cut_off_type : this.selected_service.cut_off_type,
        location: '',
        raw_data : this.myForm.value,
        booking_config: this.selected_service.booking_config,
      }, 1);

      if(i === this.scheduled_dates.length-1){
        this.loading = false;
        this.router.navigate(['/checkout']);
      }

    }

  }

}