import { Component, OnInit, TemplateRef } from '@angular/core';
import { RequestService } from '../../../services/request.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.css'],
})
export class HotelsComponent implements OnInit {
  loading: boolean = true;
  description: String = '';
  items: any = [];

  constructor(
    private sanitizer: DomSanitizer,
    public requestService: RequestService,
  ) { }

  transformHtml(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.requestService.getVisitHotels().subscribe((res) => {
      if (res.status === 1) {
        this.description = res.description;
        this.items = res.data;
      } else {
        this.description = '';
        this.items = [];
      }
    });
  }

}