<div class="input-group">
    <input type="text" class="form-control" [owlDateTime]="dt1" [disabled]="true" [owlDateTimeTrigger]="dt1" [min]="min"
        readonly [owlDateTimeFilter]="myFilter" (dateTimeInput)="selectingDate($event)" autocomplete="off"
        data-select="datepicker" placeholder="Pick a date">
    <div class="input-group-append">
        <button class="btn btn-secondary" [owlDateTimeTrigger]="dt1" type="button">
            <i class="fa fa-calendar"></i>
        </button>
    </div>
    <owl-date-time [pickerMode]="'popup'" [hideOtherMonths]="true" [pickerType]="'calendar'" #dt1></owl-date-time>
    <select id="qty" class="" (change)="selectingQty($event)" size="0" aria-invalid="false">
        <option value="1" selected>1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
    </select>
    <div class="clr1 ">
        <span class="qty">Qty</span>
    </div>
</div>