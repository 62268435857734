import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { RequestService } from '../../services/request.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator } from '../../services/validator-service';
import { first } from 'rxjs/operators';
import { Event, Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  userData: any;
  Form: any = FormArray;
  loading = false;
  submitted = false;

  constructor(
    public requestService: RequestService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    public router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.userData = this.authenticationService.currentUserValue;
    this.Form = this.formBuilder.group({
      old_password: ['', Validators.required],
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      user_id: [this.userData.user_id, Validators.required]
    }, {
      validator: [
        ConfirmedValidator('new_password', 'confirm_password')
      ]
    });
  }

  get f() { return this.Form.controls; }

  submitForm() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService.changePassword(this.Form.value)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if (data.status === 1) {
            this.toastr.error(data.message);
            this.Form.controls['old_password'].setValue('');
            this.Form.controls['new_password'].setValue('');
            this.Form.controls['confirm_password'].setValue('');
            for (let control in this.Form.controls) {
              this.Form.controls[control].setErrors(null);
            }
            this.loading = false;
          } else {
            this.toastr.error(data.message);
            this.loading = false;
          }
        },
        error: error => {
          this.toastr.error(error);
          this.loading = false;
        }
      });
  }

}
