<div role="main" class="main form_bg">
	<section class="page-header page-header-classic page-header-sm">
		<div class="container">
			<div class="row">
				<div class="col-md-8 order-2 order-md-1 align-self-center p-static">
					<span class="page-header-title-border visible" style="width: 85.3906px;"></span>
					<h1 data-title-border="">Devotee Registration</h1>
				</div>
				<div class="col-md-4 order-1 order-md-2 align-self-center">
					<ul class="breadcrumb d-block text-md-right">
						<li><a [routerLink]="'/'">Home</a></li>
						<li class="active">Devotee Registration</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<div class="container form_bg_bdr">
		<div class="row">
			<div class="col-sm-12 col-md-12">
				<form [formGroup]="Form" (ngSubmit)="submitForm()">
					<h4>Personal Information</h4>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<input type="text" formControlName="first_name" autocomplete="off"
								[ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
								placeholder="First Name *" class="form-control form-control-lg" />
							<div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
								<div *ngIf="f.first_name.errors.required">First name is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<input type="text" formControlName="middle_name" autocomplete="off"
								placeholder="Middle Name" class="form-control form-control-lg" />
						</div>
						<div class="form-group col-lg-4">
							<input type="text" formControlName="last_name" autocomplete="off"
								[ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" placeholder="Last Name *"
								class="form-control form-control-lg" />
							<div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
								<div *ngIf="f.last_name.errors.required">Last name is required</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<input type="text" class="form-control form-control-lg" [owlDateTime]="dt"
								[owlDateTimeTrigger]="dt" readonly formControlName="dob" autocomplete="off"
								data-select="datepicker" placeholder="Date of Birth" />
							<owl-date-time [pickerMode]="'popup'" [pickerType]="'calendar'" #dt>
							</owl-date-time>
						</div>
						<div class="form-group col-lg-4">
							<select formControlName="nakshatram" class="form-control is-valid valid" size="0"
								aria-invalid="false">
								<option value="">Select Nakshatram</option>
								<option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
									nakshatram.name }}</option>
							</select>
						</div>
						<div class="form-group col-lg-4">
							<input type="text" formControlName="gotram" placeholder="Gotram"
								class="form-control form-control-lg" autocomplete="off" />
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<input type="text" formControlName="email" placeholder="Email *"
								[ngClass]="{ 'is-invalid': submitted && f.email.errors }"
								class="form-control form-control-lg" autocomplete="off" />
							<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
								<div *ngIf="f.email.errors.required">Email is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<input type="text" placeholder="Re-Enter Email *" formControlName="confirm_email"
								[ngClass]="{ 'is-invalid': submitted && f.confirm_email.errors }"
								class="form-control form-control-lg" autocomplete="off" />
							<div *ngIf="submitted && f.confirm_email.errors" class="invalid-feedback">
								<div *ngIf="f.confirm_email.errors.required">Confirm email is required</div>
								<div *ngIf="f.confirm_email.errors.confirmedValidator">Email and Confirm email must be
									match.</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<select formControlName="language" class="form-control is-valid valid" size="0"
								aria-invalid="false">
								<option value="">Preferred Language</option>
								<option *ngFor="let language of languages" value="{{ language._id }}">{{
									language.name }}</option>
							</select>
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-12">
							<div id="content">
								<input type="checkbox" (change)="changeIsMarried()" formControlName="is_married"
									value="1"> Married
								<input type="checkbox" (change)="changeIsChildren()" formControlName="is_children"
									value="1"> Children
							</div>
						</div>
					</div>

					<h4 *ngIf="married_box">Spouse Information</h4>

					<div [hidden]="!married_box" formArrayName="spouse">
						<div *ngFor="let item of spouse().controls; let Index = index" [formGroupName]="Index"
							class="form-row">
							<div class="form-group col-lg-4">
								<input type="text" formControlName="first_name" autocomplete="off"
									[ngClass]="{ 'is-invalid': submitted && f.spouse.controls[Index].controls.first_name.errors }"
									placeholder="First Name *" class="form-control form-control-lg" />
								<div *ngIf="submitted && f.spouse.controls[Index].controls.first_name.errors"
									class="invalid-feedback">
									<div *ngIf="f.spouse.controls[Index].controls.first_name.errors.required">First name
										is required</div>
								</div>
							</div>
							<div class="form-group col-lg-4">
								<input type="text" formControlName="middle_name" placeholder="Middle Name"
									class="form-control form-control-lg">
							</div>
							<div class="form-group col-lg-4">
								<input type="text" formControlName="last_name" autocomplete="off"
									[ngClass]="{ 'is-invalid': submitted && f.spouse.controls[Index].controls.last_name.errors }"
									placeholder="Last Name *" class="form-control form-control-lg" />
								<div *ngIf="submitted && f.spouse.controls[Index].controls.last_name.errors"
									class="invalid-feedback">
									<div *ngIf="f.spouse.controls[Index].controls.last_name.errors.required">Last name
										is required</div>
								</div>
							</div>
							<div class="form-group col-lg-4">
								<input type="text" class="form-control form-control-lg" [owlDateTime]="sdt"
									[owlDateTimeTrigger]="sdt" readonly formControlName="dob" autocomplete="off"
									data-select="datepicker" placeholder="Date of Birth" />
								<owl-date-time [pickerMode]="'popup'" [pickerType]="'calendar'" #sdt>
								</owl-date-time>
							</div>
							<div class="form-group col-lg-4">
								<select formControlName="nakshatram" class="form-control is-valid valid" size="0"
									aria-invalid="false">
									<option value="">Select Nakshatram</option>
									<option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
										nakshatram.name }}</option>
								</select>
							</div>
							<div class="form-group col-lg-4">
								<input type="text" formControlName="email" placeholder="Email"
									class="form-control form-control-lg">
							</div>
						</div>
					</div>

					<h4 *ngIf="children_box">Children Information</h4>

					<div [hidden]="!children_box" formArrayName="childrens">
						<div *ngFor="let item of childrens().controls; let Index = index" [formGroupName]="Index"
							class="form-row">
							<div class="form-group col-lg-12">
								<div class="pull-right">
									<i (click)="removeChildrens(Index)" class="btn-minus fas fa-minus-square"></i>
									<!-- <img (click)="removeChildrens(Index)" src="/assets/img/minus.png" /> -->
								</div>
							</div>
							<div class="form-group col-lg-4">
								<input type="text" formControlName="first_name" autocomplete="off"
									[ngClass]="{ 'is-invalid': submitted && f.childrens.controls[Index].controls.first_name.errors }"
									placeholder="First Name *" class="form-control form-control-lg" />
								<div *ngIf="submitted && f.childrens.controls[Index].controls.first_name.errors"
									class="invalid-feedback">
									<div *ngIf="f.childrens.controls[Index].controls.first_name.errors.required">First
										name
										is required</div>
								</div>
							</div>
							<div class="form-group col-lg-4">
								<input type="text" formControlName="middle_name" placeholder="Middle Name"
									class="form-control form-control-lg">
							</div>
							<div class="form-group col-lg-4">
								<input type="text" formControlName="last_name" autocomplete="off"
									[ngClass]="{ 'is-invalid': submitted && f.childrens.controls[Index].controls.last_name.errors }"
									placeholder="Last Name *" class="form-control form-control-lg" />
								<div *ngIf="submitted && f.childrens.controls[Index].controls.last_name.errors"
									class="invalid-feedback">
									<div *ngIf="f.childrens.controls[Index].controls.last_name.errors.required">Last
										name
										is required</div>
								</div>
							</div>
							<div class="form-group col-lg-4">
								<input type="text" class="form-control form-control-lg" [owlDateTime]="cdt"
									[owlDateTimeTrigger]="cdt" readonly formControlName="dob" autocomplete="off"
									data-select="datepicker" placeholder="Date of Birth" />
								<owl-date-time [pickerMode]="'popup'" [pickerType]="'calendar'" #cdt>
								</owl-date-time>
							</div>
							<div class="form-group col-lg-4">
								<select formControlName="nakshatram" class="form-control is-valid valid" size="0"
									aria-invalid="false">
									<option value="">Select Nakshatram</option>
									<option *ngFor="let nakshatram of nakshatrams" value="{{ nakshatram._id }}">{{
										nakshatram.name }}</option>
								</select>
							</div>
							<div class="form-group col-lg-4">
								<input type="text" formControlName="email" placeholder="Email"
									class="form-control form-control-lg">
							</div>
						</div>
						<div *ngIf="children_box" class="r_btn">
							<i (click)="addChildrens()" class="btn-plus fas fa-plus-square"></i>
							<!-- <img (click)="addChildrens()" src="/assets/img/add.png"> -->
						</div>
					</div>

					<hr>
					<div class="clear"></div>
					<h4>Contact Information</h4>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<input type="text" formControlName="address" placeholder="Address *"
								[ngClass]="{ 'is-invalid': submitted && f.address.errors }"
								class="form-control form-control-lg">
							<div *ngIf="submitted && f.address.errors" class="invalid-feedback">
								<div *ngIf="f.address.errors.required">Address is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<select formControlName="country" class="form-control is-valid valid" size="0"
								[ngClass]="{ 'is-invalid': submitted && f.country.errors }"
								(change)="onSelectCountry($event)" aria-invalid="false">
								<option value="">Select Country *</option>
								<option *ngFor="let country of countries" value="{{ country._id }}">{{
									country.name }}</option>
							</select>
							<div *ngIf="submitted && f.country.errors" class="invalid-feedback">
								<div *ngIf="f.country.errors.required">Country is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<select formControlName="state" class="form-control is-valid valid" size="0"
								[ngClass]="{ 'is-invalid': submitted && f.state.errors }" aria-invalid="false">
								<option value="">Select State *</option>
								<option *ngFor="let state of states" value="{{ state._id }}">{{
									state.name }}</option>
							</select>
							<div *ngIf="submitted && f.state.errors" class="invalid-feedback">
								<div *ngIf="f.state.errors.required">State is required</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<input type="text" formControlName="city" placeholder="City *"
								[ngClass]="{ 'is-invalid': submitted && f.city.errors }"
								class="form-control form-control-lg">
							<div *ngIf="submitted && f.city.errors" class="invalid-feedback">
								<div *ngIf="f.city.errors.required">City is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<input type="text" formControlName="zip_code" placeholder="Zip Code *"
								[ngClass]="{ 'is-invalid': submitted && f.zip_code.errors }"
								class="form-control form-control-lg">
							<div *ngIf="submitted && f.zip_code.errors" class="invalid-feedback">
								<div *ngIf="f.zip_code.errors.required">Zipcode is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<input type="text" formControlName="secondary_email" placeholder="Email"
								class="form-control form-control-lg">
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<input type="text" formControlName="home_phone" placeholder="Home Phone"
								class="form-control form-control-lg">
						</div>
						<div class="form-group col-lg-4">
							<input type="text" formControlName="office_phone" placeholder="Office Phone"
								class="form-control form-control-lg">
						</div>
						<div class="form-group col-lg-4">
							<input type="text" formControlName="mobile" placeholder="Mobile *"
								[ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
								class="form-control form-control-lg">
							<div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
								<div *ngIf="f.mobile.errors.required">Mobile number is required</div>
							</div>
						</div>
					</div>
					<hr>
					<div class="clear"></div>
					<h4>Login Information</h4>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<input type="text" formControlName="user_name" placeholder="User Name *"
								[ngClass]="{ 'is-invalid': submitted && f.user_name.errors }"
								class="form-control form-control-lg">
							<div *ngIf="submitted && f.user_name.errors" class="invalid-feedback">
								<div *ngIf="f.user_name.errors.required">Username number is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<input type="password" formControlName="password" placeholder="Password *"
								[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
								class="form-control form-control-lg">
							<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
								<div *ngIf="f.password.errors.required">password number is required</div>
							</div>
						</div>
						<div class="form-group col-lg-4">
							<input type="password" formControlName="confirm_password" placeholder="Confirm Password *"
								[ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }"
								class="form-control form-control-lg">
							<div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
								<div *ngIf="f.confirm_password.errors.required">Confirm password is required</div>
								<div *ngIf="f.confirm_password.errors.confirmedValidator">Password and Confirm password
									must be match.</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-4">
							<div class="custom-control custom-checkbox pb-3">
								<input type="checkbox" formControlName="is_subscribed" value="1"
									class="custom-control-input" id="shipbillingaddress">
								<label class="custom-control-label" for="shipbillingaddress">Subscribe to
									Newsletter?</label>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-lg-8">
							<p><strong>Password must contain atleast one upper case, one lower case and one
									number.</strong>
							</p>
						</div>
					</div>

					<div class="form-row" style="margin-bottom:78px;">
						<div class="form-group col">
							<button type="submit" class="btn btn-primary btn-modern">Submit</button>
							<!-- <input type="submit" value="Cancel" class="btn btn-primary btn-modern"> -->
						</div>

					</div>
				</form>
			</div>
		</div>

		<ng-template #loginMdl let-modal>
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel"><strong>Registration Confirmation</strong></h5>
				<button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p style="padding: 0px 10px;"><strong>You are registering a devotee account in SV Temple Pittsburgh,<br /> 
					If you didn't select Nakshatram or Gotram then it will be defaulted to "Subha Nakshatram" and "Subha Gotram"<br/>
					do you want to proceed?</strong><br />

			</div>
			<div class="modal-footer">
				<a (click)="confirmSubmit()" type="button" class="btn btn-primary">Yes</a>
				<button type="button" class="btn btn-secondary" (click)="modal.dismiss()">No</button>
			</div>
		</ng-template>

	</div>