import { Component, OnInit, TemplateRef } from '@angular/core';
import { RequestService } from '../../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ShoppingCartService } from "../../../services/shopping-cart.service";
import * as moment from 'moment';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css'],
})
export class PersonalComponent implements OnInit {  
  loading:boolean = true;
  services:any;
  min=new Date();
  showDonation: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    public requestService: RequestService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private shoppingCartService: ShoppingCartService
  ) {}

  transformHtml(value:any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  
  ngOnInit(): void {
    let today = new Date();
    today = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    this.min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    this.getData();
    window.scrollTo(0, 0);
  }

  toggleDonation(event: any){
    this.showDonation = event;
  }

  getData() {
    this.requestService.getServiceAtTemple('personal').subscribe((res) => {
      if(res.status === 1){
        this.services = res.data;
      } else {
        this.services = [];
      }
    });
  }

  selectDate(i:number,event:any){ 
    let selected = new Date(event.value);
    let month = selected.getMonth()+1;
    let date = month+'/'+selected.getDate()+'/'+selected.getFullYear();
    this.services[i].date = date; //this.datepipe.transform(event.value, 'MM/dd/yyyy');
  }
  
  selectQty(i:number,event:any){
    this.services[i].quantity = event.target.value;
  }

  public addProductToCart(product:any): void {
    if(product.is_online === 0){
      this.toastr.error(product.booking_message);
      return;
    }
    if(product.date === ''){
      this.toastr.error('Please select the date first');
      return;
    }

    let selected = new Date(product.date);
    var cc = product.cut_off_service_time.split(':');

    /* Selected Date with Last service time */
    let estimate_booking_date = new Date(selected.getFullYear(), selected.getMonth(), selected.getDate(), cc[0], cc[1], cc[2]);

    /* Selected DateTime substract cut off hours */
    estimate_booking_date.setHours(estimate_booking_date.getHours() - product.cut_off_hours);

    /* Booking DateTime */
    let today : any = new Date();
    today = today.toLocaleString('en-US', { timeZone: 'America/New_York' })
    today = new Date(today);

    if(today >= estimate_booking_date){
      // this.toastr.error('Cut-off time has passed so the Online booking is not available for the '+moment(estimate_booking_date).format('MMMM Do YYYY, hh:mm a')+' Eastern Time');
      this.toastr.error('Cut-off time has passed so the Online booking is not available for the date and service selected');
      return;
    }

    // let today : any = new Date();
    // today = today.toLocaleString('en-US', { timeZone: 'America/New_York' })
    // today = new Date(today);
    // let selected = new Date(product.date);
    // if(today.getFullYear() === selected.getFullYear() && 
    // today.getMonth() === selected.getMonth() && 
    // today.getDate()  === selected.getDate()){
    //   var morning = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9, 0, 0);
    //   if(morning.getTime() < today.getTime()){
    //     this.toastr.error('Same day service booking allowed before 9 AM Eastern Time');
    //     return;
    //   }
    // }
    
    let qty = parseInt(product.quantity);
    this.toastr.error('Service added successfully on puja basket');
    this.shoppingCartService.addItem({
      _id : product._id,
      type : 'Personal Service',
      type_id : 1,
      name : product.title,
      quantity : 1,
      price : product.amount,
      page:'services/personal',
      date : product.date,
      cut_off_hours : product.cut_off_hours,
      cut_off_service_time : product.cut_off_service_time,
      cut_off_type : product.cut_off_type,
      location : '',
      booking_config : product.booking_config,
    }, qty);
  }

}