import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestService {

  API_URL: string = environment.apiUrl;

  constructor(private http: HttpClient) {
    // httpHeaders.append('key', 'fd');
  }

  getOutsideService() {
    return this.http.get<any>(this.API_URL + 'service/get_outside');
  }

  getRentalService() {
    return this.http.get<any>(this.API_URL + 'service/get_rental');
  }

  getServiceCategories() {
    return this.http.get<any>(this.API_URL + 'get_service_categories');
  }

  getServiceAtTemple(slug: string) {
    return this.http.post<any>(this.API_URL + 'service/temple', { slug });
  }

  getNakshatrams() {
    return this.http.get<any>(this.API_URL + 'get_nakshatram');
  }

  getProfile(user_id: string) {
    return this.http.post<any>(this.API_URL + 'get_profile', { user_id });
  }

  getLanguages() {
    return this.http.get<any>(this.API_URL + 'get_language');
  }

  getCountries() {
    return this.http.get<any>(this.API_URL + 'get_country');
  }

  getStates(country_id: string) {
    return this.http.post<any>(this.API_URL + 'get_state', { country_id });
  }

  preValidItem(items: any, donation_amount: number) {
    return this.http.post<any>(this.API_URL + 'pre_valid_item', { items, donation_amount });
  }

  makeOrder(items: any, payment_data: any, payout_detail: any, mailing_detail: any, payment_type: number, user: string) {
    return this.http.post<any>(this.API_URL + 'make_order', { items, payment_data, payment_type, payout_detail, mailing_detail, user });
  }

  getOrderDetail(order_id: string) {
    return this.http.post<any>(this.API_URL + 'get_order_detail', { order_id });
  }

  getDonation() {
    return this.http.get<any>(this.API_URL + 'get_donation');
  }

  getDonationDetail(data: any) {
    return this.http.post<any>(this.API_URL + 'get_donation_detail', data);
  }

  getServiceDetail(data: any) {
    return this.http.post<any>(this.API_URL + 'get_service_detail', data);
  }

  forgotPassword(data: any) {
    return this.http.post<any>(this.API_URL + 'forgot_password', data);
  }

  forgotUsername(data: any) {
    return this.http.post<any>(this.API_URL + 'forgot_username', data);
  }

  forgotUsernamePickOne(data: any) {
    return this.http.post<any>(this.API_URL + 'forgot_username_pick_one', data);
  }

  forgotDevoteeId(data: any) {
    return this.http.post<any>(this.API_URL + 'forgot_devotee_id', data);
  }

  isValidResetToken(token: string) {
    return this.http.post<any>(this.API_URL + 'is_valid_reset_token', { token });
  }

  resetPassword(data: any) {
    return this.http.post<any>(this.API_URL + 'reset_password', data);
  }

  verifyUsernameOtp(data: any) {
    return this.http.post<any>(this.API_URL + 'verify_username_otp', data);
  }

  forgotDevoteeIdPickOne(data: any) {
    return this.http.post<any>(this.API_URL + 'forgot_devotee_id_pick_one', data);
  }

  verifyDevoteeIdOtp(data: any) {
    return this.http.post<any>(this.API_URL + 'verify_devotee_id_otp', data);
  }

  getScroller() {
    return this.http.get<any>(this.API_URL + 'get_scroller');
  }

  getSlider() {
    return this.http.get<any>(this.API_URL + 'get_slider');
  }

  getLiveStreamings() {
    return this.http.get<any>(this.API_URL + 'get_live_streaming');
  }

  getEvents(type: string) {
    return this.http.post<any>(this.API_URL + 'get_events', { type });
  }

  getActivities(type: string) {
    return this.http.post<any>(this.API_URL + 'get_activities', { type });
  }

  getUpcomingEvents(type: string) {
    return this.http.post<any>(this.API_URL + 'get_upcoming_events', { type });
  }

  getPanchang(date: object) {
    return this.http.post<any>(this.API_URL + 'get_panchang', { date });
  }

  getTodayPujaSchedules(date: object) {
    return this.http.post<any>(this.API_URL + 'get_today_puja_schedule', { date });
  }

  getDarshanTimings() {
    return this.http.get<any>(this.API_URL + 'get_darshan_timings');
  }

  getSpotlight() {
    return this.http.get<any>(this.API_URL + 'get_spotlight');
  }

  getSurveyUrl() {
    return this.http.get<any>(this.API_URL + 'get_survey_url');
  }

  getFooterGallery() {
    return this.http.get<any>(this.API_URL + 'get_footer_photo_gallery');
  }

  getYearEndReport() {
    return this.http.get<any>(this.API_URL + 'get_year_end_report');
  }

  getAllPujaSchedules() {
    return this.http.get<any>(this.API_URL + 'get_puja_schedule');
  }

  getMonthlyNewsLetterUrl() {
    return this.http.get<any>(this.API_URL + 'get_monthly_newsletter');
  }

  getAnnualCalendarUrl() {
    return this.http.get<any>(this.API_URL + 'get_annual_calendar');
  }

  checkAvailability(id: string, items: any, total: number) {
    return this.http.post<any>(this.API_URL + 'check_availability', { id, items, total });
  }

  getGalleryCategories() {
    return this.http.get<any>(this.API_URL + 'get_gallery_categories');
  }

  getGalleryFestivals() {
    return this.http.get<any>(this.API_URL + 'get_gallery_festivals');
  }

  getGalleryTags() {
    return this.http.get<any>(this.API_URL + 'get_all_gallery_tags');
  }

  getGalleryFilter(data: any) {
    return this.http.post<any>(this.API_URL + 'get_gallery', data);
  }

  getGalleryFilterPhotos(id: string) {
    return this.http.post<any>(this.API_URL + 'get_gallery_photos', { id });
  }

  getGalleryFilterVideos(id: string) {
    return this.http.post<any>(this.API_URL + 'get_gallery_videos', { id });
  }

  getLatestNews(limit: number) {
    return this.http.post<any>(this.API_URL + 'get_latest_news', { limit });
  }

  getAllNews(data: any) {
    return this.http.post<any>(this.API_URL + 'get_all_news', data);
  }

  getNewsDetail(data: any) {
    return this.http.post<any>(this.API_URL + 'get_news_detail', data);
  }

  subscribe(data: any) {
    return this.http.post<any>(this.API_URL + 'subscribe', data);
  }

  getSearchResult(data: any) {
    return this.http.post<any>(this.API_URL + 'get_search_result', data);
  }

  contactSubmit(data: any) {
    return this.http.post<any>(this.API_URL + 'contact-us/store', data);
  }

  getFullPanchang() {
    return this.http.get<any>(this.API_URL + 'get_full_panchang');
  }

  getAllEvents() {
    return this.http.get<any>(this.API_URL + 'get_all_events');
  }

  getVisitLocation() {
    return this.http.get<any>(this.API_URL + 'get_location');
  }

  getVisitHotels() {
    return this.http.get<any>(this.API_URL + 'get_hotels');
  }

  getVisitFaq() {
    return this.http.get<any>(this.API_URL + 'get_faq');
  }

  getVisitPrasadamPolicy() {
    return this.http.get<any>(this.API_URL + 'get_prasadam_policy');
  }

  getStandingCommitteeList(data: any) {
    return this.http.post<any>(this.API_URL + 'get_standing_committee_list', data);
  }

  getDevoteeCommitteeList(data: any) {
    return this.http.post<any>(this.API_URL + 'get_devotee_committee_list', data);
  }

  getCommitteeList(data: any) {
    return this.http.post<any>(this.API_URL + 'get_committee_list', data);
  }

  isValidDevoteeCommitteeForm(user_id: string) {
    return this.http.post<any>(this.API_URL + 'is_valid_devotee_committee_form', { user_id });
  }

  isValidSSCForm(user_id: string) {
    return this.http.post<any>(this.API_URL + 'is_valid_ssc_form', { user_id });
  }

  addDevoteeCommitteeMember(data: any) {
    return this.http.post<any>(this.API_URL + 'add_devotee_committee_member', data);
  }

  addSSCMember(data: any) {
    return this.http.post<any>(this.API_URL + 'add_ssc_member', data);
  }

  checkCorporateBody(user_id: string) {
    return this.http.post<any>(this.API_URL + 'check_corporate_body', { user_id });
  }

  makeDuePayment(payout_detail: any, payment_data: any, payment_type: number, user: string) {
    return this.http.post<any>(this.API_URL + 'make_due_payment', { user, target_id: payout_detail._id, payment_type, payment_data, payout_detail });
  }

  getDonationReport(data: any) {
    return this.http.post<any>(this.API_URL + 'get_donation_report', data);
  }

}