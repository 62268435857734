import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from "../../services/shopping-cart.service";
import {
  IPayPalConfig,
  ICreateOrderRequest 
} from 'ngx-paypal';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../services/local-storage-service';
import { Router } from '@angular/router';
import { RequestService } from '../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  cart: any;
  cartItemsData: any;
  cartItems: any;
  itemCount = 0;
  grossTotal :string= '0';
  public payPalConfig ? : IPayPalConfig;
  clientId: string = environment.paypalClientId;
  currency: string = environment.paypalCurrency;
  user = '';

  constructor(
    private authenticationService: AuthenticationService,
    public router: Router,
    public requestService: RequestService,
    private toastr: ToastrService,
    public localStorageService: LocalStorageService,
    private shoppingCartService: ShoppingCartService
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if(this.authenticationService.isLoggedIn()){
      this.user = this.authenticationService.currentUserValue.user_id;
    }
    let payout_data = this.localStorageService.getItem('payout');
    if(!payout_data){
      this.router.navigate(['/checkout']);
    }
    let mailing_data = this.localStorageService.getItem('mailing');
    if(!mailing_data && !this.authenticationService.isLoggedIn()){
      this.router.navigate(['/checkout/detail']);
    }
    this.cart = this.shoppingCartService.get();
    this.cart.subscribe((cart: any) => {
      this.itemCount = cart.items.map((x:any) => x.quantity).reduce((p:any, n:any) => p + n, 0);
      this.grossTotal = cart.grossTotal;
      this.cartItems = cart.items.map((item: any) => { return { 
        name: item.name,
        quantity: item.quantity,
        category: 'DIGITAL_GOODS',
        unit_amount: {
          currency_code: this.currency,
          value: item.price,
        },
      }; });
      this.cartItemsData = cart.items.map((item: any) => { return { ...item, expand:false, totalCost: item.price * item.quantity }; });
    });
    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = {
    currency: this.currency,
    clientId: this.clientId,
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: this.currency,
            value: this.grossTotal,
            breakdown: {
              item_total: {
                currency_code: this.currency,
                value: this.grossTotal
              }
            }
          },
          items: this.cartItems,
        }
      ]
    },
    advanced: {
      commit: 'true'
    },
    style: {
      label: 'paypal',
      layout: 'vertical',
      color: 'silver',
    },
    onApprove: (data, actions) => {
      // console.log('onApprove - transaction was approved, but not authorized', data, actions);
      // actions.order.get().then(function(details:any) {
      //   console.log('onApprove - you can get full order details inside onApprove: ', details);
      // });
    },
    onClientAuthorization: (data) => {
      let payout_detail = this.localStorageService.getItem('payout');
      let mailing_detail = this.localStorageService.getItem('mailing');
      this.requestService.makeOrder(this.cartItemsData,data,payout_detail,mailing_detail || '',1,this.user).subscribe((res) => {
         if(res.status === 1){
            this.shoppingCartService.empty();
            this.localStorageService.removeItem('payout');
            this.localStorageService.removeItem('mailing');
            this.router.navigate(['/receipt/' + res.order_id]);
         } else {
            this.toastr.error(res.data);
         }
      });
    },
    onCancel: (data, actions) => {
      // console.log('OnCancel', data, actions);
      this.toastr.error('Payment canceled');
    },
    onError: err => {
      this.toastr.error('Payment not completed due to server error');
      // console.log('OnError', err);
    },
    onClick: (data, actions) => {
      // console.log('onClick', data, actions);
    },
  };
  }
}
