import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Event, Router, NavigationStart } from '@angular/router';
import { RequestService } from '../../services/request.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.css']
})
export class ForgotUsernameComponent implements OnInit {

  Form: any = FormArray;
  Form1: any = FormArray;
  Form2: any = FormArray;
  loading = false;
  submitted = false;
  status = 0;
  status_msg = '';
  users:any = [];
  usernames='';

  constructor(
    private authenticationService: AuthenticationService,
    public requestService: RequestService,
    private toastr: ToastrService,
    public router: Router,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.Form = this.formBuilder.group({
      username: ['', Validators.required],
    });
    this.Form1 = this.formBuilder.group({
      user: [''],
    });
    this.Form2 = this.formBuilder.group({
      user: [''],
      otp: [''],
      disable: [false],
    });
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

  get f() { return this.Form.controls; }
  get f1() { return this.Form1.controls; }
  get f2() { return this.Form2.controls; }

  back(){
    // this.success = '';
  }

  submitForm() {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;
    this.requestService.forgotUsername(this.Form.value).subscribe((res) => {
      if (res.status === 1) {
        this.status = 1;
        this.status_msg = res.message;
        this.Form2.controls['user'].setValue(res.user);
        this.loading = false;
      } else if (res.status === 2) {
        this.status = 3;
        this.status_msg = res.message;
        this.users = res.data;
        this.loading = false;
      } else {
        this.toastr.error(res.message);
        this.loading = false;
      }
    });
  }

  submitForm1() {
    this.submitted = true;
    if (this.Form1.value.user === '') {
      this.toastr.error('Please choose any one your account');
      return;
    }
    this.loading = true;
    this.requestService.forgotUsernamePickOne(this.Form1.value).subscribe((res) => {
      if (res.status === 1) {
        this.users = [];
        this.status = 1;
        this.status_msg = res.message;
        this.Form2.controls['user'].setValue(res.user);
        this.Form2.controls['disable'].setValue(true);
        this.usernames = res.usernames.join();
        this.loading = false;
      } else {
        this.toastr.error(res.message);
        this.loading = false;
      }
    });
    
  }

  submitForm2() {
    this.submitted = true;
    if (this.Form2.value.otp === '') {
      this.toastr.error('Please enter verification otp');
      return;
    }
    this.loading = true;
    this.requestService.verifyUsernameOtp(this.Form2.value).subscribe((res) => {
      if (res.status === 1) {
        this.status = 4;
        this.status_msg = res.message;
        this.loading = false;
      } else {
        this.toastr.error(res.message);
        this.loading = false;
      }
    });
    
  }

}
