<div role="main" class="main">
    <section class="page-header page-header-classic page-header-sm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
                    <span class="page-header-title-border visible" style="width: 85.3906px;"></span>
                    <h1 data-title-border="">HOTELS</h1>
                </div>
                <div class="col-md-4 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb d-block text-md-right">
                        <li><a [routerLink]="'/'">Home</a></li>
                        <li class="active">VISIT INFO</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <table class="table table-bordered table-hover" style="text-align:center;">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Phone Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of items; let i=index">
                            <td>{{ i+1}}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.phone_number }}</td>
                        </tr>
                    </tbody>
                </table>
                <p style="font-size:13px;" [innerHTML]="transformHtml(description)"></p>
            </div>
        </div>
    </div>
</div>