import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ShoppingCartService } from "./services/shopping-cart.service";
import { AvailabilityCartService } from "./services/availability-cart.service";
import { NgxPayPalModule } from 'ngx-paypal';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
// import { UICarouselModule } from "ui-carousel";
import { NgxPaginationModule } from 'ngx-pagination';
import { DatePipe, CommonModule } from '@angular/common';
import { NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FullCalendarModule } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { HomeComponent } from './component/home/home.component';
import { HeaderComponent } from './component/common/header/header.component';
import { FooterComponent } from './component/common/footer/footer.component';
import { LoginComponent } from './component/login/login.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { ForgotUsernameComponent } from './component/forgot-username/forgot-username.component';
import { ForgotDevoteeIdComponent } from './component/forgot-devotee-id/forgot-devotee-id.component';
import { DevoteeRegistrationComponent } from './component/devotee-registration/devotee-registration.component';
import { CheckoutComponent } from './component/checkout/checkout.component';
import { CheckoutDetailComponent } from './component/checkout-detail/checkout-detail.component';
import { PaymentComponent } from './component/payment/payment.component';
import { ReceiptComponent } from './component/receipt/receipt.component';
import { HomeSliderComponent } from './component/common/home-slider/home-slider.component';
import { LiveStreamingComponent } from './component/live-streaming/live-streaming.component';
import { DailyPujaScheduleComponent } from './component/daily-puja-schedule/daily-puja-schedule.component';
import { NewsComponent } from './component/news/news.component';
import { ReportComponent } from './component/report/report.component';
import { NewsdetailsComponent } from './component/news-details/news-details.component';
import { SearchComponent } from './component/search/search.component';
import { AlmanacComponent } from './component/almanac/almanac.component';
import { ProfileComponent } from './component/profile/profile.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { DonationReportComponent } from './component/donation-report/donation-report.component';
import { DevoteeCommitteeComponent } from './component/devotee-committee/devotee-committee.component';
import { SpecialStandingCommitteeComponent } from './component/special-standing-committee/special-standing-committee.component';

/* Services */
import { ServicesComponent } from './component/services/services.component';
import { CartSidebarComponent } from './component/common/cart-sidebar/cart-sidebar.component';
import { AvailabilitySidebarComponent } from './component/common/availability-sidebar/availability-sidebar.component';
import { OutsideTempleComponent } from './component/services/outside-temple/outside-temple.component';
import { RentalsComponent } from './component/services/rentals/rentals.component';
import { TempleComponent } from './component/services/temple/temple.component';
import { DailyComponent } from './component/services/daily/daily.component';
import { WeeklyComponent } from './component/services/weekly/weekly.component';
import { WeeklyDatePickerComponent } from './component/services/partial/weekly-date-picker/weekly-date-picker.component';
import { MonthlyComponent } from './component/services/monthly/monthly.component';
import { MonthlyDatePickerComponent } from './component/services/partial/monthly-date-picker/monthly-date-picker.component';
import { AnnualComponent } from './component/services/annual/annual.component';
import { AnnualDatePickerComponent } from './component/services/partial/annual-date-picker/annual-date-picker.component';
import { VahanaSevasComponent } from './component/services/vahana-sevas/vahana-sevas.component';
import { VahanDatePickerComponent } from './component/services/partial/vahan-date-picker/vahan-date-picker.component';
import { PersonalComponent } from './component/services/personal/personal.component';
import { ExpressComponent } from './component/services/express/express.component';
import { DonationBlockComponent } from './component/services/donation-block/donation-block.component';
import { DonationComponent } from './component/services/donation/donation.component';
import { DonationDetailComponent } from './component/services/donation-detail/donation-detail.component';
import { ServiceDetailComponent } from './component/services/service-detail/service-detail.component';
import { SpecialComponent } from './component/services/special/special.component';
import { SpecialDatePickerComponent } from './component/services/partial/special-date-picker/special-date-picker.component';
import { ConfirmAvailableComponent } from './component/services/confirm-available/confirm-available.component';
import { RecurringComponent } from './component/services/recurring/recurring.component';

/* Visit */
import { VisitInfoComponent } from './component/visit-info/visit-info.component';
import { LocationDirectionsComponent } from './component/visit-info/location-directions/location-directions.component';
import { HotelsComponent } from './component/visit-info/hotels/hotels.component';
import { TemplePrasadamPolicyComponent } from './component/visit-info/temple-prasadam-policy/temple-prasadam-policy.component';
import { FaqComponent } from './component/visit-info/faq/faq.component';

FullCalendarModule.registerPlugins([
  interactionPlugin,
  dayGridPlugin
]);

@NgModule({
  declarations: [
    AppComponent, HeaderComponent, FooterComponent, CheckoutComponent, CheckoutDetailComponent, PaymentComponent,
    HomeComponent, LoginComponent, ForgotPasswordComponent, DevoteeRegistrationComponent,
    ReceiptComponent, ForgotUsernameComponent, ResetPasswordComponent, HomeSliderComponent,
    LiveStreamingComponent, DailyPujaScheduleComponent, NewsComponent, ReportComponent,
    NewsdetailsComponent, SearchComponent, AlmanacComponent, ProfileComponent,
    ChangePasswordComponent, ForgotDevoteeIdComponent, DevoteeCommitteeComponent,
    SpecialStandingCommitteeComponent, DonationReportComponent,

    ServicesComponent, OutsideTempleComponent, RentalsComponent, TempleComponent, DailyComponent,
    CartSidebarComponent, WeeklyComponent, VahanaSevasComponent, PersonalComponent, ExpressComponent,
    DonationBlockComponent, DonationComponent, AvailabilitySidebarComponent, ConfirmAvailableComponent,
    MonthlyComponent, AnnualComponent, SpecialComponent, WeeklyDatePickerComponent, MonthlyDatePickerComponent,
    RecurringComponent, AnnualDatePickerComponent, DonationDetailComponent, SpecialDatePickerComponent, ServiceDetailComponent,
    VahanDatePickerComponent,

    VisitInfoComponent, LocationDirectionsComponent, HotelsComponent, TemplePrasadamPolicyComponent, FaqComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxPaginationModule,
    FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxPayPalModule,
    SlickCarouselModule,
    //  UICarouselModule,
    NgbModule, FullCalendarModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    CommonModule,
    ToastrModule.forRoot({
      timeOut: 3000, positionClass: 'toast-bottom-right', preventDuplicates: true,
    }),
  ],
  providers: [
    DatePipe,
    NgbCarouselConfig,
    { provide: ShoppingCartService, useClass: ShoppingCartService },
    { provide: AvailabilityCartService, useClass: AvailabilityCartService },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
